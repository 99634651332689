import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { ArrowIcon } from 'public/svg/IconsSvg';
import { twJoin } from 'tailwind-merge';
import { NavigationItemType } from 'types/navigation';

type NavigationItemProps = {
    hoveredCategory: NavigationItemType | null;
    activeCategory: NavigationItemType | null;
    setHoveredCategory: React.Dispatch<React.SetStateAction<NavigationItemType | null>>;
    item: NavigationItemType;
};

const TEST_IDENTIFIER = 'layout-header-navigation-navigationitem';

const NavigationItem: FC<NavigationItemProps> = ({ hoveredCategory, activeCategory, setHoveredCategory, item }) => {
    const isHovered =
        hoveredCategory?.uuid === item.uuid || (activeCategory?.uuid === item.uuid && hoveredCategory === null);

    return (
        <ExtendedNextLink
            type={item.link ? 'static-presumed-category' : 'category'}
            href={item.link || item.slug}
            className={twJoin(
                'relative py-2.5 pr-4 pl-14 no-underline first:py-4 last:py-4 hover:no-underline',
                isHovered && 'bg-grayLight text-primary hover:text-primary',
                !isHovered && 'text-white hover:text-white',
            )}
            data-testid={TEST_IDENTIFIER}
            onMouseEnter={() => setHoveredCategory(item)}
            onMouseLeave={() => setHoveredCategory(null)}
        >
            <>
                <span className="absolute top-1/2 left-4 inline-flex h-5 w-5 -translate-y-1/2 items-center justify-center text-primary">
                    {item.image !== null && (
                        <Image
                            className={isHovered ? 'grayscale invert' : ''}
                            src={item.image}
                            width="25"
                            height="25"
                            alt={item.name}
                        />
                    )}
                </span>
                {item.name}
                {item.hasChildren && activeCategory?.uuid !== item.uuid && (
                    <ArrowIcon className="absolute right-2 top-[calc(50%_-_4px)] h-2 w-2 -rotate-90 text-inherit" />
                )}
            </>
        </ExtendedNextLink>
    );
};

export default NavigationItem;
