import { mapConnectionEdges } from 'connectors/connection/Connection';
import { getFirstImage } from 'connectors/image/Image';
import { mapPageInfoApiData } from 'connectors/pageInfo/PageInfo';
import {
    BlogArticleConnectionFragmentApi,
    BlogArticleDetailFragmentApi,
    ListedBlogArticleFragmentApi,
    SimpleBlogArticleFragmentApi,
    useBlogArticlesQueryApi,
    usePromotedBlogArticleQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import {
    BlogArticleConnectionType,
    BlogArticleDetailType,
    ListedBlogArticleType,
    SimpleBlogArticleType,
} from 'types/blogArticle';

export const blogPreviewVariables = { first: 4, onlyHomepageArticles: true, includePromotedArticle: true };

export const useBlogPreviewArticles = (): ListedBlogArticleType[] => {
    const [{ data, error }] = useBlogArticlesQueryApi({ variables: blogPreviewVariables });

    useQueryError(error);

    if (data?.blogArticles.edges === undefined || data.blogArticles.edges === null) {
        return [];
    }

    return mapConnectionEdges<ListedBlogArticleFragmentApi, ListedBlogArticleType>(
        data.blogArticles.edges,
        mapListedBlogArticle,
    );
};

export const usePromotedBlogArticle = (categoryUuid: string | null): ListedBlogArticleType | null => {
    const [{ data, error }] = usePromotedBlogArticleQueryApi({ variables: { categoryUuid: categoryUuid } });

    useQueryError(error);

    const promotedArticle = data?.promotedBlogArticle;

    if (promotedArticle === undefined || promotedArticle === null) {
        return null;
    }

    return mapListedBlogArticle(promotedArticle);
};

export const mapBlogArticleDetail = (apiData: BlogArticleDetailFragmentApi): BlogArticleDetailType => {
    return {
        ...apiData,
        __typename: 'BlogArticle',
    };
};

export const mapBlogArticleConnection = (
    apiData: BlogArticleConnectionFragmentApi | null,
): BlogArticleConnectionType | null => {
    if (apiData === null) {
        return null;
    }

    return {
        ...apiData,
        pageInfo: mapPageInfoApiData(apiData.pageInfo),
        edges: mapConnectionEdges<ListedBlogArticleFragmentApi, ListedBlogArticleType>(
            apiData.edges,
            mapListedBlogArticle,
        ),
    };
};

export const mapListedBlogArticle = (apiData: ListedBlogArticleFragmentApi): ListedBlogArticleType => {
    return {
        ...apiData,
        image: getFirstImage(apiData.images),
    };
};

export const mapSimpleBlogArticle = (apiData: SimpleBlogArticleFragmentApi): SimpleBlogArticleType => {
    return {
        ...apiData,
        image: getFirstImage(apiData.images),
    };
};
