import { useEffect, useState } from 'react';

type useMountAnimationReturnType = [
    isMounted: boolean,
    isInStableAnimationState: boolean,
    setIsMountedHandler: (value: boolean) => void,
];

export const useMountAnimation = (animationDelay: number, initialIsMounted = false): useMountAnimationReturnType => {
    const [isMounted, setIsMounted] = useState(initialIsMounted);
    const [isInStableAnimationState, setIsInStableAnimationState] = useState(initialIsMounted);
    const [conditionHelper, setConditionHelper] = useState(initialIsMounted);

    const setIsMountedHandler = (value: boolean) => {
        if (value) {
            setIsMounted(true);
            setConditionHelper(true);
        } else {
            setIsInStableAnimationState(false);
        }
    };

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;

        if (isMounted && conditionHelper) {
            setIsInStableAnimationState(true);
            setConditionHelper(false);
        } else if (isMounted && !isInStableAnimationState) {
            timeoutId = setTimeout(() => {
                setIsMounted(false);
            }, animationDelay);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [animationDelay, isInStableAnimationState, isMounted, conditionHelper]);

    return [isMounted, isInStableAnimationState, setIsMountedHandler];
};
