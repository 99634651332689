import SubCategory from 'components/Layout/Header/HeaderBottom/DesktopMenu/SubCategory/SubCategory';
import { useNavigationSubCategoriesApi } from 'graphql/generated';
import Skeleton from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';

type SubCategoriesProps = {
    activeCategory: string;
};

const SubCategories: FC<SubCategoriesProps> = ({ activeCategory }) => {
    const [{ data, fetching }] = useNavigationSubCategoriesApi({ variables: { uuid: activeCategory } });

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!data?.category?.children.length && !fetching) {
        return null;
    }

    return (
        <ul className={twJoin('block flex-1 columns-3 gap-8 px-8 pt-8')}>
            {!fetching
                ? data?.category?.children.map((item, index) => (
                      <SubCategory className="break-inside-avoid" item={item} key={index} />
                  ))
                : Array(5)
                      .fill(null)
                      .map((_, index) => (
                          <div key={index} className="flex gap-3 pb-7 pl-16">
                              <Skeleton baseColor="#e5e5e5" highlightColor="#ececec" className="h-20 w-20" />
                              <div className="flex flex-col gap-2">
                                  <Skeleton baseColor="#e5e5e5" highlightColor="#ececec" className="h-3 w-32" />
                                  <Skeleton baseColor="#e5e5e5" highlightColor="#ececec" className="h-3 w-16" />
                                  <Skeleton baseColor="#e5e5e5" highlightColor="#ececec" className="h-3 w-20" />
                                  <Skeleton baseColor="#e5e5e5" highlightColor="#ececec" className="h-3 w-20" />
                              </div>
                          </div>
                      ))}
        </ul>
    );
};

export default SubCategories;
