import { Canonical } from 'components/Basic/Head/Canonical/Canonical';
import { SupportChat } from 'components/Blocks/SupportChat/SupportChat';
import { showInfoMessage, showSuccessMessage } from 'components/Helpers/Toasts';
import CommonLayout from 'components/Layout/CommonLayout';
import { Error503 } from 'components/Pages/ErrorPage/503/Error503';
import { useSettings } from 'connectors/Settings/Settings';
import PageContentProvider from 'context/PageContentProvider/PageContentProvider';
import ShopsysGlobalProvider from 'context/ShopsysGlobalProvider/ShopsysGlobalProvider';
import { extend, locale } from 'dayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/hu';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/zh';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { injectScripts } from 'helpers/administration/scripts';
import { ServerSidePropsType } from 'helpers/InitServerSideProps';
import { isDomainLocale } from 'helpers/isDomainLocale';
import { useReloadCart } from 'hooks/cart/useReloadCart';
import { useSetGeneralSlice } from 'hooks/setGeneralSlice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { useRedirectLoader } from 'hooks/ui/useRedirectStatusProgress';
import { useDomainConfig, useSetDomainConfig } from 'hooks/useDomainConfig';
import { useSetUserSlice } from 'hooks/useSetUserSlice';
import i18nConfig from 'i18n';
import { usePersooAddToCart } from 'modules/persoo/usePersooAddToCart';
import appWithI18n from 'next-translate/appWithI18n';
import { withUrqlClient } from 'next-urql';
import { AppProps as NextAppProps } from 'next/app';
import { NextComponentType, NextPageContext } from 'next/dist/shared/lib/utils';
import { Roboto } from 'next/font/google';
import Head from 'next/head';
import { ReactElement, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { usePersistStore } from 'store/usePersistStore';
import 'styles/globals.css';
import { NextPageCustomProps } from 'types/global';
import { getUrqlExchanges } from 'urql/exchanges';
import { fetcher } from 'urql/fetcher';
import { getDomainConfig } from 'utils/Domain/Domain';
import getGtmHeadScript from 'utils/Gtm/GtmHeadScript';

extend(LocalizedFormat);

const robotoFont = Roboto({
    weight: ['400', '500', '700'],
    subsets: ['latin-ext', 'latin'],
    variable: '--font-roboto',
});

type ErrorProps = { err?: any };

type CustomAppComponentProps = NextComponentType<NextPageContext, any, any> & NextPageCustomProps;

type CustomNextAppProps = {
    Component: CustomAppComponentProps;
};

type AppProps = {
    pageProps: ServerSidePropsType;
} & Omit<NextAppProps<ErrorProps>, 'Component' | 'pageProps'> &
    ErrorProps &
    CustomNextAppProps;

function MyApp({ Component, pageProps, err }: AppProps): ReactElement {
    const t = useTypedTranslationFunction();

    const settings = useSettings();
    useSetDomainConfig(pageProps.domainConfig);
    useSetUserSlice({ defaultStoreUuid: pageProps.defaultStoreUuid, cartUuid: pageProps.cartUuid });
    useSetGeneralSlice({ lastVisitedProductsCatNumbers: pageProps.lastVisitedProductsCatNumbers ?? undefined });

    useEffectOnce(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const scripts = settings?.scripts || [];
        injectScripts(scripts, 'all');
    });

    const updateUserState = usePersistStore((s) => s.updateUserState);
    const { defaultLocale } = useDomainConfig();
    const { loginLoading } = usePersistStore((s) => s);
    const isCzechLocale = isDomainLocale(defaultLocale, 'cs');

    usePersooAddToCart();
    useReloadCart();
    useRedirectLoader();

    locale(defaultLocale);

    useEffect(() => {
        if (loginLoading === 'not-loading') {
            return;
        }

        showSuccessMessage(t('Successfully logged in'));

        if (loginLoading === 'loading-with-cart-modifications') {
            showInfoMessage(t('Your cart has been modified. Please check the changes.'));
        }
        updateUserState({ loginLoading: 'not-loading' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginLoading]);

    return (
        <>
            <Head>
                <meta name="apple-mobile-web-app-title" content="Tescoma" />
                <meta name="application-name" content="Tescoma" />
                <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
                <link rel="manifest" href="/favicons/site.webmanifest" crossOrigin="use-credentials" />
                <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#D90000" />
                <link rel="shortcut icon" href="/favicons/favicon.ico" type="image/x-icon" />
                <meta name="msapplication-TileColor" content="#b91d47" />
                <meta name="theme-color" content="#b91d47" />
            </Head>
            {getGtmHeadScript()}
            {isCzechLocale && <SupportChat />}
            <style jsx global>{`
                html {
                    font-family: ${robotoFont.style.fontFamily};
                }
            `}</style>
            <Canonical />
            <ShopsysGlobalProvider>
                <PageContentProvider
                    isMaintenance={pageProps.isMaintenance}
                    userConsentCookie={pageProps.userConsentCookie}
                >
                    {Component.layout === 'plain' ? (
                        <Component {...pageProps} err={err} />
                    ) : (
                        <CommonLayout showLastVisitedProducts={Component.layout === 'withLastVisitedProducts'}>
                            {pageProps.isMaintenance ? <Error503 /> : <Component {...pageProps} err={err} />}
                        </CommonLayout>
                    )}
                </PageContentProvider>
            </ShopsysGlobalProvider>
        </>
    );
}

/**
 * We need to define "something" on the server side, even though it is not used at all.
 * On the server side, the URL is actually defined in initUrqlClient in InitServerSideProps.
 */
const getApiUrl = () => {
    let apiUrl = 'defaultUrl';
    if (typeof window !== 'undefined') {
        apiUrl = getDomainConfig(window.location.host).publicGraphqlEndpoint;
    }

    return apiUrl;
};

export default withUrqlClient(
    (ssrExchange) => ({
        url: getApiUrl(),
        exchanges: getUrqlExchanges(ssrExchange),
        fetch: fetcher(null),
    }),
    { ssr: false },
)(
    // eslint-disable-next-line
    // @ts-ignore
    appWithI18n(MyApp, { ...i18nConfig }),
);
