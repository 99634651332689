import { CombinedError } from '@urql/core';
import { showErrorMessage } from 'components/Helpers/Toasts';
import { getUserFriendlyErrors } from 'connectors/lib/friendlyErrorMessageParser';
import { ApplicationErrors } from 'helpers/errors/applicationErrors';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { GtmMessageOriginType } from 'types/gtm';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export const useHandleFormErrors = <T extends FieldValues>(
    error: CombinedError | undefined,
    formProviderMethods: UseFormReturn<T>,
    origin: GtmMessageOriginType,
    errorMessage?: string,
    fields?: Record<string, { name: string }>,
): void => {
    const t = useTypedTranslationFunction();
    const { url: domainUrl } = useDomainConfig();
    const [transportAndPaymentUrl] = getInternationalizedStaticUrls(['/order/transport-and-payment'], domainUrl);
    const router = useRouter();

    const handleOnReservationsDisabledAtPlace = () => {
        showErrorMessage(t('Reservations are disabled at this place'));
        router.events.off('routeChangeComplete', handleOnReservationsDisabledAtPlace);
    };

    useEffect(() => {
        if (error === undefined) {
            return;
        }

        const { userError, applicationError } = getUserFriendlyErrors(error, t);

        if (applicationError !== undefined) {
            switch (applicationError.type) {
                case ApplicationErrors['reservations-disabled-at-place']:
                    router.events.on('routeChangeComplete', handleOnReservationsDisabledAtPlace);
                    router.push(transportAndPaymentUrl);
                    break;
                default:
                    showErrorMessage(
                        errorMessage !== undefined && applicationError.type === ApplicationErrors.default
                            ? errorMessage
                            : applicationError.message,
                        origin,
                    );
            }
        }

        if (userError?.validation !== undefined) {
            for (const fieldName in userError.validation) {
                if (
                    fields !== undefined &&
                    Object.keys(fields).some((fieldKey) => fields[fieldKey].name === fieldName)
                ) {
                    formProviderMethods.setError(fieldName as Path<T>, userError.validation[fieldName]);
                    continue;
                }

                const validationCode = userError.validation[fieldName].code;
                const validationMessage = userError.validation[fieldName].message;
                showErrorMessage(
                    validationCode?.startsWith('AIRME_') ? t(validationMessage) : validationMessage,
                    origin,
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, t]);
};
