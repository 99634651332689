import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { BreadcrumbsMetadata } from 'components/Basic/Head/BreadcrumbsMetadata/BreadcrumbsMetadata';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { ArrowIcon, HomeIcon } from 'public/svg/IconsSvg';
import { Fragment } from 'react';
import { BreadcrumbItemType } from 'types/breadcrumb';
import { FriendlyPagesTypesKeys } from 'types/friendlyUrl';

type BreadcrumbsProps = {
    breadcrumb: BreadcrumbItemType[];
    type?: FriendlyPagesTypesKeys;
};
export const dividerClassName = 'mr-4 h-0 w-5 border-b border-solid border-grayDarker -indent-[9999px] notLg:hidden';
export const breadcrumbsLinkClassName =
    'mr-4 flex items-center py-2 pr-1 text-primary no-underline notLg:hidden notLg:last-of-type:[display:initial]';

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumb, type }) => {
    const testIdentifier = 'layout-breadcrumbs';
    const t = useTypedTranslationFunction();

    if (breadcrumb.length === 0) {
        return null;
    }

    return (
        <div
            data-testid={testIdentifier}
            className="-mt-2 mb-6 flex items-center text-small notLg:mb-5 notLg:text-primary"
        >
            <BreadcrumbsMetadata breadcrumbs={breadcrumb} />
            <ArrowIcon className="mr-1 h-3 w-3 rotate-90 lg:hidden" />
            <ExtendedNextLink
                href="/"
                type="static"
                className={breadcrumbsLinkClassName}
                data-testid={testIdentifier + '-item-root'}
            >
                <>
                    <HomeIcon className="mr-2 h-3 w-3 notLg:hidden" />
                    {t('Home page')}
                </>
            </ExtendedNextLink>
            <span className={dividerClassName}>/</span>
            {breadcrumb.slice(0, breadcrumb.length - 1).map((breadcrumb, index) => (
                <Fragment key={index}>
                    <ExtendedNextLink
                        href={breadcrumb.slug}
                        type={breadcrumb.type ?? type ?? 'static'}
                        className={breadcrumbsLinkClassName}
                        data-testid={testIdentifier + '-item-root'}
                    >
                        {breadcrumb.name}
                    </ExtendedNextLink>
                    <span className={dividerClassName}>/</span>
                </Fragment>
            ))}
            <span className="mr-4 text-grayDarker notLg:hidden ">{breadcrumb[breadcrumb.length - 1].name}</span>
        </div>
    );
};

export default Breadcrumbs;
