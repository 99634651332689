import { useSettings } from 'connectors/Settings/Settings';
import { getStoresAvailabilityMessage } from 'helpers/availability/pickupPointAvailability';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { twJoin } from 'tailwind-merge';

type ProductAvailableStoresCountProps = {
    isMainVariant: boolean;
    isInCentralStore: boolean;
    availableStoresCount: number;
    deliveryDaysFromCentralStore: string | null;
};

export const getProductAvailabilityEShopTwClass = (isInStock: boolean): string => {
    return isInStock ? 'text-green' : 'text-grayDarker';
};
export const productAvailabilityTwClass = 'mt-2 text-small font-medium leading-4 text-grayDarker';

const ProductAvailableStoresCount: FC<ProductAvailableStoresCountProps> = ({
    availableStoresCount,
    deliveryDaysFromCentralStore,
    isInCentralStore,
    isMainVariant,
}) => {
    const testIdentifier = 'blocks-product-availability';
    const settings = useSettings();
    const t = useTypedTranslationFunction();

    if (isMainVariant) {
        return null;
    }

    const isInCentralStoreWithEnabledReservationsAndNowhereElse =
        deliveryDaysFromCentralStore !== null && isInCentralStore && availableStoresCount === 0;

    return (
        <div
            className={twJoin('text-small leading-4', availableStoresCount !== 0 ? 'text-green' : 'text-grayDarker')}
            data-testid={testIdentifier}
        >
            {getStoresAvailabilityMessage(
                isInCentralStoreWithEnabledReservationsAndNowhereElse,
                availableStoresCount,
                deliveryDaysFromCentralStore,
                settings?.isAnyStockStore,
                t,
            )}
        </div>
    );
};

export default ProductAvailableStoresCount;
