import OrderSteps from 'components/Blocks/OrderSteps/OrderSteps';
import Webline from 'components/Layout/Webline/Webline';
import { ArticleDetailSkeleton } from 'components/Pages/ArticleDetail/ArticleDetailSkeleton';
import { BlogCategoryPageSkeleton } from 'components/Pages/BlogCategory/BlogCategoryPageSkeleton';
import { CartPageSkeleton } from 'components/Pages/Cart/CartPageSkeleton';
import { EmptyCartWidget } from 'components/Pages/Cart/EmptyCartWidget/EmptyCartWidget';
import { CategoryDetailPageSkeleton } from 'components/Pages/CategoryDetail/CategoryDetailPageSkeleton';
import { HomePageSkeleton } from 'components/Pages/Homepage/HomePageSkeleton';
import { TransportAndPaymentSkeleton } from 'components/Pages/Order/TransportAndPayment/TransportAndPaymentSkeleton';
import { ProductDetailPageSkeleton } from 'components/Pages/ProductDetail/ProductDetailPageSkeleton';
import { StoreDetailPageSkeleton } from 'components/Pages/StoreDetail/StoreDetailPageSkeleton';
import { StoresSkeleton } from 'components/Pages/Stores/StoresSkeleton';
import { useCurrentCart } from 'connectors/cart/Cart';
import { useEffect } from 'react';
import { useSessionStore } from 'store/useSessionStore';

export const SkeletonManager: FC = () => {
    const { isPageLoading, isFetchingDataForPage, destinationOfRedirect } = useSessionStore((s) => s);
    const { isCartEmpty } = useCurrentCart();
    useEffect(() => {
        if (isPageLoading) {
            window.scrollTo({ top: 0 });
        }
    }, [isPageLoading]);

    if (!isPageLoading && !isFetchingDataForPage) {
        return null;
    }

    switch (destinationOfRedirect) {
        case 'homepage':
            return <HomePageSkeleton />;
        case 'stores':
            return <StoresSkeleton />;
        case 'product':
            return <ProductDetailPageSkeleton />;
        case 'category':
        case 'brand':
        case 'flag':
            return <CategoryDetailPageSkeleton />;
        case 'article':
        case 'blogArticle':
            return <ArticleDetailSkeleton />;
        case 'blogCategory':
            return <BlogCategoryPageSkeleton />;
        case 'store':
            return <StoreDetailPageSkeleton />;
        case 'cart':
            return isCartEmpty ? (
                <>
                    <OrderSteps activeStep={1} tightTopMargin isOrderCreated={false} />
                    <Webline>
                        <EmptyCartWidget />
                    </Webline>
                </>
            ) : (
                <CartPageSkeleton />
            );
        case 'transport-and-payment':
            return <TransportAndPaymentSkeleton />;
        default:
            return null;
    }
};
