import Autocomplete from './Autocomplete/Autocomplete';
import { SearchFormMetaType } from './formMeta';
import withAutocompleteSearch from './withSearch';
import Overlay from 'components/Basic/Overlay/Overlay';
import Portal from 'components/Basic/Portal/Portal';
import TextInput from 'components/Forms/TextInput/TextInput';
import { useGtmSearchResultView } from 'hooks/gtm/useGtmSearchResultView';
import { usePersoo } from 'hooks/persoo/usePersoo';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { usePrevious } from 'hooks/ui/usePrevious';
import { PERSOO_IDS } from 'modules/persoo/persooTypes';
import { useRouter } from 'next/router';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { AutocompleteSearchFormType } from 'types/form';
import { AutocompleteSearchType } from 'types/search';

type SearchProps = {
    onAutocompleteSearchSubmitCallback: SubmitHandler<AutocompleteSearchFormType>;
    autocompleteSearchResults: AutocompleteSearchType | undefined;
    autocompleteSearchQueryValue: string;
    formProviderMethods: UseFormReturn<AutocompleteSearchFormType>;
    formMeta: SearchFormMetaType;
    fetching: boolean;
};

const Search: FC<SearchProps> = ({
    autocompleteSearchQueryValue,
    autocompleteSearchResults,
    fetching,
    formMeta,
    formProviderMethods,
    onAutocompleteSearchSubmitCallback,
}) => {
    const testIdentifier = 'layout-header-search-autocomplete-input';
    const { events } = useRouter();
    const [hasAutocompleteSearchFocus, setAutocompleteSearchFocus] = useState(false);
    const autocompleteSearchInRef = useRef<HTMLDivElement>(null);
    const { isPersooAllowed } = usePersoo();

    useGtmSearchResultView(autocompleteSearchResults, autocompleteSearchQueryValue, fetching);

    useEffectOnce(() => {
        const handler = () => {
            setAutocompleteSearchFocus(false);
            formProviderMethods.reset();
        };

        events.on('routeChangeComplete', handler);

        return () => {
            events.off('routeChangeComplete', handler);
        };
    });

    useEffect(() => {
        const node = autocompleteSearchInRef.current;
        if (!node) {
            return () => void null;
        }

        const handler: EventListener = (event) => {
            if (!(event.target instanceof Node) || !node.contains(event.target)) {
                setAutocompleteSearchFocus(false);
            }
        };

        document.addEventListener('click', handler);
        document.addEventListener('focus', handler, { capture: true });

        return () => {
            document.removeEventListener('click', handler);
            document.removeEventListener('focus', handler, { capture: true });
        };
    }, []);

    let autocompleteVisible =
        hasAutocompleteSearchFocus &&
        autocompleteSearchQueryValue.length > 2 &&
        autocompleteSearchResults !== undefined;

    const previousAutocompleteVisible = usePrevious(autocompleteVisible);

    autocompleteVisible = fetching && !previousAutocompleteVisible.current ? false : autocompleteVisible;

    const panelDockedFromLeft =
        autocompleteVisible &&
        autocompleteSearchResults !== undefined &&
        autocompleteSearchResults.productsSearch.totalCount > 0 &&
        (autocompleteSearchResults.categoriesSearch.totalCount > 0 ||
            autocompleteSearchResults.articlesSearch.length > 0);

    return (
        <>
            <div className="h-[48px] w-full lg:relative" id={isPersooAllowed ? PERSOO_IDS.autocomplete : undefined}>
                <Overlay withWrapper={false} className="hidden vl:block" isActive={autocompleteVisible} />
                <div
                    className={twJoin(
                        'relative lg:absolute lg:left-0 lg:top-0 lg:w-full',
                        autocompleteVisible && 'z-aboveMenu',
                    )}
                    ref={autocompleteSearchInRef}
                >
                    <form
                        className="relative flex w-full"
                        onSubmit={formProviderMethods.handleSubmit(onAutocompleteSearchSubmitCallback)}
                    >
                        <FormProvider {...formProviderMethods}>
                            <Controller
                                control={formProviderMethods.control}
                                name={formMeta.fields.autocompleteSearchQuery.name}
                                render={({ field: { onBlur, onChange, ref, value } }) => (
                                    <TextInput
                                        className="mb-0 w-full rounded-none border-2 border-t-0 border-primary pr-11 text-body placeholder:text-ellipsis vl:rounded-md vl:border-t-2"
                                        id={formMeta.getFieldId(formMeta.fields.autocompleteSearchQuery.name)}
                                        name={formMeta.fields.autocompleteSearchQuery.name}
                                        type="search"
                                        placeholderType="static"
                                        inputSize="small"
                                        variant="searchInHeader"
                                        onFocus={() => setAutocompleteSearchFocus(true)}
                                        label={formMeta.fields.autocompleteSearchQuery.label}
                                        onBlur={onBlur}
                                        onChange={(e) => {
                                            onChange(e);
                                            setAutocompleteSearchFocus(true);
                                        }}
                                        ref={ref}
                                        value={value}
                                        autoComplete="off"
                                        data-testid={testIdentifier}
                                        isLoading={fetching}
                                    />
                                )}
                            />
                        </FormProvider>
                    </form>
                    {autocompleteVisible && (
                        <Autocomplete
                            autocompleteSearchResults={autocompleteSearchResults}
                            autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                            isAutocompleteActive={autocompleteVisible}
                            isPanelDockedFromLeft={panelDockedFromLeft}
                            fetching={fetching}
                        />
                    )}
                </div>
            </div>
            {autocompleteVisible && (
                <Portal>
                    <Overlay withWrapper={false} className="z-overlay vl:hidden" isActive={autocompleteVisible} />
                </Portal>
            )}
        </>
    );
};

/* @component */
export default memo(withAutocompleteSearch(Search));
