import { theme } from 'components/Theme/main';
import { mapAvailabilityData } from 'connectors/availability/Availability';
import { getFirstImage } from 'connectors/image/Image';
import { mapProductPriceData, mapProductPricesData } from 'connectors/price/Prices';
import { ProductColorVariantFragmentApi, SimpleProductFragmentApi } from 'graphql/generated';
import { ColorVariantType, SimpleProductType } from 'types/product';

export const mapSimpleProductApiData = (
    simpleProductApiData: SimpleProductFragmentApi,
    currencyCode: string,
): SimpleProductType => {
    return {
        ...simpleProductApiData,
        price: mapProductPriceData(simpleProductApiData.price, currencyCode),
        prices: mapProductPricesData(simpleProductApiData.prices, currencyCode),
        image: getFirstImage(simpleProductApiData.images),
        unitName: simpleProductApiData.unit.name,
        categoryNames: simpleProductApiData.categories.map((category) => category.name),
        availability: mapAvailabilityData(simpleProductApiData.availability),
        colorVariants: mapColorVariants(simpleProductApiData.colorVariants),
        currentColorVariant:
            'currentColorVariant' in simpleProductApiData
                ? mapColorVariant(simpleProductApiData.currentColorVariant)
                : null,
    };
};

const mapColorVariant = (variant: ProductColorVariantFragmentApi): ColorVariantType => ({
    ...variant,
    value: variant.value ?? theme.color.gray,
    colorImage: getFirstImage(variant.parameterValueImages),
});

const mapColorVariants = (colorVariants: SimpleProductFragmentApi['colorVariants']): ColorVariantType[] => {
    const mappedData = [];

    for (const variant of colorVariants) {
        if (variant.value !== null) {
            mappedData.push(mapColorVariant(variant));
        }
    }

    return mappedData;
};
