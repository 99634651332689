import { useNewsletterForm, useNewsletterFormMeta } from './formMeta';
import Image from 'components/Basic/Image/Image';
import Button from 'components/Forms/Button/Button';
import Checkbox from 'components/Forms/Checkbox/Checkbox';
import Form from 'components/Forms/Form/Form';
import { GdprConsentsWrapper } from 'components/Forms/GdprConsentsWrapper/GdprConsentsWrapper';
import ChoiceFormLine from 'components/Forms/Lib/ChoiceFormLine/ChoiceFormLine';
import { ErrorPopupProps } from 'components/Forms/Lib/ErrorPopup/ErrorPopup';
import FormLine from 'components/Forms/Lib/FormLine/FormLine';
import FormLineError from 'components/Forms/Lib/FormLineError/FormLineError';
import TextInput from 'components/Forms/TextInput/TextInput';
import { showSuccessMessage } from 'components/Helpers/Toasts';
import { useFooterSetting } from 'connectors/footerSetting/footerSetting';
import { useGdprConsents } from 'connectors/gdpr/gdprConsents';
import { GdprActionTypeEnumApi, useNewsletterSubscribeMutationApi } from 'graphql/generated';
import { useHandleErrorPopupVisibility } from 'hooks/forms/UseHandleErrorPopupVisibility';
import { useHandleFormErrors } from 'hooks/forms/UseHandleFormErrors';
import { useHandleFormSuccessfulSubmit } from 'hooks/forms/UseHandleFormSuccessfulSubmit';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import dynamic from 'next/dynamic';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form';
import { NewsletterFormType } from 'types/form';

const ErrorPopup = dynamic<ErrorPopupProps>(() =>
    import('components/Forms/Lib/ErrorPopup/ErrorPopup').then((component) => component.ErrorPopup),
);

/**
 * Newsletter form block, which is displayed in the Footer section and serves as
 * a signup form for the Newsletter.
 */
const NewsletterForm: FC = () => {
    const testIdentifier = 'layout-footer-newsletterform';

    const t = useTypedTranslationFunction();
    const [subscribeToNewsletterResult, subscribeToNewsletter] = useNewsletterSubscribeMutationApi();
    const [formProviderMethods, defaultValues] = useNewsletterForm();
    const footerSetting = useFooterSetting();
    const formMeta = useNewsletterFormMeta(formProviderMethods);
    const [isErrorPopupVisible, setErrorPopupVisibility] = useHandleErrorPopupVisibility(formProviderMethods);
    const gdprConsents = useGdprConsents(GdprActionTypeEnumApi.EmailApi, null);
    useHandleFormErrors(subscribeToNewsletterResult.error, formProviderMethods, 'footer', formMeta.messages.error);
    useHandleFormSuccessfulSubmit(
        subscribeToNewsletterResult,
        formProviderMethods,
        defaultValues,
        () => showSuccessMessage(formMeta.messages.success),
        { blur: true, reset: true },
    );

    const onSubscribeToNewsletterHandler: SubmitHandler<NewsletterFormType> = async (data, event) => {
        event?.preventDefault();
        await subscribeToNewsletter(data);
    };

    if (footerSetting === null || footerSetting.newsletterImage === null) {
        return null;
    }

    return (
        <>
            <div className="flex items-center border-b border-b-borderLight px-12" data-testid={testIdentifier}>
                <div className="align-stretch mr-14 mb-2 w-40 shrink-0 xl:h-[140px] notVl:hidden">
                    <Image
                        src={footerSetting.newsletterImage}
                        alt={t('Join the 30 000 subscribers of our newsletter')}
                        width="160"
                        height="140"
                    />
                </div>
                <div className="py-5 text-[20px] font-light leading-7 vl:my-7 vl:mr-10 vl:w-[220px] vl:min-w-[200px] vl:p-0 vl:text-right xl:min-w-[220px] xl:text-[24px]">
                    {t('Join the 30 000 subscribers of our newsletter')}
                </div>
                <div className="w-full">
                    <FormProvider {...formProviderMethods}>
                        <Form
                            className="notSm:mt-4"
                            onSubmit={formProviderMethods.handleSubmit(onSubscribeToNewsletterHandler)}
                            noValidate
                        >
                            <div className="mb-3 flex notLg:mb-2 notLg:flex-col">
                                <FormLine>
                                    <Controller
                                        name={formMeta.fields.email.name}
                                        render={({ fieldState: { error }, field }) => (
                                            <>
                                                <TextInput
                                                    className="border-2 border-grayDark pl-5 pr-5"
                                                    id={formMeta.formName + '-' + formMeta.fields.email.name}
                                                    name={formMeta.fields.email.name}
                                                    label={formMeta.fields.email.label}
                                                    required
                                                    type="text"
                                                    fieldRef={field}
                                                />
                                                <FormLineError
                                                    className="ml-2"
                                                    innerClassName="ml-2"
                                                    textInputSize="small"
                                                    error={error}
                                                    inputType="text-input"
                                                    data-testid={
                                                        formMeta.formName + '-' + formMeta.fields.email.name + '-error'
                                                    }
                                                />
                                            </>
                                        )}
                                    />
                                </FormLine>
                                <div className="flex flex-col">
                                    <Button className="ml-3 active:scale-95 notLg:ml-0 notLg:mt-3" type="submit">
                                        {t('subscribe')}
                                    </Button>
                                </div>
                            </div>
                            <GdprConsentsWrapper
                                data-testid="gdprConsents"
                                render={(handler, value) => (
                                    <div className="gdpr-consent ml-5 text-tiny leading-4 text-grayVeryDark">
                                        {gdprConsents?.map(({ type, isRequired, text }, index) => {
                                            return (
                                                <ChoiceFormLine key={type}>
                                                    <Checkbox
                                                        name={`gdprConsents.${index}`}
                                                        label={
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: text ?? '',
                                                                }}
                                                            />
                                                        }
                                                        value={type}
                                                        checked={value.includes(type)}
                                                        onChange={handler(type)}
                                                        required={isRequired}
                                                    />
                                                </ChoiceFormLine>
                                            );
                                        })}
                                    </div>
                                )}
                            />
                        </Form>
                    </FormProvider>
                </div>
            </div>
            {isErrorPopupVisible && (
                <ErrorPopup
                    onCloseCallback={() => setErrorPopupVisibility(false)}
                    fields={formMeta.fields}
                    origin="footer"
                />
            )}
        </>
    );
};

/* @component */
export default NewsletterForm;
