import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import styled, { css } from 'styled-components';

export const LoginUnderHeaderStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100vw;
        position: fixed;
        top: 200px;
        left: 0;
        right: 0;
        z-index: ${theme.zIndex.menu};

        font-weight: 300;

        @media ${theme.mediaQueries.queryVl} {
            width: 100%;
            position: absolute;
            top: 100%;
            right: -110px;
            left: auto;
            min-width: 936px;
            margin-top: 8px;
            flex-direction: row;
            overflow: hidden;

            border-radius: ${theme.radius.medium};
        }

        @media ${theme.mediaQueries.queryXl} {
            right: 0;
        }
    `,
);

export const LoginContentStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        cursor: default;

        @media ${theme.mediaQueries.queryVl} {
            flex-direction: row;
            overflow: hidden;
        }
    `,
);

export const LoginColumnStyled = styled.div(
    ({ theme }) => css`
        width: 100%;
        padding: 25px 30px;

        &:nth-child(1) {
            order: 1;

            background-color: ${theme.color.grayLighter};
        }

        &:nth-child(2) {
            background-color: ${theme.color.white};
        }

        @media ${theme.mediaQueries.queryVl} {
            width: 50%;
            padding-right: 20px;

            &:nth-child(1) {
                padding: 40px;
                order: 0;
            }

            &:nth-child(2) {
                padding: 40px 80px 40px 40px;
            }
        }
    `,
);

export const LoginLostPassLinkStyled = styled(ExtendedNextLink)(
    ({ theme }) => css`
        line-height: 16px;

        text-decoration: underline;
        font-size: 14px;
        word-break: break-word;
        color: ${theme.color.primary};

        &:hover {
            text-decoration: none;
            color: ${theme.color.grayDarker};
        }
    `,
);

export const ClubStyled = styled.div(
    ({ theme }) => css`
        position: relative;
        padding-right: 120px;

        @media ${theme.mediaQueries.queryVl} {
            padding-right: 0;
            padding-left: 120px;
        }
    `,
);

export const ClubIconStyled = styled.div(
    ({ theme }) => css`
        position: absolute;
        right: 0;
        top: 0;
        width: 90px;

        @media ${theme.mediaQueries.queryVl} {
            left: 0;
        }
    `,
);

export const ClubHeadingStyled = styled.h3<{ $weight?: number }>(
    ({ theme, $weight = 700 }) => css`
        margin-bottom: 12px;
        line-height: 26px;

        font-size: 21px;
        font-weight: ${$weight};

        @media ${theme.mediaQueries.queryVl} {
            margin-bottom: 22px;
            line-height: 32px;

            font-size: 28px;
        }
    `,
);

export const ClubBenefitsListStyled = styled.ul(
    ({ theme }) => css`
        display: none;

        svg {
            margin-right: 10px;

            color: ${theme.color.red};
        }

        @media ${theme.mediaQueries.queryVl} {
            display: block;
            margin-bottom: 30px;
        }
    `,
);

export const ClubBenefitsItemStyled = styled.li`
    margin-bottom: 10px;
    line-height: 20px;

    font-size: 16px;
`;

export const ButtonsStyled = styled.div(
    ({ theme }) => css`
        margin: 15px 0 0 0;
        column-gap: 10px;

        @media ${theme.mediaQueries.queryVl} {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    `,
);

export const ButtonsDividerStyled = styled.div(
    ({ theme }) => css`
        height: 1px;
        margin: 20px 0;

        background-color: ${theme.color.grayMidDarker};

        @media ${theme.mediaQueries.queryVl} {
            display: none;
        }
    `,
);

export const MembershipBenefitsLink = styled.a(
    ({ theme }) => css`
        display: block;
        margin-bottom: 30px;

        color: ${theme.color.primary};

        &:hover {
            color: ${theme.color.grayDarker};
        }

        @media ${theme.mediaQueries.queryVl} {
            display: none;
        }
    `,
);
