import React, { HTMLAttributes } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<
    HTMLAttributes<HTMLHeadingElement>,
    never,
    'className' | 'style' | 'onClick'
>;

type HeadingProps = NativeProps & {
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    'data-testid'?: string;
};

const Heading: FC<HeadingProps> = ({ type, children, className, ...props }) => {
    const testIdentifier = `basic-heading-${type}`;
    const TagName = type;

    return (
        <TagName
            className={twMerge(
                'mb-2 font-bold text-primary [text-rendering:optimizeLegibility] [word-wrap:break-word]',
                type === 'h1' && 'text-[30px] leading-9 lg:mb-4 lg:text-h1',
                type === 'h2' && 'text-h3 leading-8 lg:text-h2',
                type === 'h3' && 'text-h4 leading-7 lg:mb-4 lg:text-h3 lg:leading-8',
                type === 'h4' && 'text-h5 leading-[30px] lg:text-h4 lg:leading-6',
                type === 'h5' && 'text-[21px] leading-8 lg:text-h5 lg:leading-5',
                type === 'h6' && 'text-h6 font-normal leading-6',
                className,
            )}
            data-testid={testIdentifier}
            {...props}
        >
            {children}
        </TagName>
    );
};

export default Heading;
