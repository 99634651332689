import {
    DRAWER_DEFAULT_LENGTH_IN_CM,
    DRAWER_DEFAULT_WIDTH_IN_CM,
    SIZES_OF_CELL_IN_CM,
} from 'helpers/configurator/configuratorConstants';
import { calculateDrawerPaddings } from 'helpers/configurator/configuratorHelpers';
import {
    ConfiguratorDrawerPaddingsType,
    ConfiguratorDrawerSizesType,
    ConfiguratorProductType,
} from 'types/configurator';
import { StateCreator } from 'zustand';

export type ConfiguratorSlice = {
    drawerSizes: ConfiguratorDrawerSizesType;
    actualCellWidthInPixels: number;
    productsInDrawer: ConfiguratorProductType[];
    drawerPaddings: ConfiguratorDrawerPaddingsType;
    updateConfiguratorState: (value: Partial<ConfiguratorSlice>) => void;
    updateConfiguratorProduct: (value: Partial<ConfiguratorProductType>, productId: string) => void;
    deleteConfiguratorProduct: (productId: string) => void;
};

const drawerSizesWithoutRounding = {
    width: DRAWER_DEFAULT_WIDTH_IN_CM / SIZES_OF_CELL_IN_CM.width,
    length: DRAWER_DEFAULT_LENGTH_IN_CM / SIZES_OF_CELL_IN_CM.length,
};

export const createConfiguratorSlice: StateCreator<ConfiguratorSlice> = (set) => ({
    drawerSizes: {
        width: Math.floor(drawerSizesWithoutRounding.width),
        length: Math.floor(drawerSizesWithoutRounding.length),
    },
    drawerPaddings: calculateDrawerPaddings(drawerSizesWithoutRounding),
    actualCellWidthInPixels: 0,
    productsInDrawer: [],

    updateConfiguratorProduct: (value, productId) => {
        set((state) => {
            const updatedProducts = state.productsInDrawer.map<ConfiguratorProductType>((product) => {
                if (product.id === productId) {
                    return { ...product, ...value };
                }

                return product;
            });

            return { productsInDrawer: updatedProducts };
        });
    },

    deleteConfiguratorProduct: (productId) => {
        set((state) => {
            return { productsInDrawer: state.productsInDrawer.filter((product) => product.id !== productId) };
        });
    },

    updateConfiguratorState: (value) => {
        set(value);
    },
});
