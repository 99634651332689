import { FieldError } from 'react-hook-form';
import twMerge from 'utils/twMerge';

type FormLineErrorProps = {
    inputType: 'textarea' | 'text-input' | 'checkbox' | 'text-input-password' | 'select';
    textInputSize?: 'small';
    error?: FieldError;
    'data-testid'?: string;
    className?: React.ComponentProps<'div'>['className'];
    innerClassName?: React.ComponentProps<'div'>['className'];
};

const FormLineError: FC<FormLineErrorProps> = ({ innerClassName, className, inputType, error, ...props }) => {
    const testIdentifier = props['data-testid'] ?? 'forms-error';

    if (error) {
        return (
            <div
                className={twMerge(
                    'relative my-1',
                    'after:pointer-events-none after:absolute after:-top-4 after:-left-1 after:-right-1 after:bottom-0 after:-z-above',
                    inputType !== 'checkbox' && 'px-2',
                    inputType !== 'checkbox' && 'after:rounded-b-lg after:bg-[#f5778f]',
                    className,
                )}
                data-testid={testIdentifier}
            >
                <span className={twMerge('text-tiny leading-5 text-red', innerClassName)}>{error.message}</span>
            </div>
        );
    }

    return null;
};

export default FormLineError;
