import { useCurrentUserData } from './user/useCurrentUserData';
import { useState } from 'react';
import { UserSlice } from 'store/slices/createUserSlice';
import { usePersistStore } from 'store/usePersistStore';

export const useSetUserSlice = (userState: Partial<UserSlice>) => {
    const [isConfigSet, setIsConfigSet] = useState(false);
    const { isUserLoggedIn } = useCurrentUserData();
    const updateDefaultStoreUuidState = usePersistStore((state) => state.updateDefaultStoreUuidState);
    const updateCartUuidState = usePersistStore((state) => state.updateCartUuidState);

    /**
     * React complains about too many rerenders, probably because it is setting twice
     * and because it needs to run also on SSR we cannot use useEffect here
     */
    if (!isConfigSet) {
        updateDefaultStoreUuidState(userState.defaultStoreUuid ?? null);
        updateCartUuidState(isUserLoggedIn ? null : userState.cartUuid ?? null);
        setIsConfigSet(true);
    }
};
