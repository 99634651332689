import { getFirstImage } from 'connectors/image/Image';
import { NotificationBarsFragmentApi, useNotificationBarsApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { NotificationBarsType } from 'types/notificationBars';

export const useNotificationBars = (): NotificationBarsType[] => {
    const [{ data, error }] = useNotificationBarsApi();
    useQueryError(error);

    if (data === undefined || data.notificationBars === null) {
        return [];
    }

    return mapNotificationBars(data.notificationBars);
};

const mapNotificationBars = (apiData: NotificationBarsFragmentApi[]): NotificationBarsType[] => {
    const mappedNotificationBars = [];

    for (const notificationBarItem of apiData) {
        mappedNotificationBars.push({
            ...notificationBarItem,
            mobileImage: getFirstImage(notificationBarItem.mobileImage),
            webImage: getFirstImage(notificationBarItem.webImage),
        });
    }

    return mappedNotificationBars;
};
