import { useState } from 'react';
import { GeneralSliceType } from 'store/slices/createGeneralSlice';
import { useSessionStore } from 'store/useSessionStore';

export const useSetGeneralSlice = (data: Partial<GeneralSliceType>): void => {
    const [isConfigSet, setIsConfigSet] = useState(false);

    const updateGeneralState = useSessionStore((state) => state.updateGeneralState);

    /**
     * React complains about too many rerenders, probably because it is setting twice
     * and because it needs to run also on SSR we cannot use useEffect here
     */
    if (!isConfigSet) {
        updateGeneralState(data);
        setIsConfigSet(true);
    }
};
