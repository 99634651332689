import styled, { css } from 'styled-components';

export const EmptyCartWidgetStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 80px 16px;

        svg {
            margin-bottom: 24px;
        }

        p {
            text-align: center;

            font-size: 28px;
            font-weight: 700;
        }

        @media ${theme.mediaQueries.queryMd} {
            flex-direction: row;

            svg {
                margin-bottom: 0;
                margin-right: 44px;
            }
        }
    `,
);
