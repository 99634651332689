import { MINIMAL_SEARCH_QUERY_LENGTH } from 'connectors/search/AutocompleteSearch';
import useDebounce from 'hooks/helpers/UseDebounce';
import { useEffect, useRef } from 'react';
import { AutocompleteSearchType } from 'types/search';
import { getGtmSearchResultEvent } from 'utils/Gtm/EventFactories';
import { gtmSafePushEvent } from 'utils/Gtm/Gtm';

const KEYWORD_DEBOUNCE_DELAY = 1500;

export const useGtmSearchResultView = (
    searchResult: AutocompleteSearchType | undefined,
    keyword: string,
    fetching: boolean,
): void => {
    const lastSearchedKeywordRef = useRef<string | null>(null);
    const debouncedKeyword = useDebounce(keyword, KEYWORD_DEBOUNCE_DELAY);

    useEffect(() => {
        if (
            searchResult &&
            debouncedKeyword.length >= MINIMAL_SEARCH_QUERY_LENGTH &&
            lastSearchedKeywordRef.current !== debouncedKeyword &&
            !fetching
        ) {
            const event = getGtmSearchResultEvent(searchResult, debouncedKeyword);
            gtmSafePushEvent(event);
        }
    }, [debouncedKeyword, searchResult, fetching]);

    useEffect(() => {
        lastSearchedKeywordRef.current = debouncedKeyword;
    }, [debouncedKeyword]);
};
