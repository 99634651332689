import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { twJoin } from 'tailwind-merge';
import tinycolor from 'tinycolor2';
import twMerge from 'utils/twMerge';

type Props = {
    'data-testid'?: string;
    backgroundColor: string;
    fixedWidth?: boolean;
    flagLink?: string;
    large?: boolean;
    className?: React.ComponentProps<'div'>['className'];
};

export const Flag: FC<Props> = ({
    children,
    large = false,
    fixedWidth = false,
    backgroundColor,
    'data-testid': testIdentifier = 'basic-flag',
    flagLink,
    className,
}) => {
    const isDefaultVariant = backgroundColor === '#ffffff';

    const flagTwClass = twJoin(
        'inline-flex justify-center items-center p-1 text-tiny font-bold web-transition whitespace-nowrap leading-[8px] hover:no-underline cursor-pointer',
        fixedWidth && 'w-16',
        large && 'min-h-8',
        isDefaultVariant && 'border-solid border border-grayDarker',
        !isDefaultVariant && 'text-white',
        isDefaultVariant && 'text-grayDarker',
    );

    const colorStyles = {
        backgroundColor: isDefaultVariant ? '#fff' : tinycolor(backgroundColor).toString(),
    };

    if (flagLink !== undefined) {
        return (
            <ExtendedNextLink type="flag" href={flagLink} className="no-underline">
                <div
                    className={twMerge(flagTwClass, className)}
                    style={colorStyles}
                    data-testid={testIdentifier}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </div>
            </ExtendedNextLink>
        );
    }

    return (
        <div className={flagTwClass} style={colorStyles} data-testid={testIdentifier}>
            {children}
        </div>
    );
};
