type VshImageLoaderProps = {
    src: string;
    width: number;
};

const vshImageLoader = ({ src, width }: VshImageLoaderProps) => {
    const splittedSrc = src.split('.');
    const isSvg = splittedSrc[splittedSrc.length - 1] === 'svg';

    if (isSvg) {
        return src;
    }

    return `${src}?width=${width || 'auto'}`;
};

export default vshImageLoader;
