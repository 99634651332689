import {
    getGtmChangeCartItemEvent,
    getGtmPaymentInfoEvent,
    getGtmProductDetailOnClickEvent,
    getGtmSearchClickEvent,
    getGtmShippingInfoEvent,
    getNewGtmEcommerceEvent,
} from './EventFactories';
import { getGtmPaymentData, getGtmPurchaseData, getGtmPurchaseUserInfo, gtmSafePushEvent } from './Gtm';
import { mapPayment } from 'connectors/payments/Payment';
import { mapTransport } from 'connectors/transports/Transports';
import {
    OrderDetailFragmentApi,
    SimplePaymentFragmentApi,
    TransportWithAvailablePaymentsAndStoresFragmentApi,
} from 'graphql/generated';
import { CartItemType, CartType } from 'types/cart';
import { CurrentCustomerType } from 'types/customer';
import {
    GtmCartInfoType,
    GtmConsentInfoType,
    GtmConsentUpdateType,
    GtmListNameType,
    GtmRegistrationDetailType,
    GtmRegistrationEventType,
    GtmRegistrationOriginType,
    GtmSectionType,
} from 'types/gtm';
import { OrderDetailType } from 'types/orders';
import { PaymentType } from 'types/payment';
import { PickupPointType } from 'types/pickupPoint';
import { AutocompleteProductType, ListedProductType, SimpleProductType } from 'types/product';
import { PromoCodeType } from 'types/promoCode';
import { MappedTransportType } from 'types/transport';

export const onClickProductDetailGtmEventHandler = (
    product: ListedProductType | SimpleProductType | AutocompleteProductType,
    listName: GtmListNameType,
    index: number,
    domainUrl: string,
): void => {
    const event = getNewGtmEcommerceEvent('ec.product_click', true);
    event.ecommerce = getGtmProductDetailOnClickEvent(product, listName, index, domainUrl);
    gtmSafePushEvent(event);
};

export const onRemoveCartItemGtmEventHandler = (
    gtmCartInfo: GtmCartInfoType | undefined | null,
    removedCartItem: CartItemType,
    currencyCode: string,
    eventValue: number,
    eventValueWithTax: number,
    listIndex: number,
    listName: GtmListNameType,
    domainUrl: string,
): void => {
    const event = getNewGtmEcommerceEvent('ec.remove_from_cart', true);
    event.ecommerce = getGtmChangeCartItemEvent(
        removedCartItem,
        listIndex,
        removedCartItem.quantity,
        currencyCode,
        eventValue,
        eventValueWithTax,
        listName,
        domainUrl,
    );
    event.cart = gtmCartInfo ?? null;
    gtmSafePushEvent(event);
};

export const onChangeCartItemGtmEventHandler = (
    gtmCartInfo: GtmCartInfoType | undefined | null,
    addedCartItem: CartItemType,
    currencyCode: string,
    eventValue: number,
    eventValueWithTax: number,
    listIndex: number | null,
    quantityDifference: number,
    listName: GtmListNameType,
    domainUrl: string,
): void => {
    const event = getNewGtmEcommerceEvent('ec.add_to_cart', true);
    if (quantityDifference < 0) {
        event.event = 'ec.remove_from_cart';
    }
    const absoluteQuantity = Math.abs(quantityDifference);
    event.ecommerce = getGtmChangeCartItemEvent(
        addedCartItem,
        listIndex,
        absoluteQuantity,
        currencyCode,
        eventValue,
        eventValueWithTax,
        listName,
        domainUrl,
    );
    event.cart = gtmCartInfo ?? null;
    gtmSafePushEvent(event);
};

export const onTransportChangeGtmEventHandler = (
    gtmCartInfo: GtmCartInfoType | undefined | null,
    updatedTransport: TransportWithAvailablePaymentsAndStoresFragmentApi | null,
    updatedPickupPoint: PickupPointType | null,
    updatedPaymentName: string | undefined,
    currencyCode: string,
): void => {
    if (gtmCartInfo && updatedTransport !== null) {
        const event = getNewGtmEcommerceEvent('ec.shipping_info', true);
        const mappedTransport = mapTransport(updatedTransport, currencyCode);
        event.ecommerce = getGtmShippingInfoEvent(gtmCartInfo, mappedTransport, updatedPickupPoint, updatedPaymentName);
        gtmSafePushEvent(event);
    }
};

export const onPaymentChangeGtmEventHandler = (
    gtmCartInfo: GtmCartInfoType | undefined | null,
    updatedPayment: SimplePaymentFragmentApi | null,
    currencyCode: string,
): void => {
    if (gtmCartInfo && updatedPayment !== null) {
        const event = getNewGtmEcommerceEvent('ec.payment_info', true);
        const mappedPayment = mapPayment(updatedPayment, currencyCode);
        event.ecommerce = getGtmPaymentInfoEvent(gtmCartInfo, mappedPayment);
        gtmSafePushEvent(event);
    }
};

export const onPurchaseOrderGtmEventHandler = (
    cart: CartType,
    transport: MappedTransportType,
    pickupPoint: PickupPointType | null,
    payment: PaymentType,
    promoCodes: PromoCodeType[],
    order: OrderDetailFragmentApi,
    currentCustomer: CurrentCustomerType | null | undefined,
    domainUrl: string,
    sendQuestionnaire: boolean,
): void => {
    const event = getNewGtmEcommerceEvent('ec.purchase', true);
    event.ecommerce = getGtmPurchaseData(
        cart,
        transport,
        pickupPoint,
        payment,
        promoCodes,
        order.number,
        domainUrl,
        sendQuestionnaire,
    );
    event.user = getGtmPurchaseUserInfo(order, currentCustomer);
    gtmSafePushEvent(event);
};

export const onPaymentOrderGtmEventHandler = (
    order: OrderDetailType,
    currentCustomer: CurrentCustomerType | null | undefined,
): void => {
    const event = getNewGtmEcommerceEvent('ec.payment', true);
    event.ecommerce = getGtmPaymentData(order);
    event.user = getGtmPurchaseUserInfo(order, currentCustomer);
    gtmSafePushEvent(event);
};

export const onClickSuggestResultGtmEventHandler = (
    keyword: string,
    section: GtmSectionType,
    itemName: string,
): void => {
    const event = getGtmSearchClickEvent(keyword, section, itemName);
    gtmSafePushEvent(event);
};

export const onConsentUpdateGtmEventHandler = (updatedConsent: GtmConsentInfoType): void => {
    const event: GtmConsentUpdateType = {
        event: 'consent.update',
        consent: updatedConsent,
    };
    gtmSafePushEvent(event);
};

export const onRegistrationGtmEventHandler = (origin: GtmRegistrationOriginType): void => {
    const detail: GtmRegistrationDetailType = {
        origin: origin,
    };
    const event: GtmRegistrationEventType = {
        event: 'ec.registration',
        registrationDetail: detail,
    };
    gtmSafePushEvent(event);
};
