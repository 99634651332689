import { BreadcrumbsSkeleton } from 'components/Layout/Breadcrumbs/BreadcrumbsSkeleton';
import Webline from 'components/Layout/Webline/Webline';
import Skeleton from 'react-loading-skeleton';

export const ArticleDetailSkeleton: FC = () => {
    return (
        <Webline>
            <BreadcrumbsSkeleton count={3} />
            <div className="m-auto mb-12 max-w-[800px]">
                <Skeleton className="h-10 w-full vl:w-4/5" />
                <Skeleton className="mt-0.5 h-10 w-2/3 lg:hidden" />
                <div className="mt-6 mb-4 flex gap-5 vl:mt-0 vl:gap-10">
                    <Skeleton className="h-4 w-24" />
                    <Skeleton className="h-4 w-16" />
                </div>
                <Skeleton className="mb-6 aspect-video w-full !rounded-none" />
                <Skeleton className="h-3 w-full" count={3} />
                <Skeleton className="h-3 w-3/5" />
                <Skeleton className="mb-3 mt-6 aspect-video w-full !rounded-none" />
                <Skeleton className="mb-6 aspect-video w-full !rounded-none" />
                <Skeleton className="h-3 w-full" count={5} />
                <Skeleton className="h-3 w-1/5" />
                <Skeleton className="my-6 aspect-video w-full !rounded-none" />
                <Skeleton className="h-3 w-full" count={3} />
                <Skeleton className="h-3 w-2/3" />
                <Skeleton className="mt-4 h-3 w-2/5" />
            </div>
        </Webline>
    );
};
