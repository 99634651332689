import styled, { css } from 'styled-components';

const localVariables = {
    orderSummaryWrapperWidth: '420px',
    marginBottom: '20px',
    totalPriceFontSize: '36px',
    ItemImageSize: '80px',
} as const;

export const OrderSummaryWrapperStyled = styled.div`
    ${({ theme }) => css`
        width: 100%;

        @media ${theme.mediaQueries.queryVl} {
            width: ${localVariables.orderSummaryWrapperWidth};
        }
    `}
`;

export const OrderSummaryTitleStyled = styled.h3`
    ${({ theme }) => css`
        margin-bottom: 20px;

        font-size: ${theme.fontSize.default};
        font-weight: 700;

        @media ${theme.mediaQueries.queryLg} {
            font-size: ${theme.fontSize.bigger};
        }
    `}
`;

export const OrderSummaryTitleInsideStyled = styled(OrderSummaryTitleStyled)`
    margin: 36px 0 -10px 40px;
`;

export const OrderSummaryContentWrapperStyled = styled.div`
    ${({ theme }) => css`
        border: 5px solid ${theme.color.grayLight};
        border-radius: 7px;
    `}
`;

export const OrderSummaryContentStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ProductsPreviewStyled = styled.div``;

export const OrderSummaryListStyled = styled.ul`
    margin: 0;
    padding: 10px 20px 0 20px;

    list-style: none;
`;

export const OrderSummaryBottomStyled = styled.div`
    ${({ theme }) => css`
        padding: 25px 20px 10px 20px;

        background-color: ${theme.color.grayLight};
    `}
`;

export const ListItemStyled = styled.li`
    display: flex;
    align-items: center;
    padding: 20px 0;

    border-bottom: 1px solid #e9e9e9;

    &:last-child {
        border-bottom: none;
    }
`;

export const ListItemInfoWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ListItemPictureWrapperStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: start;
    width: ${localVariables.ItemImageSize};
    height: ${localVariables.ItemImageSize};
    margin-right: 15px;
    flex-shrink: 0;
`;

export const ListItemInfoStyled = styled.div`
    padding-right: 10px;
    flex: 1;
    font-size: 14px;
`;

export const ListItemPriceWrapperStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
`;

export const ListItemPriceStyled = styled.span`
    flex: 1;

    font-size: 14px;
`;

export const ListItemQuantityStyled = styled.strong`
    font-size: 14px;
    margin-right: 40%;
`;

export const ListItemPriceTotalStyled = styled.strong`
    ${({ theme }) => css`
        flex: 1;
        text-align: right;
        font-size: 16px;
        font-weight: bold;

        @media ${theme.mediaQueries.queryVl} {
            font-size: 18px;
        }
    `}
`;

export const DiscountTextStyled = styled.div`
    ${({ theme }) => css`
        display: table-row;
        padding: 6px 0;
        vertical-align: baseline;
        line-height: ${theme.lineHeight.default};

        font-size: 15px;
    `}
`;

export const DiscountPriceStyled = styled.div`
    ${({ theme }) => css`
        padding: 6px 0;
        vertical-align: baseline;
        line-height: ${theme.lineHeight.default};
        text-align: right;

        font-size: 15px;
    `}
`;

export const OrderSummaryRowWrapperStyled = styled.div`
    ${({ theme }) => css`
        padding-bottom: 10px;
        margin-bottom: ${localVariables.marginBottom};

        border-bottom: 1px solid ${theme.color.blueVeryLight};
    `}
`;

export const OrderSummaryRowContentStyled = styled.div`
    display: table;
    width: 100%;
`;

export const OrderSummaryRowStyled = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PriceWrapperStyled = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;

        @media ${theme.mediaQueries.queryVl} {
            flex-direction: row;
        }
    `}
`;

export const OrderSummaryTextAndImageStyled = styled.div`
    ${({ theme }) => css`
        display: table-row;
        padding: 6px 0;
        vertical-align: baseline;
        line-height: ${theme.lineHeight.default};

        font-size: 15px;
    `}
`;

export const OrderSummaryPriceStyled = styled.div`
    ${({ theme }) => css`
        padding: 6px 0;
        vertical-align: baseline;
        line-height: ${theme.lineHeight.default};
        text-align: right;

        font-size: 15px;
    `}
`;

export const OrderSummaryTotalPriceWrapperStyled = styled.div``;

export const OrderSummaryTotalPriceTextStyled = styled.span`
    font-size: 18px;
`;

export const OrderSummaryTotalPriceAmountStyled = styled.strong`
    ${({ theme }) => css`
        color: ${theme.color.green};

        font-size: ${localVariables.totalPriceFontSize};
        font-weight: 700;

        @media ${theme.mediaQueries.queryVl} {
            margin: 0 0 0 10px;
        }
    `}
`;
