import Link from 'components/Basic/Link/Link';
import AddToCart from 'components/Blocks/Product/AddToCart/AddToCart';
import WatchDog from 'components/Blocks/Product/WatchDog/WatchDog';
import Button from 'components/Forms/Button/Button';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useRouter } from 'next/dist/client/router';
import { AvailabilityStatusEnum } from 'types/availability';
import { GtmListNameType } from 'types/gtm';
import { ProductActionType } from 'types/product';
import { onClickProductDetailGtmEventHandler } from 'utils/Gtm/EventHandlers';

type ProductActionProps = {
    product: ProductActionType;
    gtmListName: GtmListNameType;
    listIndex: number;
    isConfigurator?: boolean;
};

const ProductAction: FC<ProductActionProps> = ({ gtmListName, listIndex, product, isConfigurator }) => {
    const testIdentifier = 'blocks-product-action';

    const router = useRouter();
    const t = useTypedTranslationFunction();
    const { type: domainType, url } = useDomainConfig();

    const productButtonType = (() => {
        if (isConfigurator) {
            return 'configurator' as const;
        }
        if (domainType === 'web') {
            return 'web' as const;
        }
        if (product.isMainVariant) {
            return 'choose-color' as const;
        }
        if (product.isSellingDenied) {
            return 'watch-dog' as const;
        }

        return 'add-to-cart' as const;
    })();

    return (
        <span className="mt-3 lg:mx-5 lg:mb-4 vl:mx-6" data-testid={testIdentifier}>
            {productButtonType === 'web' && (
                <Link
                    href={product.slug}
                    onClick={() => onClickProductDetailGtmEventHandler(product, gtmListName, listIndex, url)}
                    isButton
                    size="smallNarrow"
                    type="product"
                >
                    {t('Show')}
                </Link>
            )}
            {productButtonType === 'add-to-cart' && (
                <AddToCart
                    productUuid={product.uuid}
                    gtmListName={gtmListName}
                    listIndex={listIndex}
                    isBookingOnly={
                        product.availability.status === AvailabilityStatusEnum.outOfStock &&
                        product.availableStoresCount > 0
                    }
                />
            )}
            {productButtonType === 'choose-color' && (
                <Button
                    size="smallNarrow"
                    onClick={() => router.push(product.slug)}
                    name={productButtonType}
                    data-testid={`${testIdentifier}-${productButtonType}`}
                >
                    {t('Choose color')}
                </Button>
            )}
            {productButtonType === 'watch-dog' && (
                <WatchDog productUuid={product.uuid} productName={product.fullName} />
            )}
            {productButtonType === 'configurator' && (
                <Button size="smallNarrow" name="add-to-drawer">
                    {t('Add to configurator')}
                </Button>
            )}
        </span>
    );
};

export default ProductAction;
