import twMerge from 'utils/twMerge';

type PillProps = {
    value: string | number | undefined;
    className?: React.ComponentProps<'div'>['className'];
};

const Pill: FC<PillProps> = ({ value, className = '' }) => {
    if (value === undefined) {
        return null;
    }

    const pillTwClass = twMerge(
        'inline-flex h-5 min-w-[20px] items-center justify-center rounded-[20px] bg-primary px-1 font-bold text-[10px] text-white',
        className,
    );

    return <span className={pillTwClass}>{value}</span>;
};

export default Pill;
