import { showErrorMessage, showInfoMessage } from 'components/Helpers/Toasts';
import { theme } from 'components/Theme/main';
import { mapAvailabilityData, mapStoreAvailability } from 'connectors/availability/Availability';
import { getFirstImage } from 'connectors/image/Image';
import { getUserFriendlyErrors } from 'connectors/lib/friendlyErrorMessageParser';
import { mapPayment } from 'connectors/payments/Payment';
import {
    mapLoyaltyPointsObtainedData,
    mapPriceData,
    mapProductPriceData,
    mapProductPricesData,
} from 'connectors/price/Prices';
import { mapSimpleProductApiData } from 'connectors/products/SimpleProduct';
import { mapTransport } from 'connectors/transports/Transports';
import {
    AddToCartMutationApi,
    CartFragmentApi,
    CartItemFragmentApi,
    CartItemModificationsFragmentApi,
    CartLoyaltyClubModificationsResultApi,
    CartModificationsFragmentApi,
    CartPaymentModificationsFragmentApi,
    CartPromoCodeModificationsFragmentApi,
    CartTransportModificationsFragmentApi,
    ProductColorVariantFragmentApi,
    PromoCodeFragmentApi,
    useCartQueryApi,
} from 'graphql/generated';
import { ApplicationErrors } from 'helpers/errors/applicationErrors';
import { getSelectedPickupPoint } from 'helpers/pickupPoint/pickupPoint';
import { ChangePaymentHandler } from 'hooks/cart/useChangePaymentInCart';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { Translate } from 'next-translate';
import { useEffect, useMemo, useState } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { AddToCartPopupDataType, CartItemType, CartType, CurrentCartType } from 'types/cart';
import { ColorVariantType } from 'types/product';
import { PromoCodeType } from 'types/promoCode';
import { CombinedError, OperationContext } from 'urql';

export const useCurrentCart = (selectedPickupPlaceIdentifier?: string | null, fromCache = true): CurrentCartType => {
    const [isInitiallyLoaded, setInitialLoadedState] = useState(false);
    const { isUserLoggedIn } = useCurrentUserData();
    const { defaultStoreUuid, cartUuid } = usePersistStore((s) => s);
    const { currencyCode } = useDomainConfig();
    const t = useTypedTranslationFunction();

    const [result, refetchCart] = useCartQueryApi({
        variables: {
            cartUuid,
            defaultStoreUuid,
            selectedStoreUuid: selectedPickupPlaceIdentifier ?? defaultStoreUuid,
        },
        pause: cartUuid === null && !isUserLoggedIn,
        requestPolicy: fromCache ? 'cache-first' : 'network-only',
    });

    useEffect(() => {
        if (cartUuid === null && !isUserLoggedIn) {
            setInitialLoadedState(true);

            return;
        }

        if (!result.fetching) {
            setInitialLoadedState(true);
        }
    }, [cartUuid, isUserLoggedIn, result.fetching]);

    return useMemo(() => {
        if (result.data === undefined) {
            return getEmptyCart(isInitiallyLoaded, refetchCart, false);
        }

        if (isInitiallyLoaded !== true) {
            setInitialLoadedState(true);
        }

        if (cartUuid === null && !isUserLoggedIn) {
            return getEmptyCart(isInitiallyLoaded, refetchCart, true);
        }

        if (result.error !== undefined) {
            // EXTEND CART ERRORS HERE
            handleCartError(result.error, t);

            return getEmptyCart(isInitiallyLoaded, refetchCart, true);
        }

        if (result.data.cart === null) {
            // EXTEND EMPTY CART HERE
            return getEmptyCart(isInitiallyLoaded, refetchCart, true);
        }
        // EXTEND CART UPDATE HERE
        const mappedCart = mapCart(result.data.cart, currencyCode);

        const mappedTransport =
            result.data.cart.transport === null ? null : mapTransport(result.data.cart.transport, currencyCode);

        return {
            cart: mappedCart,
            isCartEmpty: mappedCart.items.length === 0,
            transport: mappedTransport,
            pickupPlace: getSelectedPickupPoint(mappedTransport, result.data.cart.selectedPickupPlaceIdentifier),
            payment: result.data.cart.payment === null ? null : mapPayment(result.data.cart.payment, currencyCode),
            paymentGoPayBankSwift: result.data.cart.paymentGoPayBankSwift,
            promoCodes: result.data.cart.promoCodes.map((promoCode) => mapPromoCode(promoCode, currencyCode)),
            loyaltyClubPoints: result.data.cart.loyaltyClubPoints,
            loyaltyClubPointsMaximum: result.data.cart.loyaltyClubPointsMaximum,
            isLoaded: true,
            isInitiallyLoaded: isInitiallyLoaded,
            modifications: result.data.cart.modifications,
            hasVoucherProduct: result.data.cart.hasVoucherProduct,
            storesWhereAllProductsAreAvailableTogetherUuids: mappedCart.storesWhereAllProductsAreAvailableTogetherUuids,
            refetchCart,
        };
    }, [result.data, result.error, isInitiallyLoaded, cartUuid, isUserLoggedIn, currencyCode, refetchCart, t]);
};

const getEmptyCart = (
    isInitiallyLoaded: boolean,
    refetchCart: (opts?: Partial<OperationContext> | undefined) => void,
    isLoaded = true,
): CurrentCartType => ({
    cart: null,
    isCartEmpty: true,
    transport: null,
    pickupPlace: null,
    payment: null,
    paymentGoPayBankSwift: null,
    promoCodes: [],
    loyaltyClubPoints: null,
    loyaltyClubPointsMaximum: null,
    isLoaded,
    isInitiallyLoaded,
    modifications: null,
    storesWhereAllProductsAreAvailableTogetherUuids: [],
    refetchCart,
});

const handleCartError = (error: CombinedError, t: Translate) => {
    const { userError, applicationError } = getUserFriendlyErrors(error, t);

    switch (applicationError?.type) {
        case ApplicationErrors['cart-not-found']:
            break;
        case ApplicationErrors.default:
            showErrorMessage(applicationError.message, 'cart');
            break;
    }

    if (userError?.validation !== undefined) {
        for (const invalidFieldName in userError.validation) {
            showErrorMessage(userError.validation[invalidFieldName].message, 'cart');
        }
    }
};

export const mapAddToCartPopupData = (
    addToCartResult: AddToCartMutationApi['AddToCart'] | null,
    currencyCode: string,
): AddToCartPopupDataType | null => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (addToCartResult === null || addToCartResult.addProductResult.cartItem === null) {
        return null;
    }

    return {
        ...mapSimpleProductApiData(addToCartResult.addProductResult.cartItem.product, currencyCode),
        uuid: addToCartResult.addProductResult.cartItem.product.uuid,
        quantityAdded: addToCartResult.addProductResult.addedQuantity,
        quantityTotal: addToCartResult.addProductResult.cartItem.quantity,
        stockQuantity: addToCartResult.addProductResult.cartItem.product.stockQuantity,
    };
};

export const mapCart = (apiData: CartFragmentApi, currencyCode: string): CartType => {
    const remainingFreeTransport = apiData.remainingAmountWithVatForFreeTransport;

    const totalPrice = mapPriceData(apiData.totalPrice, currencyCode);
    const totalItemsPrice = mapPriceData(apiData.totalItemsPrice, currencyCode);
    const totalItemsPriceWithoutAppliedGiftVoucher = mapPriceData(
        apiData.totalItemsPriceWithoutAppliedGiftVoucher,
        currencyCode,
    );

    return {
        items: apiData.items.map((item) => mapCartItem(item, currencyCode)),
        totalPrice: totalPrice,
        totalItemsPrice: totalItemsPrice,
        totalDiscountPrice: mapPriceData(apiData.totalDiscountPrice, currencyCode),
        areProductsAvailableAtSomeStoreTogether: apiData.areProductsAvailableAtSomeStoreTogether || false,
        remainingAmountWithVatForFreeTransport:
            remainingFreeTransport !== null ? Number.parseFloat(remainingFreeTransport) : null,
        loyaltyClubPoints: apiData.loyaltyClubPoints,
        loyaltyClubDiscount:
            apiData.loyaltyClubDiscount !== null ? Number.parseFloat(apiData.loyaltyClubDiscount) : null,
        loyaltyClubPointsObtained: mapLoyaltyPointsObtainedData(apiData.loyaltyClubPointsObtained),
        totalItemsPriceWithoutAppliedGiftVoucher: totalItemsPriceWithoutAppliedGiftVoucher,
        hasProductExcludedFromDelivery: apiData.hasProductExcludedFromDelivery,
        roundingPrice: apiData.roundingPrice && mapPriceData(apiData.roundingPrice, currencyCode),
        hasVoucherProduct: apiData.hasVoucherProduct,
        storesWhereAllProductsAreAvailableTogetherUuids: apiData.storesWhereAllProductsAreAvailableTogetherUuids ?? [],
    };
};

export const mapCartItem = (apiData: CartItemFragmentApi, currencyCode: string): CartItemType => {
    return {
        ...apiData,
        product: {
            ...apiData.product,
            price: mapProductPriceData(apiData.product.price, currencyCode),
            prices: mapProductPricesData(apiData.product.prices, currencyCode),
            availability: mapAvailabilityData(apiData.product.availability),
            defaultStoreAvailability:
                apiData.product.defaultStoreAvailability !== null
                    ? mapStoreAvailability(apiData.product.defaultStoreAvailability)
                    : null,
            image: getFirstImage(apiData.product.images),
            categoryNames: apiData.product.categories.map((category) => category.name),
            promoCode:
                apiData.product.promoCode !== null ? mapPromoCode(apiData.product.promoCode, currencyCode) : null,
            colorVariants: mapColorVariants(apiData.product.colorVariants),
            currentColorVariant:
                'currentColorVariant' in apiData.product ? mapColorVariant(apiData.product.currentColorVariant) : null,
            selectedStoreAvailability:
                apiData.product.selectedStoreAvailability !== null
                    ? mapStoreAvailability(apiData.product.selectedStoreAvailability)
                    : null,
        },
    };
};

const mapPromoCode = (promoCode: PromoCodeFragmentApi, currencyCode: string): PromoCodeType => ({
    ...promoCode,
    discount: mapPriceData(promoCode.discount, currencyCode),
    freeTransportFrom: promoCode.freeTransportFrom !== null ? Number.parseFloat(promoCode.freeTransportFrom) : null,
});

const mapColorVariant = (variant: ProductColorVariantFragmentApi): ColorVariantType => ({
    ...variant,
    value: variant.value ?? theme.color.gray,
    colorImage: getFirstImage(variant.parameterValueImages),
});

const mapColorVariants = (colorVariants: CartItemFragmentApi['product']['colorVariants']): ColorVariantType[] => {
    const mappedData = [];

    for (const variant of colorVariants) {
        if (variant.value !== null) {
            mappedData.push(mapColorVariant(variant));
        }
    }

    return mappedData;
};

export const handleCartModifications = (
    cartModifications: CartModificationsFragmentApi,
    t: Translate,
    changePaymentInCart: ChangePaymentHandler,
): void => {
    handleCartTransportModifications(cartModifications.transportModifications, t, changePaymentInCart);
    handleCartPaymentModifications(cartModifications.paymentModifications, t);
    handleCartItemModifications(cartModifications.itemModifications, t);
    handleCartPromoCodeModifications(cartModifications.promoCodeModifications, t);
    handleCartLoyaltyClubModifications(cartModifications.loyaltyClubModifications, t);
};

const handleCartLoyaltyClubModifications = (
    loyaltyClubModificationsResultApi: CartLoyaltyClubModificationsResultApi,
    t: Translate,
): void => {
    if (loyaltyClubModificationsResultApi.loyaltyClubNotAvailable) {
        showInfoMessage(t('loyaltyClubNotAvailable'));
    }
    if (loyaltyClubModificationsResultApi.orderMaximumExceeded) {
        showInfoMessage(
            t('The amount of applied loyalty points has been changed because of the total order price change'),
            'cart',
        );
    }
    if (loyaltyClubModificationsResultApi.personalPointsExceeded) {
        showInfoMessage(
            t('The amount of applied loyalty points has been changed to match your current account balance'),
            'cart',
        );
    }
    if (loyaltyClubModificationsResultApi.voucherInCart) {
        showInfoMessage(
            t('Gift vouchers cannot be purchased using loyalty points and have therefore been removed from the basket'),
            'cart',
        );
    }
    if (loyaltyClubModificationsResultApi.negativeAppliedPoints) {
        showInfoMessage(
            t('Cannot apply less than zero loyalty points and they have therefore been removed from the basket'),
            'cart',
        );
    }
};

const handleCartTransportModifications = (
    transportModifications: CartTransportModificationsFragmentApi,
    t: Translate,
    changePaymentInCart: ChangePaymentHandler,
): void => {
    if (transportModifications.transportPriceChanged) {
        showInfoMessage(t('The price of the transport you selected has changed.'), 'cart');
    }
    if (transportModifications.transportUnavailable) {
        changePaymentInCart(null, null);
        showInfoMessage(t('The transport you selected is no longer available.'), 'cart');
        showInfoMessage(t('Your transport and payment selection has been removed.'), 'cart');
    }
    if (transportModifications.transportWeightLimitExceeded) {
        changePaymentInCart(null, null);
        showInfoMessage(t('You have exceeded the weight limit of the selected transport.'), 'cart');
        showInfoMessage(t('Your transport and payment selection has been removed.'), 'cart');
    }
    if (transportModifications.transportVolumeLimitExceeded) {
        changePaymentInCart(null, null);
        showInfoMessage(t('You have exceeded the volume limit of the selected transport.'), 'cart');
        showInfoMessage(t('Your transport and payment selection has been removed.'), 'cart');
    }
};

const handleCartPaymentModifications = (
    paymentModifications: CartPaymentModificationsFragmentApi,
    t: Translate,
): void => {
    if (paymentModifications.paymentPriceChanged) {
        showInfoMessage(t('The price of the payment you selected has changed.'), 'cart');
    }
    if (paymentModifications.paymentUnavailable) {
        showInfoMessage(t('The payment you selected is no longer available.'), 'cart');
    }
};

const handleCartItemModifications = (itemModifications: CartItemModificationsFragmentApi, t: Translate): void => {
    for (const cartItemWithChangedQuantity of itemModifications.cartItemsWithChangedQuantity) {
        showInfoMessage(
            t('The quantity of item {{ itemName }} has changed.', {
                itemName: cartItemWithChangedQuantity.product.fullName,
            }),
            'cart',
        );
    }
    for (const cartItemWithModifiedPrice of itemModifications.cartItemsWithModifiedPrice) {
        showInfoMessage(
            t('The price of item {{ itemName }} has changed.', {
                itemName: cartItemWithModifiedPrice.product.fullName,
            }),
            'cart',
        );
    }
    for (const soldOutCartItem of itemModifications.noLongerAvailableCartItemsDueToQuantity) {
        showInfoMessage(
            t('Item {{ itemName }} has been sold out.', { itemName: soldOutCartItem.product.fullName }),
            'cart',
        );
    }
    for (const nonListableCartItem of itemModifications.noLongerListableCartItems) {
        showInfoMessage(
            t('Item {{ itemName }} can no longer be bought.', { itemName: nonListableCartItem.product.fullName }),
            'cart',
        );
    }
    for (const removedVoucher of itemModifications.invalidVoucherCombinationRemovedItems) {
        showInfoMessage(
            t('Item {{ itemName }} has been removed from cart. Physical and electronic vouchers cannot be combined.', {
                itemName: removedVoucher.product.fullName,
            }),
            'cart',
        );
    }
};

const handleCartPromoCodeModifications = (
    promoCodeModifications: CartPromoCodeModificationsFragmentApi,
    t: Translate,
): void => {
    for (const nonApplicablePromoCode of promoCodeModifications.noLongerApplicablePromoCode) {
        showInfoMessage(
            t('The promo code {{ promoCode }} is no longer applicable so we have removed it from your order.', {
                promoCode: nonApplicablePromoCode,
            }),
            'cart',
        );
    }

    for (const nonApplicablePromoCode of promoCodeModifications.noLongerApplicableGiftCodeForPersonalPickup) {
        showInfoMessage(
            t(
                'Due to the reservation at the store, the gift voucher {{ promoCode }} was removed from the order and you will be able to use it when you pick up the goods at the store.',
                {
                    promoCode: nonApplicablePromoCode,
                },
            ),
        );
    }
};
