import { GetServerSidePropsContext, NextPageContext } from 'next';
import nookies from 'nookies';

const COOKIE_NAME = 'cartUuid';

export const setCartUuidToCookies = (storeUuid: string) => {
    nookies.set(undefined, COOKIE_NAME, storeUuid, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30,
    });
};

export const getCartUuidCookie = (): string | null => {
    const cookies = nookies.get();
    if (COOKIE_NAME in cookies) {
        return cookies[COOKIE_NAME];
    }

    return null;
};

export const destroyCartUuidCookie = () => {
    nookies.destroy(undefined, COOKIE_NAME, {
        path: '/',
    });
};

export const getCartUuidCookieOnSsr = (context: GetServerSidePropsContext | NextPageContext): string | null => {
    if (!context.req) {
        return null;
    }

    const cartUuidCookie = 'cookies' in context.req ? context.req.cookies[COOKIE_NAME] : null;

    if (cartUuidCookie) {
        return cartUuidCookie;
    }

    return null;
};
