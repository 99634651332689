import { StoresMarkersSearchLatLngQueryApi } from 'graphql/generated';
import { MapMarkerType } from 'types/map';
import { PickupPointType } from 'types/pickupPoint';
import { ListedStoreType, SearchedStoreType } from 'types/store';

export function mapSearchedStoreToPickupPoint(searchedStore: SearchedStoreType): PickupPointType {
    return {
        identifier: searchedStore.uuid,
        name: searchedStore.name,
        email: searchedStore.email,
        telephone: searchedStore.telephone,
        description: '',
        openingHours: searchedStore.openingHours ?? null,
        street: searchedStore.street || '',
        postcode: searchedStore.postcode || '',
        city: searchedStore.city || '',
        country: searchedStore.country,
        disabled: false,
        reservationDisabled: false,
        locationLatitude:
            searchedStore.locationLatitude !== null &&
            !isNaN(
                typeof searchedStore.locationLatitude === 'string'
                    ? parseFloat(searchedStore.locationLatitude)
                    : searchedStore.locationLatitude,
            )
                ? searchedStore.locationLatitude.toString()
                : null,
        locationLongitude:
            searchedStore.locationLongitude !== null &&
            !isNaN(
                typeof searchedStore.locationLongitude === 'string'
                    ? parseFloat(searchedStore.locationLongitude)
                    : searchedStore.locationLongitude,
            )
                ? searchedStore.locationLongitude.toString()
                : null,
    };
}

export function mapListedStoreToPickupPoint(searchedStore: ListedStoreType): PickupPointType {
    return {
        identifier: searchedStore.uuid,
        name: searchedStore.name,
        email: searchedStore.email,
        telephone: searchedStore.telephone,
        description: '',
        openingHours: searchedStore.openingHours ?? null,
        street: searchedStore.street,
        postcode: searchedStore.postcode,
        city: searchedStore.city,
        country: searchedStore.country,
        disabled: false,
        reservationDisabled: false,
        locationLatitude:
            searchedStore.locationLatitude !== null && !isNaN(searchedStore.locationLatitude)
                ? searchedStore.locationLatitude.toString()
                : null,
        locationLongitude:
            searchedStore.locationLongitude !== null && !isNaN(searchedStore.locationLongitude)
                ? searchedStore.locationLongitude.toString()
                : null,
    };
}

export function getStoreByUuid<T extends { uuid: string }>(stores: T[], uuid: string | null): T | null {
    const store = stores.find((store) => store.uuid === uuid);

    return store ?? null;
}

export const mapStoresToMapMarker = (stores: StoresMarkersSearchLatLngQueryApi): MapMarkerType[] => {
    return stores.storesBySearch.map(({ store }) => {
        return {
            postOffice: false,
            isPickupPoint: true,
            lat: parseFloat(store.locationLatitude ?? ''),
            lng: parseFloat(store.locationLongitude ?? ''),
            identifier: store.uuid,
        };
    });
};
