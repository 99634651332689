/* eslint-disable no-use-before-define */
import { Popover } from 'components/Basic/Popover/Popover';
import { useForwardedRef } from 'hooks/typescript/UseForwardedRef';
import { MinusIcon, PlusIcon } from 'public/svg/IconsSvg';
import { FormEventHandler, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';

type SpinboxProps = {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
    onChangeValueCallback?: (currentValue: number) => void;
    size?: 'default' | 'small';
    text?: string;
    backgroundColor?: string;
    border?: string;
    hasDynamicWidth?: boolean;
    fetching?: boolean;
    disabled?: boolean;
    overstepMessage?: {
        tooHigh?: string;
        tooLow?: string;
    };
};

const WARNING_LABEL_DISPLAY_DURATION = 2000;

const Spinbox = forwardRef<HTMLInputElement, SpinboxProps>(
    (
        {
            backgroundColor = '#ececec',
            border = 'none',
            defaultValue,
            min,
            max,
            onChangeValueCallback,
            size,
            step,
            text,
            hasDynamicWidth,
            fetching,
            disabled,
            overstepMessage,
        },
        spinboxForwardedRef,
    ) => {
        const testIdentifier = 'forms-spinbox-';
        const [isHoldingDecrease, setIsHoldingDecrease] = useState(false);
        const [isHoldingIncrease, setIsHoldingIncrease] = useState(false);
        const [warningLabel, setWarningLabel] = useState<string | null>();
        const intervalRef = useRef<NodeJS.Timer | null>(null);
        const spinboxRef = useForwardedRef(spinboxForwardedRef);
        const spinboxMirrorRef = useRef<HTMLSpanElement | null>(null);

        const setNewSpinboxValue = useCallback(
            (newValue: number) => {
                if (Number.isNaN(newValue) || newValue < min) {
                    spinboxRef.current.valueAsNumber = min;
                    if (overstepMessage?.tooLow) {
                        setWarningLabel(overstepMessage.tooLow);
                    }
                } else if (newValue > max) {
                    spinboxRef.current.valueAsNumber = max;
                    if (overstepMessage?.tooHigh) {
                        setWarningLabel(overstepMessage.tooHigh);
                    }
                } else {
                    spinboxRef.current.valueAsNumber = newValue;
                }

                if (spinboxMirrorRef.current !== null) {
                    spinboxMirrorRef.current.innerText = spinboxRef.current.valueAsNumber.toString();
                }

                if (onChangeValueCallback !== undefined) {
                    onChangeValueCallback(spinboxRef.current.valueAsNumber);
                }
            },
            [min, max, onChangeValueCallback, spinboxRef, overstepMessage],
        );

        const onChangeValueHandler = useCallback(
            (amountChange: number) => {
                if (spinboxRef.current !== null) {
                    setNewSpinboxValue(spinboxRef.current.valueAsNumber + amountChange);
                }
            },
            [setNewSpinboxValue, spinboxRef],
        );

        useEffect(() => {
            if (isHoldingDecrease) {
                intervalRef.current = setInterval(() => {
                    onChangeValueHandler(-step);
                }, 200);
            } else {
                clearSpinboxInterval(intervalRef.current);
            }

            return () => {
                clearSpinboxInterval(intervalRef.current);
            };
        }, [isHoldingDecrease, onChangeValueHandler, step]);

        useEffect(() => {
            if (isHoldingIncrease) {
                intervalRef.current = setInterval(() => {
                    onChangeValueHandler(step);
                }, 200);
            } else {
                clearSpinboxInterval(intervalRef.current);
            }

            return () => {
                clearSpinboxInterval(intervalRef.current);
            };
        }, [isHoldingIncrease, onChangeValueHandler, step]);

        const clearSpinboxInterval = (interval: NodeJS.Timer | null) => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };

        const onInputHandler: FormEventHandler<HTMLInputElement> = (event) => {
            if (spinboxRef.current !== null) {
                setNewSpinboxValue(event.currentTarget.valueAsNumber);
            }
        };

        useEffect(() => {
            spinboxRef.current.valueAsNumber = defaultValue;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [defaultValue]);

        useEffect(() => {
            const timer = setTimeout(() => {
                if (warningLabel) {
                    setWarningLabel(null);
                }
            }, WARNING_LABEL_DISPLAY_DURATION);

            return () => clearTimeout(timer);
        }, [warningLabel]);

        const spinboxButtonTwClass = twJoin(
            'flex justify-center items-center shrink-0 w-7 p-0 web-transition text-primary cursor-pointer bg-none border-0 rounded-md outline-0 vl:w-9 hover:bg-grayVeryLight active:scale-95',
            size === 'small' && 'translate-y-0 text-tiny',
            disabled && 'cursor-default pointer-events-none',
        );

        return (
            <Popover label={warningLabel} variant="default">
                <div
                    className={twJoin(
                        'inline-flex items-stretch overflow-hidden rounded-md p-1',
                        size === 'small' ? 'min-h-[30px]' : 'min-h-[50px]',
                        disabled && 'opacity-50',
                    )}
                    style={{ backgroundColor: backgroundColor, border: border }}
                >
                    <button
                        type="button"
                        className={spinboxButtonTwClass}
                        onClick={() => onChangeValueHandler(-step)}
                        onMouseDown={() => setIsHoldingDecrease(true)}
                        onMouseUp={() => setIsHoldingDecrease(false)}
                        onMouseLeave={() => setIsHoldingDecrease(false)}
                        data-testid={testIdentifier + 'decrease'}
                        disabled={disabled}
                    >
                        <MinusIcon className="h-[18px] w-[18px]" />
                    </button>
                    <span className="relative block overflow-hidden">
                        {hasDynamicWidth && (
                            <span
                                className="pointer-events-none invisible whitespace-pre text-h5"
                                ref={spinboxMirrorRef}
                            >
                                {defaultValue}
                            </span>
                        )}
                        <input
                            className={twJoin(
                                'm-0 h-full border-0 p-0 text-h5 text-primary outline-none',
                                !hasDynamicWidth && 'w-full min-w-[40px] text-center',
                                hasDynamicWidth && 'absolute left-0 right-0 w-auto min-w-[1px] text-left',
                                fetching && 'hidden',
                            )}
                            style={{ backgroundColor: backgroundColor }}
                            ref={spinboxRef}
                            defaultValue={defaultValue}
                            onInput={onInputHandler}
                            type="number"
                            min={min}
                            max={max}
                            data-testid={testIdentifier + 'input'}
                            disabled={disabled}
                        />
                        {fetching && (
                            <div className="flex h-10 w-10 items-center justify-center">
                                <Skeleton
                                    className="h-7 w-4 !rounded-[5px]"
                                    highlightColor="#dcdcdc"
                                    baseColor="#d3d3d3"
                                />
                            </div>
                        )}
                    </span>
                    {text !== undefined && <span className="my-auto mx-2 pt-1 text-grayVeryDark">{text}</span>}
                    <button
                        type="button"
                        className={spinboxButtonTwClass}
                        onClick={() => onChangeValueHandler(step)}
                        onMouseDown={() => setIsHoldingIncrease(true)}
                        onMouseUp={() => setIsHoldingIncrease(false)}
                        onMouseLeave={() => setIsHoldingIncrease(false)}
                        data-testid={testIdentifier + 'increase'}
                        disabled={disabled}
                    >
                        <PlusIcon className="h-[18px] w-[18px]" />
                    </button>
                </div>
            </Popover>
        );
    },
);

Spinbox.displayName = 'Spinbox';

/* @component */
export default Spinbox;
