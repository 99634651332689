import ErrorLayout from 'components/Layout/ErrorLayout';
import Webline from 'components/Layout/Webline/Webline';
import {
    ErrorPageButtonLinkStyled,
    ErrorPageStyled,
    ErrorPageTextHeadingStyled,
    ErrorPageTextMainStyled,
    ErrorPageTextStyled,
} from 'components/Pages/ErrorPage/ErrorPage.style';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

export const Error500WithBoundary: FC<FallbackProps> = ({ resetErrorBoundary }) => {
    const router = useRouter();

    useEffect(() => {
        const handleResetFromErrorState = () => resetErrorBoundary();

        router.events.on('routeChangeComplete', handleResetFromErrorState);

        return () => {
            router.events.off('routeChangeComplete', handleResetFromErrorState);
        };
    }, [resetErrorBoundary, router.events]);

    return <Error500 />;
};

export const Error500: FC = () => {
    const t = useTypedTranslationFunction();

    return (
        <ErrorLayout>
            <Webline>
                <ErrorPageStyled>
                    <ErrorPageTextStyled>
                        <ErrorPageTextHeadingStyled>{t('Something went wrong.')}</ErrorPageTextHeadingStyled>
                        <ErrorPageTextMainStyled>{t('Please try again later or contact us.')}</ErrorPageTextMainStyled>
                        <ErrorPageButtonLinkStyled href="/" isButton>
                            {t('Back to shop')}
                        </ErrorPageButtonLinkStyled>
                    </ErrorPageTextStyled>
                </ErrorPageStyled>
            </Webline>
        </ErrorLayout>
    );
};
