import { Cache, cacheExchange, Data } from '@urql/exchange-graphcache';
import { IntrospectionQuery } from 'graphql';
import {
    AddToCartResultApi,
    CartApi,
    CartQueryApi,
    CartQueryDocumentApi,
    CartQueryVariablesApi,
    ChangePaymentInCartInputApi,
    ChangePaymentInCartMutationApi,
    ChangeTransportInCartInputApi,
    ChangeTransportInCartMutationApi,
    PromoCodeApi,
    TransportsQueryApi,
    TransportsQueryDocumentApi,
    TransportWithAvailablePaymentsAndStoresFragmentApi,
} from 'graphql/generated';
import nookies from 'nookies';
import schema from 'schema.graphql.json';
import { Nullable } from 'typeHelpers/UtilityTypes';

const keyNull = () => null;
const keyUuid = (data: Data) => data.uuid as string | null;
const keyName = (data: Data) => data.name as string | null;
const keyCode = (data: Data) => data.code as string | null;
const keyProductUuid = (data: Data) => data.productUuid as string | null;
const keyId = (data: Data) => data.id as string | null;

const cache = cacheExchange({
    schema: schema as unknown as IntrospectionQuery,
    keys: {
        Admin: keyNull,
        Advert: keyUuid,
        AdvertCode: keyUuid,
        AdvertImage: keyUuid,
        AdvertPosition: (data) => data.positionName as string | null,
        Article: keyUuid,
        Availability: keyName,
        Banner: keyName,
        BannerSuite: keyNull,
        BlogArticle: keyUuid,
        BlogCategory: keyUuid,
        Brand: keyUuid,
        BrandFilterOption: keyNull,
        Cart: keyUuid,
        CartItem: keyUuid,
        CartItemModificationsResult: keyNull,
        CartLoyaltyClubModificationsResult: keyNull,
        CartModificationsResult: keyNull,
        CartPaymentModificationsResult: keyNull,
        CartTransportModificationsResult: keyNull,
        Category: keyNull,
        ColorVariant: keyNull,
        ColorVariantSimple: keyNull,
        CompanyCustomerUser: keyUuid,
        Country: keyCode,
        CustomerUser: keyUuid,
        DeliveryAddress: keyUuid,
        File: keyNull,
        Flag: keyUuid,
        FlagFilterOption: keyNull,
        FooterArticle: keyUuid,
        FooterSetting: keyNull,
        FooterSettingItem: keyId,
        GdprConsent: keyNull,
        GoPayBankSwift: (data) => data.swift as string | null,
        GoPayPaymentMethod: (data) => data.identifier as string | null,
        Link: keyNull,
        LoyaltyClubPoints: keyNull,
        MainVariant: keyUuid,
        NewsletterSubscriber: keyNull,
        NotificationBar: keyNull,
        OpeningDay: keyNull,
        OpeningHours: keyNull,
        OpeningInterval: keyNull,
        Order: keyUuid,
        OrderItem: keyNull,
        Parameter: keyNull,
        ParameterCheckboxFilterOption: keyNull,
        ParameterSliderFilterOption: keyNull,
        ParameterColorFilterOption: keyNull,
        ParameterGroup: keyNull,
        ParameterValue: keyUuid,
        ParameterValueColorFilterOption: keyNull,
        ParameterValueFilterOption: keyNull,
        Payment: keyUuid,
        PersonalData: keyNull,
        PersonalDataPage: keyNull,
        PillCounts: keyNull,
        Price: keyNull,
        PricingSetting: keyNull,
        Product: keyUuid,
        ProductFilterOptions: keyNull,
        ProductPrice: keyNull,
        ProductPrices: keyNull,
        ProductStoreAvailability: keyNull,
        PromoCode: (data: Data & PromoCodeApi) => `${data.code}-${data.discount.priceWithVat}`,
        RegularCustomerUser: keyUuid,
        RegularProduct: keyUuid,
        SeoSetting: keyNull,
        Settings: keyNull,
        SliderItem: keyUuid,
        Store: keyUuid,
        StoreAvailability: keyNull,
        Stores: keyNull,
        StoresAvailabilities: keyProductUuid,
        Transport: keyUuid,
        TransportType: keyCode,
        Unit: keyName,
        Variant: keyUuid,
        PostOffice: keyUuid,
        PostOfficeOpeningInterval: keyNull,
        PostOfficeOpeningDay: keyNull,
        PostOfficeOpeningHours: keyNull,
    },
    updates: {
        Mutation: {
            Login(_result, _args, cache) {
                invalidateFields(cache, ['cart']);
            },
            Logout(_result, _args, cache) {
                invalidateFields(cache, ['cart']);
            },
            ChangePersonalData(_result, _args, cache) {
                invalidateFields(cache, ['currentCustomerUser']);
            },
            DeleteDeliveryAddress(_result, _args, cache) {
                invalidateFields(cache, ['currentCustomerUser']);
            },
            UpdateDeliveryAddress(_result, _args, cache) {
                invalidateFields(cache, ['currentCustomerUser']);
            },
            AddToCart(result, _args, cache) {
                const newCart =
                    typeof result.AddToCart !== 'undefined' ? (result.AddToCart as AddToCartResultApi) : undefined;
                manuallyUpdateCartFragment(cache, newCart?.cart);
            },
            ChangeTransportInCart(result, _args, cache) {
                const newCart =
                    typeof result.ChangeTransportInCart !== 'undefined'
                        ? (result.ChangeTransportInCart as CartApi)
                        : undefined;
                manuallyUpdateCartFragment(cache, newCart);
            },
            ChangePaymentInCart(result, _args, cache) {
                const newCart =
                    typeof result.ChangePaymentInCart !== 'undefined'
                        ? (result.ChangePaymentInCart as CartApi)
                        : undefined;
                manuallyUpdateCartFragment(cache, newCart);
            },
            RemoveFromCart(result, _args, cache) {
                const newCart =
                    typeof result.RemoveFromCart !== 'undefined' ? (result.RemoveFromCart as CartApi) : undefined;
                manuallyUpdateCartFragment(cache, newCart);
            },
            ApplyPromoCodeToCart(result, _args, cache) {
                const newCart =
                    typeof result.ApplyPromoCodeToCart !== 'undefined'
                        ? (result.ApplyPromoCodeToCart as CartApi)
                        : undefined;
                manuallyUpdateCartFragment(cache, newCart);
            },
            RemovePromoCodeFromCart(result, _args, cache) {
                const newCart =
                    typeof result.RemovePromoCodeFromCart !== 'undefined'
                        ? (result.RemovePromoCodeFromCart as CartApi)
                        : undefined;
                manuallyUpdateCartFragment(cache, newCart);
            },
            CreateOrder(_result, _args, cache) {
                invalidateFields(cache, ['currentCustomerUser', 'loyaltyClubPoints']);
            },
            WatchDog(_result, _args, cache) {
                invalidateFields(cache, ['watchDogProducts', 'currentCustomerUser']);
            },
            ApplyLoyaltyClubPoints(result, _args, cache) {
                const newCart =
                    typeof result.ApplyLoyaltyClubPoints !== 'undefined'
                        ? (result.ApplyLoyaltyClubPoints as CartApi)
                        : undefined;
                manuallyUpdateCartFragment(cache, newCart);
            },
        },
    },
    optimistic: {
        ChangeTransportInCart: ({ input }: { input: ChangeTransportInCartInputApi }, cache) => {
            const cartQueryResult: CartQueryApi | null = cache.readQuery<CartQueryApi>({
                query: CartQueryDocumentApi,
                variables: {
                    cartUuid: input.cartUuid ?? null,
                },
            });

            const transportsQueryResult = cache.readQuery<TransportsQueryApi>({
                query: TransportsQueryDocumentApi,
                variables: {
                    cartUuid: input.cartUuid ?? null,
                },
            });

            if (cartQueryResult === null) {
                return null;
            }

            return getOptimisticChangeTransportInCartResult(cartQueryResult, transportsQueryResult, input);
        },
        ChangePaymentInCart: ({ input }: { input: ChangePaymentInCartInputApi }, cache) => {
            const cartQueryResult: CartQueryApi | null = cache.readQuery<CartQueryApi>({
                query: CartQueryDocumentApi,
                variables: {
                    cartUuid: input.cartUuid ?? null,
                },
            });

            if (cartQueryResult === null) {
                return null;
            }

            return getOptimisticChangePaymentInCartResult(cartQueryResult, input);
        },
    },
});

const invalidateFields = (cache: Cache, fields: string[]): void => {
    const key = 'Query';
    for (const field of cache.inspectFields('Query')) {
        if (fields.includes(field.fieldName)) {
            cache.invalidate(key, field.fieldKey);
        }
    }
};

const manuallyUpdateCartFragment = (cache: Cache, newCart: CartApi | undefined) => {
    if (newCart !== undefined) {
        cache.updateQuery<CartQueryApi, CartQueryVariablesApi>(
            {
                query: CartQueryDocumentApi,
                variables: {
                    cartUuid: newCart.uuid,
                    defaultStoreUuid: (nookies.get().defaultStoreUuid as string | undefined) ?? null,
                    selectedStoreUuid: (nookies.get().selectedStoreUuid as string | undefined) ?? null,
                },
            },
            (data) => {
                if (typeof newCart !== 'undefined') {
                    // eslint-disable-next-line no-param-reassign
                    data = {
                        __typename: 'Query',
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        cart: newCart,
                    };
                }

                return data;
            },
        );
    }
};

const getOptimisticChangeTransportInCartResult = (
    cartQueryResult: CartQueryApi,
    transportsQueryResult: TransportsQueryApi | null,
    input: ChangeTransportInCartInputApi,
): Nullable<ChangeTransportInCartMutationApi['ChangeTransportInCart']> => {
    return {
        __typename: 'Cart',
        items: cartQueryResult.cart?.items ?? null,
        modifications: cartQueryResult.cart?.modifications ?? null,
        payment: cartQueryResult.cart?.payment ?? null,
        paymentGoPayBankSwift: cartQueryResult.cart?.paymentGoPayBankSwift ?? null,
        promoCodes: cartQueryResult.cart?.promoCodes ?? null,
        remainingAmountWithVatForFreeTransport: cartQueryResult.cart?.remainingAmountWithVatForFreeTransport ?? null,
        selectedPickupPlaceIdentifier: input.pickupPlaceIdentifier ?? null,
        totalDiscountPrice: cartQueryResult.cart?.totalDiscountPrice ?? null,
        totalItemsPrice: cartQueryResult.cart?.totalItemsPrice ?? null,
        totalPrice: cartQueryResult.cart?.totalPrice ?? null,
        totalItemsPriceWithoutAppliedGiftVoucher:
            cartQueryResult.cart?.totalItemsPriceWithoutAppliedGiftVoucher ?? null,
        uuid: cartQueryResult.cart?.uuid ?? null,
        transport:
            transportsQueryResult?.transports.find((transport) => transport.uuid === input.transportUuid) ?? null,
        areProductsAvailableAtSomeStoreTogether: cartQueryResult.cart?.areProductsAvailableAtSomeStoreTogether ?? null,
        loyaltyClubDiscount: cartQueryResult.cart?.loyaltyClubDiscount ?? null,
        loyaltyClubPointsMaximum: cartQueryResult.cart?.loyaltyClubPointsMaximum ?? null,
        loyaltyClubPoints: cartQueryResult.cart?.loyaltyClubPoints ?? null,
        hasProductExcludedFromDelivery: cartQueryResult.cart?.hasProductExcludedFromDelivery ?? false,
        loyaltyClubPointsObtained: cartQueryResult.cart?.loyaltyClubPointsObtained ?? {
            loyaltyClubPoints: null,
            loyaltyClubPointsPrice: null,
        },
        roundingPrice: cartQueryResult.cart?.roundingPrice ?? null,
        hasVoucherProduct: cartQueryResult.cart?.hasVoucherProduct ?? false,
        defaultStoreUuid: cartQueryResult.cart?.defaultStoreUuid ?? null,
        storesWhereAllProductsAreAvailableTogetherUuids:
            cartQueryResult.cart?.storesWhereAllProductsAreAvailableTogetherUuids ?? [],
    };
};

const getOptimisticChangePaymentInCartResult = (
    cartQueryResult: CartQueryApi,
    input: ChangePaymentInCartInputApi,
): Nullable<ChangePaymentInCartMutationApi['ChangePaymentInCart']> => {
    const optimisticPayment = getPaymentFromTransport(cartQueryResult.cart?.transport, input.paymentUuid);

    return {
        __typename: 'Cart',
        items: cartQueryResult.cart?.items ?? null,
        modifications: cartQueryResult.cart?.modifications ?? null,
        payment: optimisticPayment,
        paymentGoPayBankSwift: optimisticPayment === null ? null : cartQueryResult.cart?.paymentGoPayBankSwift ?? null,
        promoCodes: cartQueryResult.cart?.promoCodes ?? null,
        remainingAmountWithVatForFreeTransport: cartQueryResult.cart?.remainingAmountWithVatForFreeTransport ?? null,
        selectedPickupPlaceIdentifier: cartQueryResult.cart?.selectedPickupPlaceIdentifier ?? null,
        totalDiscountPrice: cartQueryResult.cart?.totalDiscountPrice ?? null,
        totalItemsPrice: cartQueryResult.cart?.totalItemsPrice ?? null,
        totalPrice: cartQueryResult.cart?.totalPrice ?? null,
        totalItemsPriceWithoutAppliedGiftVoucher:
            cartQueryResult.cart?.totalItemsPriceWithoutAppliedGiftVoucher ?? null,
        uuid: cartQueryResult.cart?.uuid ?? null,
        transport: cartQueryResult.cart?.transport ?? null,
        areProductsAvailableAtSomeStoreTogether: cartQueryResult.cart?.areProductsAvailableAtSomeStoreTogether ?? null,
        loyaltyClubDiscount: cartQueryResult.cart?.loyaltyClubDiscount ?? null,
        loyaltyClubPointsMaximum: cartQueryResult.cart?.loyaltyClubPointsMaximum ?? null,
        loyaltyClubPoints: cartQueryResult.cart?.loyaltyClubPoints ?? null,
        hasProductExcludedFromDelivery: cartQueryResult.cart?.hasProductExcludedFromDelivery ?? false,
        loyaltyClubPointsObtained: cartQueryResult.cart?.loyaltyClubPointsObtained ?? {
            loyaltyClubPoints: null,
            loyaltyClubPointsPrice: null,
        },
        roundingPrice: cartQueryResult.cart?.roundingPrice ?? null,
        hasVoucherProduct: cartQueryResult.cart?.hasVoucherProduct ?? false,
        defaultStoreUuid: cartQueryResult.cart?.defaultStoreUuid ?? null,
        storesWhereAllProductsAreAvailableTogetherUuids:
            cartQueryResult.cart?.storesWhereAllProductsAreAvailableTogetherUuids ?? [],
    };
};

const getPaymentFromTransport = (
    transport: TransportWithAvailablePaymentsAndStoresFragmentApi | null | undefined,
    paymentUuid: string | null,
) => {
    if (!transport || paymentUuid === null) {
        return null;
    }

    return transport.payments.find((payment) => payment.uuid === paymentUuid) ?? null;
};

export default cache;
