import { breadcrumbsLinkClassName, dividerClassName } from './Breadcrumbs';
import { createEmptyArray } from 'helpers/arrayUtils';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { ArrowIcon, HomeIcon } from 'public/svg/IconsSvg';
import { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';
import twMerge from 'utils/twMerge';

type BreadcrumbsSkeletonProps = {
    count: number;
};

export const BreadcrumbsSkeleton: FC<BreadcrumbsSkeletonProps> = ({ count }) => {
    const t = useTypedTranslationFunction();

    return (
        <div className="-mt-2 mb-6 flex items-center text-small notLg:mb-5 notLg:text-primary">
            <ArrowIcon className="mr-1 h-3 w-3 rotate-90 lg:hidden" />
            <a className={twMerge(breadcrumbsLinkClassName, '!hidden lg:!block')}>
                <HomeIcon className="mr-2 h-3 w-3 notLg:hidden" />
                {t('Home page')}
            </a>
            <span className={dividerClassName}>/</span>
            {createEmptyArray(count).map((_, index) => (
                <Fragment key={index}>
                    <Skeleton className="my-2 mr-4 w-40" containerClassName={twJoin(index >= 1 && 'hidden lg:block')} />
                    {index !== count - 1 && <span className={dividerClassName}>/</span>}
                </Fragment>
            ))}
        </div>
    );
};
