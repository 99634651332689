import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import Head from 'next/head';
import { useMemo } from 'react';
import { BreadcrumbItemType } from 'types/breadcrumb';

type BreadcrumbsMetadataProps = {
    breadcrumbs: BreadcrumbItemType[];
};

export const BreadcrumbsMetadata: FC<BreadcrumbsMetadataProps> = ({ breadcrumbs }) => {
    const { url } = useDomainConfig();
    const t = useTypedTranslationFunction();

    const breadcrumbItems: BreadcrumbItemType[] = useMemo(
        () => [{ name: t('Home page'), slug: '/' }, ...breadcrumbs],
        [breadcrumbs, t],
    );

    return (
        <Head>
            <script
                type="application/ld+json"
                id="breadcrumbs-metadata"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify([
                        {
                            '@context': 'https://schema.org',
                            '@type': 'BreadcrumbList',
                            itemListElement: breadcrumbItems.map((breadcrumb, index) => {
                                const breadcrumbSlugWithoutLeadingSlash =
                                    breadcrumb.slug.charAt(0) === '/' ? breadcrumb.slug.slice(1) : breadcrumb.slug;
                                const breadcrumbAbsoluteUrl = url + breadcrumbSlugWithoutLeadingSlash;

                                return {
                                    '@type': 'ListItem',
                                    position: index + 1,
                                    name: breadcrumb.name,
                                    item: index === breadcrumbItems.length - 1 ? undefined : breadcrumbAbsoluteUrl,
                                };
                            }),
                        },
                    ]),
                }}
                key="breadcrumbs-metadata"
            />
        </Head>
    );
};
