import { CSSProperties, LabelHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<LabelHTMLAttributes<HTMLLabelElement>, never, 'htmlFor'>;

type LabelWrapperProps = NativeProps & {
    label?: string | ReactNode | ReactNode[];
    /**
     * Display count of items. This is an optional prop primary from the parameters filter.
     */
    count?: number;
    inputType: 'textarea' | 'text-input' | 'selectbox' | 'checkbox' | 'radio';
    required?: boolean;
    /**
     * Type of placeholder for check if the placeholder is static or adaptive.
     */
    placeholderType?: 'static';
    checked?: boolean;
    /**
     * Set floating label if the selectbox has a value or if the menu is open.
     */
    selectBoxLabelIsFloated?: boolean;
    pointerEvents?: CSSProperties['pointerEvents'];
    className?: React.ComponentProps<'div'>['className'];
    isEmpty?: boolean;
    countElement?: ReactElement;
};

const LabelWrapper: FC<LabelWrapperProps> = ({
    checked,
    children,
    count,
    htmlFor,
    inputType,
    label,
    placeholderType,
    pointerEvents = 'auto',
    required,
    selectBoxLabelIsFloated,
    isEmpty,
    countElement,
    className,
}) => {
    return (
        <div style={{ pointerEvents }} className="relative w-full">
            {children}
            {placeholderType !== 'static' && (
                <label
                    htmlFor={htmlFor}
                    className={twMerge(
                        (inputType === 'text-input' || inputType === 'selectbox' || inputType === 'textarea') && [
                            'absolute left-3 z-two block text-small leading-3 text-grayDark transition-all duration-200 ease-defaultTransition',
                            'peer-readonly:opacity-50 peer-readonly:pointer-events-none peer-readonly:cursor-no-drop',
                            'peer-disabled:pointer-events-none peer-disabled:cursor-no-drop peer-disabled:opacity-50',
                        ],
                        (inputType === 'text-input' || inputType === 'selectbox' || inputType === 'textarea') &&
                            (selectBoxLabelIsFloated === undefined || selectBoxLabelIsFloated === true) && [
                                'peer-focus:transform-none',
                                !isEmpty && 'transform-none',
                            ],
                        inputType === 'radio' && [
                            'cursor-pointer hover:before:bg-pos-x-top peer-checked:[&>div]:font-bold peer-checked:[&>div]:text-green',
                            'before:absolute before:top-1/2 before:left-0 before:inline-block before:h-[18px] before:w-[18px] before:-translate-y-1/2 before:bg-[url("/images/custom_radio.png")] before:bg-[length:calc(3*18px)_calc(2*18px)] before:bg-left-top before:bg-no-repeat before:content-[""]',
                            'peer-checked:before:bg-pos-y-bottom peer-focus:before:bg-pos-x-center peer-active:before:bg-pos-x-center peer-disabled:before:bg-pos-x-right',
                            '[&>div]:relative [&>div]:flex [&>div]:min-h-[18px] [&>div]:items-center [&>div]:pl-7 [&>div]:text-body [&>div]:text-primary',
                            'peer-disabled:cursor-no-drop peer-disabled:[&>div]:font-normal peer-disabled:[&>div]:text-grayDarker peer-disabled:[&>div>span]:cursor-no-drop peer-disabled:[&>div>span]:text-grayDarker peer-disabled:[&>div>img]:cursor-no-drop peer-disabled:[&>div>img]:grayscale',
                        ],
                        inputType === 'textarea' &&
                            'top-5 translate-y-0 peer-read-only:pointer-events-none peer-read-only:cursor-no-drop peer-read-only:opacity-50 peer-disabled:pointer-events-none peer-disabled:cursor-no-drop peer-disabled:opacity-50',
                        inputType === 'text-input' && 'top-1/2 -translate-y-1/2',
                        (inputType === 'text-input' || inputType === 'textarea') && [
                            'peer-focus:top-2 peer-focus:text-footer',
                            !isEmpty && 'top-2 text-footer',
                        ],
                        inputType === 'selectbox' && 'top-1/2 -translate-y-1/2',
                        inputType === 'selectbox' && selectBoxLabelIsFloated && 'top-2 text-footer',
                        inputType === 'checkbox' && [
                            'relative inline-block min-h-[18px] cursor-pointer select-none pl-7 text-body text-primary transition-colors duration-300 hover:text-green hover:before:bg-pos-x-center',
                            'before:absolute before:top-0 before:left-0 before:inline-block before:h-[18px] before:w-[18px] before:bg-[url("/images/custom_checkbox.png")] before:bg-[length:54px_36px] before:bg-left-top before:bg-no-repeat before:content-[""]',
                            'peer-checked:font-bold peer-checked:text-green peer-checked:before:bg-pos-y-bottom peer-focus:before:bg-pos-x-center peer-active:before:bg-pos-x-center',
                            'peer-disabled:cursor-no-drop peer-disabled:font-normal peer-disabled:text-grayDarker peer-disabled:before:cursor-no-drop peer-disabled:before:bg-pos-x-right',
                            '[&>a]:text-primary [&>a]:hover:text-orange [&>a]:focus:text-orange [&>a]:active:text-orange',
                        ],
                        className,
                    )}
                >
                    {label !== undefined && label}
                    {count !== undefined && checked === false && count > 0 && (
                        <>{countElement ? countElement : `\u00A0(${count})`}</>
                    )}
                    {required && <span className="ml-1 text-grayDark">*</span>}
                </label>
            )}
        </div>
    );
};

/* @component */
export default LabelWrapper;
