import { PostOfficesBySearchApi, PostOfficesMarkersBySearchApi } from 'graphql/generated';
import nookies from 'nookies';
import { CountryType } from 'types/country';
import { MapMarkerType } from 'types/map';
import { PickupPointType } from 'types/pickupPoint';
import { PostOfficeType } from 'types/postOffice';
import { SearchedStoresType } from 'types/store';
import { TransportTypeCodeEnum } from 'types/transport';

export const mapPostOfficeToPickupPoint = (
    postOffice: PostOfficeType,
    transportType: TransportTypeCodeEnum,
): PickupPointType => {
    return {
        identifier: postOffice.postcode,
        name: postOffice.name,
        email: '',
        telephone: '',
        description: '',
        openingHours: postOffice.openingHours,
        street: postOffice.address,
        postcode: postOffice.postcode,
        city: postOffice.city,
        country: getCountryByTransportType(transportType),
        disabled: false,
        reservationDisabled: false,
        locationLatitude: postOffice.locationLatitude !== null ? postOffice.locationLatitude.toString() : null,
        locationLongitude: postOffice.locationLongitude !== null ? postOffice.locationLongitude.toString() : null,
    };
};

export const mapPostOfficeToStore = (
    postOffice: NonNullable<PostOfficesBySearchApi['postOfficesBySearch']>[number],
    transportType: TransportTypeCodeEnum,
): SearchedStoresType => {
    return {
        availabilities: null,
        availabilityInformation: null,
        isAvailableForProducts: null,
        isAvailableForProductsReservation: null,
        store: {
            locationLatitude: postOffice.locationLatitude,
            locationLongitude: postOffice.locationLongitude,
            city: postOffice.city,
            country: getCountryByTransportType(transportType),
            email: null,
            isPickupPoint: true,
            coordinateDistance: null,
            name: postOffice.name,
            postcode: postOffice.postcode,
            slug: null,
            street: postOffice.address,
            telephone: null,
            uuid: postOffice.identifier,
            openingHours: postOffice.openingHours,
        },
    };
};

export const mapPostOfficesToMapMarker = (
    postOffices: PostOfficesMarkersBySearchApi['postOfficesBySearch'] | undefined,
): MapMarkerType[] => {
    if (!postOffices) {
        return [];
    }

    return postOffices.map((postOffice) => {
        return {
            postOffice: true,
            isPickupPoint: true,
            lat: parseFloat(postOffice.locationLatitude ?? ''),
            lng: parseFloat(postOffice.locationLongitude ?? ''),
            identifier: postOffice.postcode,
        };
    });
};

const getCountryByTransportType = (transportType: TransportTypeCodeEnum): CountryType => {
    if (transportType === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_CZ) {
        return {
            name: 'Česká republika',
            code: 'CZ',
        };
    }
    if (transportType === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_HU) {
        return {
            name: 'HU',
            code: 'HU',
        };
    }
    if (transportType === TransportTypeCodeEnum.POST_OFFICE_PARCEL_DELIVERY_SK) {
        return {
            name: 'SK',
            code: 'SK',
        };
    }

    return {
        name: '',
        code: '',
    };
};

export const getPostOfficeCzCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('postOffice' in cookies) {
        return JSON.parse(cookies.postOffice);
    }

    return null;
};

const ONE_MONTH = 60 * 60 * 24 * 30;

export const setPostOfficeCzCookie = (mappedPostOfficePoint: PickupPointType): void => {
    nookies.set(undefined, 'postOffice', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeCzCookie = (): void => {
    nookies.destroy(undefined, 'postOffice', {
        path: '/',
    });
};

export const getPostOfficeHuCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('postOfficeHu' in cookies) {
        return JSON.parse(cookies.postOfficeHu);
    }

    return null;
};

export const setPostOfficeHuCookie = (mappedPostOfficePoint: PickupPointType): void => {
    nookies.set(undefined, 'postOfficeHu', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeHuCookie = (): void => {
    nookies.destroy(undefined, 'postOfficeHu', {
        path: '/',
    });
};

export const getPostOfficeSkCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('postOfficeSk' in cookies) {
        return JSON.parse(cookies.postOfficeSk);
    }

    return null;
};

export const setPostOfficeSkCookie = (mappedPostOfficePoint: PickupPointType): void => {
    nookies.set(undefined, 'postOfficeSk', JSON.stringify(mappedPostOfficePoint), {
        path: '/',
        maxAge: ONE_MONTH,
    });
};

export const removePostOfficeSkCookie = (): void => {
    nookies.destroy(undefined, 'postOfficeSk', {
        path: '/',
    });
};
