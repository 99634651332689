type ResultTitleProps = {
    title: string;
};

const ResultTitle: FC<ResultTitleProps> = ({ title }) => {
    return (
        <p className="mb-4 text-small leading-snug tracking-wider text-grayDark not-first:mt-5 not-first:border-t not-first:border-grayMidDarker not-first:pt-5 xl:not-first:mt-7 xl:not-first:border-t-0 xl:not-first:pt-0">
            {title}
        </p>
    );
};

export default ResultTitle;
