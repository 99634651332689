import { WatchDogPopupProps } from 'components/Blocks/Product/WatchDog/Popup/WatchDogPopup';
import Button from 'components/Forms/Button/Button';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const WatchDogPopup = dynamic<WatchDogPopupProps>(() =>
    import('components/Blocks/Product/WatchDog/Popup/WatchDogPopup').then((component) => component.WatchDogPopup),
);

type WatchDogProps = {
    productUuid: string;
    productName: string;
};

const WatchDog: FC<WatchDogProps> = (props) => {
    const t = useTypedTranslationFunction();

    const [isPopupOpened, setIsPopupOpened] = useState(false);

    const onPopupOpenHandler = async () => {
        setIsPopupOpened(true);
    };

    return (
        <>
            <Button
                type="button"
                size="smallNarrow"
                name="watch-product"
                onClick={onPopupOpenHandler}
                className="w-fit"
            >
                {t('Watch product')}
            </Button>
            {isPopupOpened && (
                <WatchDogPopup
                    onCloseCallback={() => setIsPopupOpened(false)}
                    productUuid={props.productUuid}
                    productName={props.productName}
                />
            )}
        </>
    );
};

export default WatchDog;
