export enum AvailabilityStatusEnum {
    inStock = 'in-stock',
    outOfStock = 'out-of-stock',
    centralStock = 'central-stock',
    partiallyInStock = 'partially-in-stock',
}

export type AvailabilityType = {
    name: string;
    status: AvailabilityStatusEnum;
};

export type StoreAvailabilityType = {
    storeUuid: string;
    availabilityInformation: string;
    availabilityStatus: StoreListAvailabilityStatusEnum;
    storeName: string;
    storeReservationDisabled?: boolean;
    quantity: number;
};

export enum StoreListAvailabilityStatusEnum {
    inStock = 'in-stock',
    outOfStock = 'out-of-stock',
    someOutOfStock = 'some-out-of-stock',
    inStockReservationDisabled = 'in-stock-reservation-disabled',
    outOfStockReservationDisabled = 'out-of-stock-reservation-disabled',
    someOutOfStockReservationDisabled = 'some-out-of-stock-reservation-disabled',
    centralStock = 'central-stock',
    partiallyInStock = 'partially-in-stock',
}
