import { LoginUnderHeaderStyled } from './Login.style';
import { LoginContent } from './LoginContent';
import Portal from 'components/Basic/Portal/Portal';
import { useMenuIconicContentOffset } from 'components/Layout/Header/HeaderBottom/MenuIconic/useMenuIconicContentOffset';
import { RefObject, useMemo, useRef } from 'react';
import { PopupProps } from 'types/popup';

type LoginUnderMenuWrapperProps = PopupProps & {
    isMobileView: boolean | undefined;
    userMenuLinkRef: RefObject<HTMLDivElement>;
};

export const LoginUnderMenuWrapper: FC<LoginUnderMenuWrapperProps> = ({ isMobileView, userMenuLinkRef }) => {
    const testIdentifier = 'blocks-popup-login';

    const loginPopupRef = useRef<HTMLDivElement>(null);
    useMenuIconicContentOffset(userMenuLinkRef, loginPopupRef);

    const Component = useMemo(
        () => (
            <LoginUnderHeaderStyled data-testid={testIdentifier} ref={loginPopupRef}>
                <LoginContent isMobileView={isMobileView} />
            </LoginUnderHeaderStyled>
        ),
        [isMobileView],
    );

    return isMobileView ? <Portal>{Component}</Portal> : <>{Component}</>;
};
