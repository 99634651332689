import Link from 'components/Basic/Link/Link';
import Button from 'components/Forms/Button/Button';
import { isDomainLocale } from 'helpers/isDomainLocale';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { CloseIcon, MenuIcon } from 'public/svg/IconsSvg';
import { useMemo } from 'react';
import { twJoin } from 'tailwind-merge';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

type HamburgerButtonProps = {
    isMenuOpened?: boolean;
    onClickCallback?: (event: React.MouseEvent) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    showCloseIcon?: boolean;
};

const TEST_IDENTIFIER = 'layout-header-navigation-hamburger-button';

const HamburgerButton: FC<HamburgerButtonProps> = ({
    isMenuOpened,
    onClickCallback,
    onMouseEnter,
    onMouseLeave,
    showCloseIcon,
}) => {
    const isDesktop = useMediaMin('vl');
    const { defaultLocale, url } = useDomainConfig();
    const isPortugalLocale = isDomainLocale(defaultLocale, 'pt');
    const isSpanishLocale = isDomainLocale(defaultLocale, 'es');
    const hasLongerText = isPortugalLocale || isSpanishLocale;
    const [catalogUrl] = getInternationalizedStaticUrls(['/catalog'], url);
    const t = useTypedTranslationFunction();

    const iconTwClass = twJoin(
        isMenuOpened ? 'notVl:text-primary notVl:hover:text-primary' : 'notVl:text-white notVl:hover:text-white',
    );
    const buttonTwClass = twJoin(
        'select-none rounded-none vl:h-12 vl:w-[230px] vl:rounded-md vl:bg-primary vl:text-description notVl:h-full notVl:w-full notVl:text-small notVl:font-bold notVl:uppercase',
        isSpanishLocale ? 'vl:w-[240px]' : 'vl:w-[230px]',
        isMenuOpened
            ? 'vl:z-menu notVl:bg-white notVl:text-primary notVl:hover:bg-white notVl:hover:text-primary'
            : 'vl:z-auto notVl:bg-primary notVl:text-white notVl:hover:bg-primary notVl:hover:text-white [&>svg]:hover:text-white',
    );

    const ButtonInner = useMemo(
        () => (
            <>
                {showCloseIcon ? (
                    <CloseIcon className={twJoin('mr-3 h-4 w-4 py-0 px-0.5', iconTwClass)} />
                ) : (
                    <MenuIcon className={twJoin('mr-3 h-4 w-4 min-w-[16px]', iconTwClass)} />
                )}
                <span className="vl:hidden">{t('Menu')}</span>
                <span className="hidden whitespace-nowrap vl:block">{t('Product categories')}</span>
            </>
        ),
        [iconTwClass, showCloseIcon, t],
    );

    return (
        <>
            {isDesktop ? (
                <Link
                    className={twJoin(
                        buttonTwClass,
                        'inline-flex items-center whitespace-nowrap active:scale-100 [i>&]:hover:text-white',
                        hasLongerText ? 'pl-3' : 'pl-6',
                    )}
                    size="smallNarrow"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClickCallback}
                    href={catalogUrl}
                    isButton
                    data-testid={TEST_IDENTIFIER}
                >
                    {ButtonInner}
                </Link>
            ) : (
                <Button
                    className={buttonTwClass}
                    type="button"
                    size="smallNarrow"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClickCallback}
                    data-testid={TEST_IDENTIFIER}
                >
                    {ButtonInner}
                </Button>
            )}
        </>
    );
};

export default HamburgerButton;
