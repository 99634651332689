import { MenuItemProps } from './UserMenu';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Pill from 'components/Basic/Pill/Pill';
import { NotImplementedYetHandler } from 'components/Helpers/NotImplementedYet';
import { useAuth } from 'hooks/auth/useAuth';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

type UserMenuMobileProps = {
    menuItems: MenuItemProps[];
};

const itemTwClass =
    'relative min-w-[280px] py-3 pl-14 leading-6 text-primary tracking-wider no-underline border-b border-gray hover:no-underline';

const UserMenuMobile = forwardRef<HTMLDivElement, UserMenuMobileProps>(({ menuItems }, ref) => {
    const t = useTypedTranslationFunction();
    const [, [, logout]] = useAuth();
    const { user } = useCurrentUserData();

    return (
        <div className="fixed left-0 right-0 top-[200px] z-menu w-screen" ref={ref}>
            <div className="absolute top-0 right-0 z-menu flex w-full max-w-full flex-col overflow-hidden bg-white shadow-mobileMenu transition-all">
                {menuItems
                    .filter((menuItem) => menuItem.show)
                    .map((menuItem) => {
                        if (menuItem.link === null) {
                            return (
                                <ExtendedNextLink
                                    href="#"
                                    key={menuItem.title}
                                    className={itemTwClass}
                                    onClick={NotImplementedYetHandler}
                                    type="static"
                                >
                                    <>
                                        {menuItem.count !== null && (
                                            <Pill
                                                className="absolute top-1/2 left-6 -translate-y-1/2 leading-none"
                                                value={menuItem.count}
                                            />
                                        )}
                                        {menuItem.title}
                                    </>
                                </ExtendedNextLink>
                            );
                        }

                        return (
                            <ExtendedNextLink
                                href={menuItem.link}
                                key={menuItem.title}
                                className={itemTwClass}
                                type="static"
                            >
                                <>
                                    {menuItem.count !== null && (
                                        <Pill
                                            className="absolute top-1/2 left-6 -translate-y-1/2 leading-none"
                                            value={menuItem.count}
                                        />
                                    )}
                                    {menuItem.title}
                                </>
                            </ExtendedNextLink>
                        );
                    })}
                <a className={twJoin(itemTwClass, 'bg-grayLighter')} onClick={logout}>
                    {t('Logout')} {user?.firstName} {user?.lastName}
                </a>
            </div>
        </div>
    );
});

UserMenuMobile.displayName = 'UserMenuMobile';

export default UserMenuMobile;
