import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { LogoMetadata } from 'components/Basic/Head/LogoMetadata/LogoMetadata';
import Image from 'components/Basic/Image/Image';
import { ReactElement } from 'react';

const Logo = (): ReactElement => (
    <ExtendedNextLink
        href="/"
        className="flex h-full max-w-[160px] items-center notVl:max-w-[120px] notVl:py-1 notVl:pr-4 notVl:pl-5"
        type="static"
        key="logo"
    >
        <>
            <LogoMetadata />
            <div className="relative h-[27px] w-[84px] lg:w-[169px]">
                <Image alt="Tescoma" src="/images/logo.svg" fill sizes="(min-width: 1024px) 169px, 84px" />
            </div>
        </>
    </ExtendedNextLink>
);

/* @component */
export default Logo;
