import { PacketeryExtendedPoint, PacketeryPickFunction } from './types';
import { canUseDom } from 'helpers/canUseDom';
import { logException } from 'helpers/errors/logException';
import { replaceAll } from 'helpers/replaceAll';
import getConfig from 'next/config';
import nookies from 'nookies';
import { ExternalPickupPointType, PickupPointType } from 'types/pickupPoint';
import { TransportType } from 'types/transport';
import { getCountryCodes } from 'utils/getCountryCodes';

const { publicRuntimeConfig } = getConfig();

/**
 * @see https://docs.packetery.com/01-pickup-point-selection/02-widget-v6.html
 */

export const packeteryClose = (): void => {
    if (canUseDom()) {
        window.Packeta.Widget.close();
    }
};

export const packeteryPick: PacketeryPickFunction = (apiKey, callback, opts, inElement) => {
    if (!canUseDom()) {
        return;
    }

    let defaultInElement: HTMLElement | undefined | null = inElement;

    if (defaultInElement === undefined) {
        defaultInElement = document.getElementById('packetery-container');
    }

    if (defaultInElement === null) {
        return;
    }

    window.Packeta.Widget.pick(apiKey, callback, opts, inElement);
};

const listenWhenPacketeryPopupClosed = (onCloseCallback?: () => void) => {
    let packeteryWrapperElement = null as null | HTMLElement;

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            const removedElements = Array.from(mutation.removedNodes) as HTMLElement[];
            const addedElements = Array.from(mutation.addedNodes) as HTMLElement[];
            if (addedElements.length) {
                packeteryWrapperElement = addedElements[0];
            }

            removedElements.forEach((element) => {
                if (element.style === packeteryWrapperElement?.style) {
                    onCloseCallback?.();
                    observer.disconnect();
                }
            });
        });
    });

    observer.observe(document.body, {
        childList: true,
    });
};

export const mapPacketeryExtendedPoint = (packeteryExtendedPoint: PacketeryExtendedPoint): PickupPointType => ({
    identifier: packeteryExtendedPoint.id.toString(),
    description: packeteryExtendedPoint.directions,
    name: packeteryExtendedPoint.name,
    city: packeteryExtendedPoint.city,
    street: packeteryExtendedPoint.street,
    country: { code: packeteryExtendedPoint.country.toUpperCase(), name: packeteryExtendedPoint.country.toUpperCase() },
    postcode: replaceAll(packeteryExtendedPoint.zip, ' ', ''),
    openingHours: null,
    disabled: false,
    email: null,
    telephone: null,
    reservationDisabled: false,
    locationLatitude: null,
    locationLongitude: null,
});

export const getPacketeryCookie = (): PickupPointType | null => {
    const cookies = nookies.get();
    if ('packeteryPickupPoint' in cookies) {
        return JSON.parse(cookies.packeteryPickupPoint);
    }

    return null;
};

export const setPacketeryCookie = (mappedPacketeryPoint: PickupPointType): void => {
    nookies.set(undefined, 'packeteryPickupPoint', JSON.stringify(mappedPacketeryPoint), {
        path: '/',
        maxAge: 60 * 60 * 24 * 30,
    });
};

export const removePacketeryCookie = (): void => {
    nookies.destroy(undefined, 'packeteryPickupPoint', {
        path: '/',
    });
};

export const openPacketeryPopup = (
    defaultLocale: string,
    changePickupPoint: (
        point: ExternalPickupPointType,
        openedTransportWithPickupPoints: TransportType,
        defaultStoreUuid: string | null,
        selectedStoreUuid: string | null,
    ) => void,
    openedTransportWithPickupPoints: TransportType,
    onCloseCallback?: () => void,
): void => {
    const packeteryApiKey = publicRuntimeConfig.packeteryApiKey;
    if (packeteryApiKey === undefined || packeteryApiKey.length === 0) {
        logException(new Error(`Packeta API key was not set`));

        return;
    }

    listenWhenPacketeryPopupClosed(onCloseCallback);

    packeteryPick(
        packeteryApiKey,
        (point) => {
            changePickupPoint(point, openedTransportWithPickupPoints, null, null);
        },
        { language: defaultLocale, country: getCountryCodes(defaultLocale) },
    );
};
