import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { useTopMenuArticles } from 'connectors/articleInterface/article/Article';
import { useSettings } from 'connectors/Settings/Settings';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export const NO_OF_HEADER_LINKS = 5;

const HeaderLinks: FC = () => {
    const t = useTypedTranslationFunction();
    const headerLinks = useTopMenuArticles(NO_OF_HEADER_LINKS);
    const domainConfig = useDomainConfig();
    const [storesUrl] = getInternationalizedStaticUrls(['/stores'], domainConfig.url);
    const settings = useSettings();

    const linkTwClass = 'ml-7 no-underline text-white font-bold hover:text-white hover:underline';

    return (
        <div className="notVl:hidden">
            {settings && settings.storeCount > 0 && (
                <ExtendedNextLink href={storesUrl} className={linkTwClass} type="static">
                    {t('Stores')}
                </ExtendedNextLink>
            )}
            {headerLinks.map((link) => (
                <ExtendedNextLink
                    href={link.link ?? link.slug}
                    className={linkTwClass}
                    target={link.external ? '_blank' : undefined}
                    key={link.name}
                    type="static"
                >
                    {link.name}
                </ExtendedNextLink>
            ))}
        </div>
    );
};

export default HeaderLinks;
