import { useUserConsentFormMeta } from './formMeta';
import { useUserConsentContext } from 'context/UserConsentProvider/UserConsentProvider';
import { setUserConsentCookie } from 'helpers/cookies/setUserConsentCookie';
import { useShopsysForm } from 'hooks/forms/UseShopsysForm';
import { useGetCookiesUrl } from 'hooks/routes/useGetCookiesUrl';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { UserConsentFormType } from 'types/form';
import { onConsentUpdateGtmEventHandler } from 'utils/Gtm/EventHandlers';
import { getGtmConsentInfo } from 'utils/Gtm/Gtm';

export type UseUserConsentProps = {
    cookiePolicyUrl: string;
    formProviderMethods: UseFormReturn<UserConsentFormType>;
    onAcceptAllCallback: () => void;
    onRejectAllCallback: () => void;
    onSaveCookieChoicesCallback: () => void;
};

const DEFAULT_FORM_VALUES: UserConsentFormType = {
    marketing: false,
    preferences: false,
    statistics: true,
};

export function useUserConsent(onSaveCallback?: () => void): UseUserConsentProps {
    const cookiePolicyUrl = useGetCookiesUrl();

    const userConsentCookie = useUserConsentContext();

    const formProviderMethods = useShopsysForm(undefined, userConsentCookie ?? DEFAULT_FORM_VALUES);

    const formMeta = useUserConsentFormMeta();

    const onSaveCookieChoicesCallback = useCallback(() => {
        const formValues = formProviderMethods.getValues();

        setUserConsentCookie(formValues);
        onConsentUpdateGtmEventHandler(getGtmConsentInfo());

        onSaveCallback?.();
    }, [formProviderMethods, onSaveCallback]);

    const onAcceptAllCallback = useCallback(() => {
        for (const key in formMeta.fields) {
            formProviderMethods.setValue(key as keyof UserConsentFormType, true);
        }

        onSaveCookieChoicesCallback();
    }, [formProviderMethods, formMeta.fields, onSaveCookieChoicesCallback]);

    const onRejectAllCallback = useCallback(() => {
        for (const key in formMeta.fields) {
            if (key === 'statistics') {
                break;
            }

            formProviderMethods.setValue(key as keyof UserConsentFormType, false);
        }

        onSaveCookieChoicesCallback();
    }, [formProviderMethods, formMeta.fields, onSaveCookieChoicesCallback]);

    return {
        cookiePolicyUrl,
        formProviderMethods,
        onAcceptAllCallback,
        onRejectAllCallback,
        onSaveCookieChoicesCallback,
    };
}
