type formMaskingValueType = string | number | undefined;

export const formMasking = (
    value: formMaskingValueType,
    domainDefaultlocale: string,
    type?: string,
): formMaskingValueType => {
    if (value === undefined || !type?.toLowerCase().includes('postcode')) {
        return value;
    }

    if (domainDefaultlocale === 'pl') {
        return value
            .toString()
            .replace(/\D+/g, '')
            .replace(/(\d{2})(\d{1,3})/, '$1-$2');
    }

    if (domainDefaultlocale === 'pt') {
        return value
            .toString()
            .replace(/\D+/g, '')
            .replace(/(\d{4})(\d{1,3})/, '$1-$2');
    }

    return value;
};
