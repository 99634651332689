import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FooterSettingIconsType } from 'types/footerSetting';

type FooterIconsProps = {
    iconSection: FooterSettingIconsType;
};

const footerIconTextClassName = 'mr-5 text-footer uppercase leading-4 tracking-wider text-grayDark';

const FooterIcons: FC<FooterIconsProps> = (props) => {
    const t = useTypedTranslationFunction();
    const iconsSection = props.iconSection;

    if (iconsSection.isVisible === false) {
        return null;
    }

    return (
        <div className="hidden vl:flex vl:flex-row vl:justify-between vl:pb-12">
            {iconsSection.payments.length > 0 && (
                <div className="flex items-center last:ml-20">
                    <div className={footerIconTextClassName}>{t('Payment options')}</div>
                    <div className="-mt-5 -ml-7 flex flex-wrap">
                        {iconsSection.payments.map((item, index) => (
                            <ExtendedNextLink
                                href={item.url}
                                key={index}
                                className="relative mt-5 ml-7 h-7 w-[70px]"
                                target="_blank"
                                type="static"
                            >
                                <Image src={item.image} alt={item.url} className="h-7" height="28" width="70" />
                            </ExtendedNextLink>
                        ))}
                    </div>
                </div>
            )}
            {iconsSection.transports.length > 0 && (
                <div className="flex items-center last:ml-20">
                    <div className={footerIconTextClassName}>{t('Delivery methods')}</div>
                    <div className="-mt-5 -ml-7 flex flex-wrap">
                        {iconsSection.transports.map((item, index) => (
                            <ExtendedNextLink
                                href={item.url}
                                key={index}
                                className="relative mt-5 ml-7 h-7 w-[70px]"
                                target="_blank"
                                type="static"
                            >
                                <Image src={item.image} alt={item.url} className="h-7" height="28" width="70" />
                            </ExtendedNextLink>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FooterIcons;
