import UserText from 'components/Helpers/UserText/UserText';
import FooterMenuItem from 'components/Layout/Footer/FooterMenuItem/FooterMenuItem';
import { FooterSettingMenuType } from 'types/footerSetting';

const TEST_IDENTIFIER = 'layout-footer-footermenu';

type FooterMenuProps = {
    menuSection: FooterSettingMenuType;
};

const FooterMenu: FC<FooterMenuProps> = ({ menuSection }) => {
    return (
        <div
            className="flex flex-col items-stretch vl:flex-row vl:items-start vl:justify-between vl:border-t vl:border-t-borderLight vl:pt-12"
            data-testid={TEST_IDENTIFIER}
        >
            {menuSection.text !== null && (
                <div className="footer-user-text">
                    <UserText htmlContent={menuSection.text} />
                </div>
            )}
            {menuSection.menu.length > 0 && (
                <div className="mb-14 vl:-ml-5 vl:mb-20 vl:flex vl:grow">
                    {menuSection.menu.map((item, index) => (
                        <FooterMenuItem key={index} title={item.title} items={item.items} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FooterMenu;
