import Webline from 'components/Layout/Webline/Webline';
import { createEmptyArray } from 'helpers/arrayUtils';
import Skeleton from 'react-loading-skeleton';

export const StoresSkeleton: FC = () => {
    return (
        <>
            <Webline>
                <Skeleton className="mb-2 h-9 w-4/5 max-w-sm vl:mb-4" />
            </Webline>
            <Webline>
                <Skeleton className="mb-7 h-20 sm:h-16 md:h-11 vl:mb-8 vl:h-14" />
            </Webline>
            <Webline>
                <div className="mb-7 flex w-full flex-col sm:mb-4 xl:mb-8 xl:flex-row">
                    <Skeleton
                        containerClassName="w-full mr-10 xl:w-[220px]"
                        className="relative h-12 w-full xl:w-[220px]"
                    />
                    <Skeleton className="my-3 mx-9 h-7 w-[220px]" />
                </div>
            </Webline>
            <Webline>
                <Skeleton className="mb-[60px] h-[255px] w-full rounded-none md:h-[420px]" />
            </Webline>
            <Webline>
                <Skeleton className="mb-2 h-8 w-3/5 max-w-sm" />
            </Webline>
            <Webline>
                <Skeleton className="mb-7 h-5 w-4/5 max-w-md" />
            </Webline>
            <Webline innerClassName="px-0 lg:px-5">
                {createEmptyArray(15).map((_, index) => (
                    <div
                        key={index}
                        className="flex w-full flex-col border-y border-grayLight px-5 lg:flex-row lg:px-0"
                    >
                        <div className="flex flex-col justify-between py-5 lg:w-3/5 lg:flex-row lg:px-1">
                            <Skeleton containerClassName="lg:w-[30%]" className="h-5" />
                            <Skeleton containerClassName="lg:w-[30%]" className="h-5" />
                            <Skeleton containerClassName="lg:w-[30%]" className="h-4" />
                        </div>
                        <div className="flex flex-row items-center justify-between gap-4 pb-5 lg:w-2/5 lg:justify-end lg:pb-0">
                            <div className="flex flex-row items-center gap-5">
                                {createEmptyArray(3).map((_, index) => (
                                    <Skeleton key={index} className="h-7 w-7" />
                                ))}
                            </div>
                            <Skeleton key={index} className="h-5 w-32" />
                        </div>
                    </div>
                ))}
            </Webline>
        </>
    );
};
