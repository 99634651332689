import { createEmptyArray } from 'helpers/arrayUtils';
import Skeleton from 'react-loading-skeleton';
import twMerge from 'utils/twMerge';

const PHONE_VISIBLE_PRODUCTS = 2;

export const CategoryPromotedProductsSkeleton: FC = () => {
    return (
        <div className="flex gap-4 vl:flex-col vl:gap-0">
            {createEmptyArray(3).map((_, index) => (
                <div
                    key={index}
                    className={twMerge(
                        'h-[500px] w-[calc(50%)] lg:w-[calc(33.33%-4px)] vl:mb-1 vl:flex vl:h-24 vl:w-full vl:flex-row vl:items-center',
                        index > PHONE_VISIBLE_PRODUCTS - 1 && 'hidden lg:block',
                    )}
                >
                    <Skeleton className="h-1/2 w-full vl:mr-5 vl:h-20 vl:w-20" />
                    <Skeleton className="mt-4 h-5 w-full vl:mr-7 vl:mt-0 vl:w-64 xl:w-80" />
                    <Skeleton className="h-5 w-5/6 vl:hidden" />
                    <Skeleton className="mt-4 h-12 w-16 vl:hidden" />
                    <div className="flex flex-col">
                        <Skeleton className="mt-4 h-4 w-2/3 vl:mt-0 vl:w-32" />
                        <Skeleton className="h-4 w-3/4 vl:w-32" />
                    </div>
                    <Skeleton className="mt-3 h-10 w-32 vl:mt-0 vl:h-6 vl:w-20" containerClassName="vl:ml-auto" />
                </div>
            ))}
        </div>
    );
};
