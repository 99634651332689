import { useCurrentCart } from 'connectors/cart/Cart';
import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import Trans from 'next-translate/Trans';
import { CheckIcon, TruckIcon } from 'public/svg/IconsSvg';
import { twJoin } from 'tailwind-merge';

type FreeTransportRangeProps = {
    isFullWidth?: boolean;
};

const barWrapperTwClass = 'relative order-1 mt-2 mr-2 mb-3 h-8 vl:order-0';
const barTwClass = 'absolute top-1/2 left-0 h-[6px] w-full rounded-md bg-grayLighter';
const barRangeTwClass = 'relative h-[6px] rounded-md bg-green transition-width ease-defaultTransition duration-200';
const barIconTwClass =
    'absolute top-1/2 right-0 flex h-7 w-7 translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-round bg-green';

const FreeTransportRange: FC<FreeTransportRangeProps> = ({ isFullWidth }) => {
    const testIdentifier = 'blocks-freetransport-range';
    const { cart, isCartEmpty } = useCurrentCart();
    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();

    const totalPriceWithoutVoucher = cart?.totalItemsPriceWithoutAppliedGiftVoucher.priceWithVat;
    const totalPriceRemaining = cart?.remainingAmountWithVatForFreeTransport;
    const totalPriceRemainingPercents =
        totalPriceWithoutVoucher !== undefined && totalPriceRemaining !== undefined && totalPriceRemaining !== null
            ? (totalPriceWithoutVoucher / (totalPriceWithoutVoucher + totalPriceRemaining)) * 100
            : 0;

    if (isCartEmpty || totalPriceRemaining === null || totalPriceRemaining === undefined) {
        return null;
    }

    const amountFormatted = formatPrice(totalPriceRemaining);

    const freeTransportRangeTwClass = twJoin(
        'flex w-full flex-col text-h6 leading-5 vl:mb-0 vl:block [&>strong]:font-bold [&>strong]:text-green',
        isFullWidth ? 'mb-0 max-w-full' : 'mb-7 max-w-[310px]',
    );

    if (totalPriceRemaining > 0) {
        return (
            <div className={freeTransportRangeTwClass} data-testid={testIdentifier}>
                {`${t('FreeTransportRangeAmountLeft')} ${amountFormatted}`}
                <div className={barWrapperTwClass}>
                    <div className={barTwClass}>
                        <div
                            className={barRangeTwClass}
                            style={{ width: Math.min(totalPriceRemainingPercents, 100) + '%' }}
                        >
                            <div className={barIconTwClass}>
                                <TruckIcon className="h-4 w-4 text-white" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-small leading-4">
                    <Trans
                        i18nKey="Or book for free at your store"
                        components={{
                            0: <span />,
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={freeTransportRangeTwClass} data-testid={testIdentifier}>
            <Trans
                i18nKey="Great, you get free shipping"
                components={{
                    0: <strong />,
                }}
            />
            <div className={barWrapperTwClass}>
                <div className={barTwClass}>
                    <div className={barRangeTwClass} style={{ width: '100%' }}>
                        <div className={barIconTwClass}>
                            <CheckIcon className="h-4 w-4 text-white" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FreeTransportRange;
