import { destroyCartUuidCookie, setCartUuidToCookies } from 'helpers/cookies/cartUuidCookieHelper';
import { destroyDefaultStoreUuidCookie, setDefaultStoreUuidToCookies } from 'helpers/cookies/defaultStoreCookieHelper';
import { AddToCartData } from 'types/cart';
import { PaymentStatusType } from 'types/paymentStatus';
import { StateCreator } from 'zustand';

type LoginLoadingStatus = 'not-loading' | 'loading' | 'loading-with-cart-modifications';

export type UserSlice = {
    canAccessOrderConfirmation: boolean;
    paymentStatus: PaymentStatusType | null;
    lastOrderUuid: string | null;
    lastOrderPaymentType: string | null;
    urlHash: string | undefined;
    lastOrderNumber: string | null;
    userUuid: string | null;
    userEmail: string | null;
    loyaltyClubPoints: number | null;
    loyaltyClubPointsMaximum: number | null;
    cartUuid: string | null;
    selectedDeliveryAddressUuid: string | null;
    defaultStoreUuid: string | null;
    selectedStoreUuid: string | null;
    loginLoading: LoginLoadingStatus;
    addToCartPopupData: AddToCartData | null;
    addToCartFetching: boolean;
    cashOnDeliveryDisabled: boolean;

    updateUserState: (value: Partial<Omit<UserSlice, 'cartUuid' | 'defaultStoreUuid'>>) => void;
    updateCartUuidState: (value: Partial<string | null>) => void;
    updateDefaultStoreUuidState: (value: Partial<string | null>) => void;
};

export const createUserSlice: StateCreator<UserSlice> = (set) => ({
    canAccessOrderConfirmation: false,
    paymentStatus: null,
    urlHash: undefined,
    lastOrderNumber: null,
    userUuid: null,
    userEmail: null,
    loyaltyClubPoints: null,
    loyaltyClubPointsMaximum: null,
    cartUuid: null,
    selectedDeliveryAddressUuid: null,
    defaultStoreUuid: null,
    selectedStoreUuid: null,
    loginLoading: 'not-loading',
    lastOrderPaymentType: null,
    lastOrderUuid: null,
    addToCartPopupData: null,
    addToCartFetching: false,
    cashOnDeliveryDisabled: false,

    updateUserState: (value) => {
        set(value);
    },

    updateCartUuidState: (value) => {
        if (value) {
            setCartUuidToCookies(value);
        } else {
            destroyCartUuidCookie();
        }

        set({ cartUuid: value });
    },

    updateDefaultStoreUuidState: (value) => {
        if (value) {
            setDefaultStoreUuidToCookies(value);
        } else {
            destroyDefaultStoreUuidCookie();
        }

        set({ defaultStoreUuid: value });
    },
});
