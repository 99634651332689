import { useDomainConfig } from 'hooks/useDomainConfig';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { GtmListNameType } from 'types/gtm';
import { ListedProductType } from 'types/product';
import { getGtmProductsListEvent, getNewGtmEcommerceEvent } from 'utils/Gtm/EventFactories';
import { gtmSafePushEvent } from 'utils/Gtm/Gtm';

export const useGtmProductListView = (
    products: ListedProductType[] | null,
    listName: GtmListNameType,
    parentInstanceNumberRef?: MutableRefObject<number>,
): number => {
    const wasViewedRef = useRef(false);
    const [instanceNumber, setInstanceNumber] = useState(parentInstanceNumberRef?.current ?? 0);
    const { url } = useDomainConfig();

    useEffect(() => {
        if (products !== null && !wasViewedRef.current) {
            wasViewedRef.current = true;

            if (parentInstanceNumberRef !== undefined) {
                parentInstanceNumberRef.current++;
                setInstanceNumber(parentInstanceNumberRef.current);
            }

            const event = getNewGtmEcommerceEvent('ec.products_list', true);
            event.ecommerce = getGtmProductsListEvent(
                products,
                listName + (parentInstanceNumberRef !== undefined ? ` ${parentInstanceNumberRef.current}` : ''),
                1,
                0,
                url,
            );
            gtmSafePushEvent(event);
        }
    }, [listName, products, url, parentInstanceNumberRef]);

    return instanceNumber;
};
