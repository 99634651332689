import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Heading from 'components/Basic/Heading/Heading';
import Image from 'components/Basic/Image/Image';
import Link from 'components/Basic/Link/Link';
import FreeTransportRange from 'components/Blocks/FreeTransportRange/FreeTransportRange';
import Button from 'components/Forms/Button/Button';
import Spinbox from 'components/Forms/Spinbox/Spinbox';
import { Popup } from 'components/Layout/Popup/Popup';
import { useCurrentCart } from 'connectors/cart/Cart';
import { useSettings } from 'connectors/Settings/Settings';
import { useAddToCart } from 'hooks/cart/useAddToCart';
import { usePersoo } from 'hooks/persoo/usePersoo';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { PERSOO_IDS } from 'modules/persoo/persooTypes';
import { useRouter } from 'next/router';
import { ArrowIcon } from 'public/svg/IconsSvg';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';
import tinycolor from 'tinycolor2';
import { AddToCartData } from 'types/cart';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export type AddToCartPopupProps = {
    onCloseCallback: () => void;
    fetching: boolean;
} & AddToCartData;

const AddToCartTimeoutDelay = 100;

export const AddToCartPopup: FC<AddToCartPopupProps> = ({
    gtmListName,
    listIndex,
    onCloseCallback,
    product,
    fetching,
}) => {
    const testIdentifier = 'blocks-product-addtocartpopup-product';
    const t = useTypedTranslationFunction();
    const domainConfig = useDomainConfig();
    const [cartUrl] = getInternationalizedStaticUrls(['/cart'], domainConfig.url);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const spinboxRef = useRef<HTMLInputElement | null>(null);
    const [addToCart] = useAddToCart(gtmListName);
    const [quantityTotal, setQuantityTotal] = useState(product.quantityTotal);
    const settings = useSettings();
    const { cart } = useCurrentCart();
    const totalPriceRemaining = cart?.remainingAmountWithVatForFreeTransport;
    const showFreeTransportRange =
        totalPriceRemaining !== null &&
        totalPriceRemaining !== undefined &&
        settings?.anyFreeTransportApplicableOnCondition === true;
    const router = useRouter();

    const { isPersooAllowed } = usePersoo();

    const updateCartAfterTimeout = () => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            addToCart(product.uuid, listIndex, spinboxRef.current!.valueAsNumber, gtmListName, true);

            setQuantityTotal(spinboxRef.current!.valueAsNumber);
        }, AddToCartTimeoutDelay);
    };

    useEffect(() => {
        router.events.on('routeChangeStart', onCloseCallback);

        return () => {
            router.events.off('routeChangeStart', onCloseCallback);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // need to refactor
    useEffect(() => {
        const cartItem = cart?.items.find((items) => items.product.uuid === product.uuid);

        if (cartItem?.quantity) {
            setQuantityTotal(product.quantityTotal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart?.items.length, product.uuid]);

    return (
        <Popup
            className="w-[600px] max-w-[calc(100%-40px)] rounded-md bg-white p-0 vl:w-[1020px]"
            onCloseCallback={onCloseCallback}
            hideCloseButton
        >
            <Heading
                className="m-0 flex w-full flex-wrap items-center justify-center rounded-t-md bg-greenVeryLight px-5 pb-3 pt-4 !text-h5 normal-case leading-7 text-greenDark vl:!text-h3 vl:leading-8"
                type="h2"
            >
                {t('Added to cart')}{' '}
                <span className="ml-2 font-normal">{`(${product.quantityAdded} ${product.unitName})`}</span>
            </Heading>
            <div className="py-10 px-5 vl:pr-12 vl:pb-11 vl:pl-14">
                <div
                    className="flex flex-row items-start rounded-md pb-10 vl:items-center vl:pb-14"
                    data-testid={testIdentifier}
                >
                    {product.image && !fetching ? (
                        <div className="flex h-20 max-h-[80px] w-20 min-w-[80px] items-center justify-center">
                            <Image alt={product.fullName} src={product.image} width="80" height="80" />
                        </div>
                    ) : (
                        <Skeleton className="h-20 w-20" />
                    )}
                    <div className="w-full pl-2 lg:flex lg:items-center lg:justify-start lg:pl-5">
                        <div
                            className="block text-body font-normal leading-5 tracking-wider text-primary [work-break:break-word]"
                            data-testid={testIdentifier + '-name'}
                        >
                            {!fetching ? (
                                <ExtendedNextLink
                                    href={product.slug}
                                    type="static"
                                    className="text-primary no-underline hover:text-grayDark"
                                >
                                    <>{product.fullName}</>
                                </ExtendedNextLink>
                            ) : (
                                <Skeleton className="h-8 w-32 md:h-4 md:w-52" />
                            )}
                            {!!product.currentColorVariant && (
                                <div className="mt-1 text-tiny leading-3">
                                    <span className="mr-2 text-grayDark">{t('Color')}:</span>
                                    <span
                                        style={
                                            product.currentColorVariant.colorImage !== null
                                                ? {
                                                      background: `transparent url(${product.currentColorVariant.colorImage}) no-repeat center center`,
                                                  }
                                                : { backgroundColor: product.currentColorVariant.value }
                                        }
                                        className={twJoin(
                                            '-mt-1 mr-1 inline-block h-4 w-4 rounded-round align-middle',
                                            tinycolor(product.currentColorVariant.value).isLight() &&
                                                'border border-grayDark',
                                            product.currentColorVariant.colorImage !== null &&
                                                'border-none bg-[length:16px_16px]',
                                        )}
                                    />
                                    {product.currentColorVariant.name}
                                </div>
                            )}
                        </div>
                        <div className="hidden vl:ml-auto vl:mr-7 vl:flex vl:flex-col vl:items-start vl:justify-start vl:text-left">
                            <div className="text-h6 font-bold">{t('Need more pieces?')}</div>
                            <div className="text-small">
                                {t('You have got {{ count }} pcs of this product', {
                                    count: quantityTotal,
                                })}
                            </div>
                        </div>
                        <div className="hidden vl:block vl:w-32">
                            <Spinbox
                                min={1}
                                max={product.stockQuantity}
                                step={1}
                                defaultValue={quantityTotal}
                                onChangeValueCallback={updateCartAfterTimeout}
                                ref={spinboxRef}
                                fetching={fetching}
                                overstepMessage={{
                                    tooHigh: t('You cannot add more items'),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center vl:flex-row vl:justify-between">
                    <Button
                        className="order-1 vl:order-0 vl:justify-start"
                        onClick={onCloseCallback}
                        type="button"
                        size="small"
                        data-testid={testIdentifier + '-button-back'}
                    >
                        <ArrowIcon className="h-2.5 w-2.5 rotate-90" />
                        {t('Back')}
                    </Button>
                    {showFreeTransportRange && <FreeTransportRange />}
                    <Link
                        className="order-0 mb-7 w-full px-0 text-h5 leading-5 vl:order-1 vl:mb-0 vl:w-auto vl:px-6"
                        href={cartUrl}
                        isButton
                        variant="primary"
                    >
                        <>
                            {t('Continue to cart')}
                            <ArrowIcon className="ml-3 h-4 w-4 -rotate-90" />
                        </>
                    </Link>
                </div>
            </div>
            {isPersooAllowed && <div id={PERSOO_IDS.prebasketCarousel} />}
        </Popup>
    );
};
