import { SearchFormMetaType, useAutocompleteSearchForm, useAutocompleteSearchFormMeta } from './formMeta';
import { useAutocompleteSearch } from 'connectors/search/AutocompleteSearch';
import { SEARCH_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import useDebounce from 'hooks/helpers/UseDebounce';
import { useLatest } from 'hooks/ui/useLatest';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useRouter } from 'next/router';
import { ComponentType, useEffect, useState } from 'react';
import { SubmitHandler, UseFormReturn, useWatch } from 'react-hook-form';
import { AutocompleteSearchFormType } from 'types/form';
import { AutocompleteSearchType } from 'types/search';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export type WithAutocompleteSearchProps = {
    onAutocompleteSearchSubmitCallback: SubmitHandler<AutocompleteSearchFormType>;
    autocompleteSearchResults: AutocompleteSearchType | undefined;
    autocompleteSearchQueryValue: string;
    formProviderMethods: UseFormReturn<AutocompleteSearchFormType>;
    formMeta: SearchFormMetaType;
    fetching: boolean;
};

const withAutocompleteSearch = (Component: ComponentType<WithAutocompleteSearchProps>): FC => {
    const HOC = () => {
        const router = useRouter();
        const [formProviderMethods] = useAutocompleteSearchForm();
        const formMeta = useAutocompleteSearchFormMeta(formProviderMethods);
        const autocompleteSearchQueryValue = useWatch({
            name: formMeta.fields.autocompleteSearchQuery.name,
            control: formProviderMethods.control,
        });
        const debouncedAutocompleteSearchQuery = useDebounce(autocompleteSearchQueryValue, 200);
        const [autocompleteSearchApiResults, fetching] = useAutocompleteSearch(debouncedAutocompleteSearchQuery);
        const [autocompleteSearchResults, setAutocompleteSearchResults] = useState<AutocompleteSearchType | undefined>(
            undefined,
        );
        const { url } = useDomainConfig();
        const [searchUrl] = getInternationalizedStaticUrls(['/search'], url);

        const autocompleteResultDiff = JSON.stringify(autocompleteSearchApiResults);
        const latestIsValid = useLatest(formProviderMethods.formState.isValid);
        useEffect(() => {
            if (latestIsValid.current) {
                setAutocompleteSearchResults(autocompleteSearchApiResults);
            } else {
                setAutocompleteSearchResults(undefined);
            }
        }, [autocompleteResultDiff, autocompleteSearchQueryValue, latestIsValid]); // eslint-disable-line react-hooks/exhaustive-deps

        const onAutocompleteSearchSubmitHandler: SubmitHandler<AutocompleteSearchFormType> = (_data, event) => {
            event?.preventDefault();
            router.push({
                pathname: searchUrl,
                query: { [SEARCH_QUERY_PARAMETER_NAME]: autocompleteSearchQueryValue },
            });
        };

        return (
            <Component
                formMeta={formMeta}
                formProviderMethods={formProviderMethods}
                autocompleteSearchResults={autocompleteSearchResults}
                autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                onAutocompleteSearchSubmitCallback={onAutocompleteSearchSubmitHandler}
                fetching={fetching}
            />
        );
    };

    return HOC;
};

/* @component */
export default withAutocompleteSearch;
