import LabelWrapper from 'components/Forms/Lib/LabelWrapper/LabelWrapper';
import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';

type NativeProps = ExtractNativePropsFromDefault<
    InputHTMLAttributes<HTMLInputElement>,
    'name',
    'id' | 'disabled' | 'required' | 'onClick'
>;

type CheckboxProps = NativeProps & {
    /**
     * Display Label of the HTML checkbox element
     */
    label: string | ReactNode | ReactNode[];
    /**
     * Display count of items. This is an optional prop primary from the parameters filter.
     */
    count?: number;
} & (
        | { fieldRef: ControllerRenderProps<any, any>; value?: never; onChange?: never; checked?: never }
        | {
              fieldRef?: never;
              value?: string | boolean;
              onChange: ChangeEventHandler<HTMLInputElement>;
              checked: boolean;
          }
    );

/**
 * An HTML Checkbox element of type checkbox
 */
const Checkbox: FC<CheckboxProps> = ({
    checked,
    name,
    value,
    label,
    id,
    onChange,
    fieldRef,
    count,
    required,
    disabled,
    onClick,
    className,
}) => {
    return (
        <LabelWrapper
            count={count}
            htmlFor={id === undefined ? name + 'checkbox-id' : id}
            inputType="checkbox"
            checked={fieldRef?.value ?? checked}
            required={required}
            label={label}
            className={className}
        >
            <input
                value={value}
                onChange={onChange}
                onClick={onClick}
                disabled={disabled}
                required={required}
                {...fieldRef}
                id={id === undefined ? name + 'checkbox-id' : id}
                checked={fieldRef?.value ?? checked}
                type="checkbox"
                className="peer sr-only"
            />
        </LabelWrapper>
    );
};

/* @component */
export default Checkbox;
