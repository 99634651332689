import { HTMLAttributes } from 'react';
import { RefObject } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, 'children', 'style' | 'className'>;

type WeblineProps = {
    /**
     * Type for change type of webline. If you don't fill this prop then the webline will be without style.
     */
    type?: 'colored' | 'dark' | 'light' | 'blog';
    removePaddingOnMobile?: boolean;
    'data-testid'?: string;
    parentRef?: RefObject<HTMLDivElement>;
    innerClassName?: string;
} & NativeProps;

const Webline: FC<WeblineProps> = ({
    type,
    removePaddingOnMobile,
    parentRef,
    children,
    className,
    innerClassName,
    ...props
}) => {
    const testIdentifier = props['data-testid'] ?? 'layout-webline' + (type !== undefined ? '-' + type : '');

    return (
        <div
            className={twMerge(
                type === 'colored' && 'bg-primary',
                type === 'dark' && 'bg-grayDark',
                type === 'light' && 'bg-grayLight',
                type === 'blog' && 'bg-grayLight lg:mb-16 xxl:mx-auto xxl:mt-0 xxl:max-w-[1400px] xxl:rounded-md',
                className,
            )}
            {...props}
            data-testid={testIdentifier}
            ref={parentRef}
        >
            <div
                className={twMerge(
                    'px-5 pt-0 xl:mx-auto xl:mt-0 xl:w-layout',
                    removePaddingOnMobile && 'notVl:p-0',
                    innerClassName,
                )}
                {...props}
            >
                {children}
            </div>
        </div>
    );
};

/* @component */
export default Webline;
