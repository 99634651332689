import { CoordinatesType, MapMarkerType } from 'types/map';
import { ProductDetailType, VariantDetailType } from 'types/product';
import { SelectPickupPointPlacement } from 'types/selectPickupPoint';
import { SearchedStoresType } from 'types/store';
import { StateCreator } from 'zustand';

export type SelectPickupPointSliceState = {
    pickupPoints: SearchedStoresType[];
    storeListFetching: boolean;
    mapFetching: boolean;
    productDetail?: ProductDetailType | VariantDetailType;
    placement: SelectPickupPointPlacement;
    markers: MapMarkerType[];
    storeSearchQueryValue: string;
    pageOffset: number;
    clickedMarker: MapMarkerType | null;
    geoLocationForFilter: CoordinatesType | null;
    userGeoLocation: CoordinatesType | null;
};

export type SelectPickupPointSlice = {
    updateSelectPickupPointSlice: (value: Partial<SelectPickupPointSliceState>) => void;
    resetSelectPickupPointSlice: () => void;
} & SelectPickupPointSliceState;

const initialState: SelectPickupPointSliceState = {
    storeListFetching: false,
    pickupPoints: [],
    mapFetching: false,
    productDetail: undefined,
    placement: 'product-detail',
    markers: [],
    storeSearchQueryValue: '',
    clickedMarker: null,
    pageOffset: 0,
    geoLocationForFilter: null,
    userGeoLocation: null,
};

export const createSelectPickupPointSlice: StateCreator<SelectPickupPointSlice> = (set) => ({
    ...initialState,
    updateSelectPickupPointSlice: (value) => {
        set(value);
    },

    resetSelectPickupPointSlice: () => {
        set(initialState);
    },
});
