import { getFirstImage } from 'connectors/image/Image';
import { useNavigationQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { NavigationItemType } from 'types/navigation';

export function useNavigationItems(): { navigationItems: NavigationItemType[]; fetching: boolean } {
    const [{ data, error, fetching }] = useNavigationQueryApi();
    useQueryError(error);

    const navigationItems: NavigationItemType[] = [];

    if (data?.categories === undefined) {
        return { navigationItems: [], fetching };
    }

    for (const mappedDataItem of data.categories) {
        navigationItems.push({
            ...mappedDataItem,
            image: getFirstImage(mappedDataItem.imageIcon),
            // children: mapCategories(mappedDataItem.children),
        });
    }

    return { navigationItems, fetching };
}
