import { ArticleDetailFragmentApi, SimpleArticleFragmentApi, useTopMenuArticlesQueryApi } from 'graphql/generated';
import { ArticleDetailType, SimpleArticleType } from 'types/article';

export const useTopMenuArticles = (noOfArticles: number): SimpleArticleType[] => {
    const [result] = useTopMenuArticlesQueryApi({ variables: { noOfArticles } });
    const mappedArticles = [];

    if (result.data?.articles.edges !== undefined && result.data.articles.edges !== null) {
        for (const articleEdge of result.data.articles.edges) {
            if (articleEdge?.node !== undefined && articleEdge.node !== null) {
                mappedArticles.push(articleEdge.node);
            }
        }
    }

    return mappedArticles;
};

export const mapArticleDetail = (apiData: ArticleDetailFragmentApi): ArticleDetailType => {
    return {
        ...apiData,
        __typename: 'Article',
    };
};

export const mapSimpleArticle = (apiData: SimpleArticleFragmentApi): SimpleArticleType => {
    return {
        ...apiData,
    };
};
