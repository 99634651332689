import { createEmptyArray } from 'helpers/arrayUtils';
import { generateRandomWidth } from 'helpers/skeletonHelpers';
import Skeleton from 'react-loading-skeleton';

const TransportAndPaymentSelectSkeleton: FC = () => {
    return (
        <ul>
            {createEmptyArray(5).map((_, index) => (
                <li
                    className="relative flex border-b border-grayMidDarker py-4 before:absolute before:top-7 before:left-[90px] before:inline-block before:h-[18px] before:w-[18px] before:-translate-y-1/2 before:rounded-full before:border before:opacity-30 before:content-[''] last:border-0 vl:before:left-[100px]"
                    key={index}
                >
                    <Skeleton className="mr-[53px] h-5 w-[70px] vl:mr-[78px]" />
                    <div className="vl:flex vl:w-full vl:justify-between">
                        <Skeleton className="my-1 h-4" style={{ width: generateRandomWidth(150, 200) }} />
                        <Skeleton className="my-1 h-4" style={{ width: generateRandomWidth(30, 50) }} />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default TransportAndPaymentSelectSkeleton;
