import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { ChangeEventHandler, ReactElement, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type BaseType = { gdprConsents: string[] };

type GdprConsentsWrapperProps = {
    render: (handler: (type: string) => ChangeEventHandler<HTMLInputElement>, value: string[]) => ReactElement;
};

export const GdprConsentsWrapper: FC<GdprConsentsWrapperProps> = ({ render }) => {
    const { getValues, setValue, control, register, trigger } = useFormContext<BaseType>();
    useEffectOnce(() => {
        register('gdprConsents');
    });
    const gdprConsentsValue = useWatch({ name: 'gdprConsents', control: control });

    const onGdprContentChangeHandler = useCallback<(type: string) => ChangeEventHandler<HTMLInputElement>>(
        (type) => (e) => {
            const values = [...getValues('gdprConsents')].filter(Boolean);
            if (e.target.checked) {
                setValue('gdprConsents', Array.from(new Set([...values, type])).filter(Boolean));
            } else {
                setValue('gdprConsents', Array.from(new Set([...values.filter((i) => i !== type)])));
            }
            trigger('gdprConsents');
        },
        [getValues, setValue, trigger],
    );

    return render(onGdprContentChangeHandler, gdprConsentsValue);
};
