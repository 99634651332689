import { mapAddToCartPopupData } from 'connectors/cart/Cart';
import { useAddToCart } from 'hooks/cart/useAddToCart';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { AddToCartPersooDetailType } from 'modules/persoo/persooTypes';
import { useEffect } from 'react';
import { usePersistStore } from 'store/usePersistStore';

export const usePersooAddToCart = (): void => {
    const [addToCart] = useAddToCart('persoo');
    const { currencyCode } = useDomainConfig();
    const { updateUserState } = usePersistStore((s) => s);

    useEffect(() => {
        const handleAddToCartFromPersoo = async (event: CustomEvent<AddToCartPersooDetailType>) => {
            const { detail } = event;
            updateUserState({
                addToCartFetching: true,
            });
            const addToCartResult = await addToCart(detail.itemId, detail.listIndex, 1, detail.gtmListName);
            updateUserState({
                addToCartFetching: false,
            });
            const mappedAddToCartResult = mapAddToCartPopupData(addToCartResult, currencyCode);

            if (mappedAddToCartResult) {
                updateUserState({
                    addToCartPopupData: {
                        gtmListName: detail.gtmListName,
                        listIndex: detail.listIndex,
                        product: mappedAddToCartResult,
                    },
                });
            }
        };

        document.addEventListener('persoo:add-to-cart', handleAddToCartFromPersoo);

        return () => {
            document.removeEventListener('persoo:add-to-cart', handleAddToCartFromPersoo);
        };
    }, [addToCart, currencyCode, updateUserState]);
};
