import Overlay from 'components/Basic/Overlay/Overlay';
import Portal from 'components/Basic/Portal/Portal';
import { useLatest } from 'hooks/ui/useLatest';
import { RemoveIcon } from 'public/svg/IconsSvg';
import { useEffect } from 'react';
import { PopupProps } from 'types/popup';
import twMerge from 'utils/twMerge';

export const Popup: FC<PopupProps> = ({
    children,
    className,
    contentClassName,
    disablePortal,
    hideCloseButton,
    onCloseCallback,
}) => {
    const testIdentifier = 'layout-popup';

    const latestOnCloseCallback = useLatest(onCloseCallback);

    useEffect(() => {
        const handler: EventListener = (event) => {
            if (
                event instanceof KeyboardEvent &&
                event.key === 'Escape' &&
                typeof latestOnCloseCallback.current === 'function'
            ) {
                latestOnCloseCallback.current();
            }
        };

        document.addEventListener('keydown', handler);

        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [latestOnCloseCallback]);

    const content = (
        <>
            <Overlay onClick={onCloseCallback} />
            <div
                className={twMerge(
                    'fixed left-1/2 top-1/2 z-aboveOverlay flex max-h-[calc(100%-20px)] max-w-[calc(100%-20px)] -translate-x-1/2 -translate-y-1/2 cursor-auto flex-col rounded-lg bg-blueVeryLight p-5 font-light shadow-popup transition-opacity transition-transform vl:max-h-[calc(100%-40px)] vl:max-w-[calc(100%-40px)]',
                    className,
                )}
                role="dialog"
                aria-modal
                data-testid={testIdentifier}
            >
                {hideCloseButton !== true && (
                    <div className="absolute right-2 top-2 flex h-9 items-center justify-end">
                        <button
                            className="group z-[10] flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-0 bg-blueVeryLight text-[10px] text-primary no-underline outline-none"
                            type="button"
                            onClick={onCloseCallback}
                        >
                            <RemoveIcon className="h-3.5 w-3.5 text-currentColor transition-all duration-300 group-hover:text-primary/90" />
                        </button>
                    </div>
                )}
                <div className={twMerge('h-full overflow-hidden', contentClassName)}>{children}</div>
            </div>
        </>
    );

    if (!disablePortal) {
        return <Portal>{content}</Portal>;
    }

    return content;
};
