import { HTMLAttributes } from 'react';
import { twJoin } from 'tailwind-merge';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'style'>;

type ChoiceFormLineProps = NativeProps & {
    isLastItem?: boolean;
};

/**
 * A form line element used for wrapping inputs
 */
const ChoiceFormLine: FC<ChoiceFormLineProps> = ({ isLastItem, style, children }) => {
    return (
        <div className={twJoin('w-fit', isLastItem ? 'mb-1' : 'mb-4')} style={style}>
            {children}
        </div>
    );
};

/* @component */
export default ChoiceFormLine;
