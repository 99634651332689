import { ScriptApi, ScriptTypeEnumApi } from 'graphql/generated';
import { replaceAll } from 'helpers/replaceAll';

const SCRIPT_VARIABLES = {
    orderNumber: 'number',
    orderPriceWithVAT: 'total_price',
} as const;

type ScriptVariablesToReplaceType = {
    variable: keyof typeof SCRIPT_VARIABLES;
    replaceWith: string | number;
};

const placementsByPage = {
    all: [
        ScriptTypeEnumApi.PlacementAllPagesApi,
        ScriptTypeEnumApi.PlacementAllPagesAfterContentApi,
        ScriptTypeEnumApi.PlacementAllPagesHeaderApi,
    ],
    order: [ScriptTypeEnumApi.PlacementOrderSentPageApi, ScriptTypeEnumApi.PlacementOrderSentPageHeaderApi],
};

export const injectScripts = (
    scripts: ScriptApi[],
    page: 'all' | 'order',
    variablesToReplace?: ScriptVariablesToReplaceType[],
): void => {
    scripts.forEach((script) => {
        const scriptNode = document
            .createRange()
            .createContextualFragment(
                variablesToReplace ? parseScriptFromAdmin(script.script, variablesToReplace) : script.script,
            );

        if (!placementsByPage[page].includes(script.placement)) {
            return;
        }

        {
            switch (script.placement) {
                case ScriptTypeEnumApi.PlacementAllPagesApi:
                case ScriptTypeEnumApi.PlacementOrderSentPageApi:
                    document.body.prepend(scriptNode);
                    break;
                case ScriptTypeEnumApi.PlacementAllPagesAfterContentApi:
                    document.body.append(scriptNode);
                    break;
                case ScriptTypeEnumApi.PlacementAllPagesHeaderApi:
                case ScriptTypeEnumApi.PlacementOrderSentPageHeaderApi:
                    document.head.append(scriptNode);
                    break;
            }
        }
    });
};

const parseScriptFromAdmin = (script: string, variablesToReplace: ScriptVariablesToReplaceType[]) => {
    let scriptWithReplacedVariables = script;
    variablesToReplace.forEach((variableToReplace) => {
        scriptWithReplacedVariables = replaceAll(
            scriptWithReplacedVariables,
            `{${SCRIPT_VARIABLES[variableToReplace.variable]}}`,
            `${variableToReplace.replaceWith}`,
        );
    });

    return scriptWithReplacedVariables;
};
