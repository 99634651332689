import { getAddressObjectForGtmUser, getRandomPageId } from './Helpers';
import { mapGtmCartItemType, mapGtmOrderItemType, mapGtmShippingInfo } from './Mappers';
import { useCurrentCart } from 'connectors/cart/Cart';
import SHA256 from 'crypto-js/sha256';
import { OrderDetailFragmentApi, OrderItemTypeEnumApi } from 'graphql/generated';
import { canUseDom } from 'helpers/canUseDom';
import { getUserConsentCookie } from 'helpers/cookies/getUserConsentCookie';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { useMemo } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { BreadcrumbItemType } from 'types/breadcrumb';
import { CartItemType, CartType } from 'types/cart';
import { CategoryDetailType, CategoryHierarchyType } from 'types/category';
import { CurrentCustomerType } from 'types/customer';
import { FriendlyUrlPageType } from 'types/friendlyUrl';
import {
    GtmCartInfoEventType,
    GtmCartItemType,
    GtmConsentInfoType,
    GtmEcommerceEventType,
    GtmPageInfoType,
    GtmPageType,
    GtmPageViewEventType,
    GtmPaymentType,
    GtmPurchaseType,
    GtmSearchEventType,
    GtmUserInfoType,
} from 'types/gtm';
import { OrderDetailItemType, OrderDetailType } from 'types/orders';
import { PaymentType } from 'types/payment';
import { PickupPointType } from 'types/pickupPoint';
import { PromoCodeType } from 'types/promoCode';
import { MappedTransportType } from 'types/transport';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export const useGtmCartEventInfo = (): GtmCartInfoEventType => {
    const { cart, promoCodes, isInitiallyLoaded } = useCurrentCart();
    const { cartUuid } = usePersistStore((s) => s);
    const { isUserLoggedIn } = useCurrentUserData();
    const domain = useDomainConfig();

    return useMemo(() => {
        if ((cartUuid === null && !isUserLoggedIn) || cart === null) {
            return { cart: null, isLoaded: isInitiallyLoaded };
        }

        let products: GtmCartItemType[] = [];
        if (cart.items.length > 0) {
            products = cart.items.map((cartItem, index) => mapGtmCartItemType(cartItem, index, domain.url));
        }

        let urlCart;
        if (isUserLoggedIn) {
            const [loginRelativeUrl, cartRelativeUrl] = getInternationalizedStaticUrls(['/login', '/cart'], domain.url);
            const loginAbsoluteUrlWithoutLeadingSlash = loginRelativeUrl.slice(1);
            urlCart = domain.url + loginAbsoluteUrlWithoutLeadingSlash + '?r=' + cartRelativeUrl;
        } else {
            const [abandonedCartRelativeUrl] = getInternationalizedStaticUrls(
                [{ url: '/abandoned-cart/:cartUuid', param: cartUuid }],
                domain.url,
            );
            const abandonedCartRelativeUrlWithoutLeadingSlash = abandonedCartRelativeUrl.slice(1);
            urlCart = domain.url + abandonedCartRelativeUrlWithoutLeadingSlash;
        }

        return {
            cart: {
                urlCart,
                currency: domain.currencyCode,
                value: cart.totalItemsPrice.priceWithoutVat - (cart.loyaltyClubDiscount ?? 0),
                valueWithTax: cart.totalItemsPrice.priceWithVat - (cart.loyaltyClubDiscount ?? 0),
                products,
                coupons: promoCodes.map(({ code }) => code),
            },
            isLoaded: isInitiallyLoaded,
        };
    }, [cart, cartUuid, domain.currencyCode, domain.url, isInitiallyLoaded, isUserLoggedIn, promoCodes]);
};

export const getGtmPageInfoForFriendlyUrl = (
    data: FriendlyUrlPageType | null | undefined,
    slug: string,
    breadcrumbs: BreadcrumbItemType[] | undefined,
): GtmPageInfoType => {
    const defaultPageInfo: GtmPageInfoType = {
        type: '404',
        path: slug,
        pageId: getRandomPageId(),
        breadcrumbs:
            breadcrumbs?.map((item) => {
                delete item.__typename;

                return item;
            }) ?? [],
    };

    if (data === null || data === undefined) {
        return defaultPageInfo;
    }

    switch (data.__typename) {
        case 'RegularProduct':
        case 'Variant':
        case 'MainVariant':
            defaultPageInfo.type = 'product';
            break;
        case 'Category':
            defaultPageInfo.type = getCategoryOrSeoCategoryGtmListName(data);
            defaultPageInfo.category = getGtmCategoryNames(data.categoryHierarchy);
            defaultPageInfo.categoryId = getGtmCategoryIds(data.categoryHierarchy);
            defaultPageInfo.categoryLevel = getGtmCategoryLevel(data.categoryHierarchy);
            break;
        case 'Store':
            defaultPageInfo.type = 'store';
            break;
        case 'Article':
            defaultPageInfo.type = 'text';
            break;
        case 'BlogArticle':
            defaultPageInfo.type = 'article';
            defaultPageInfo.articleId = data.uuid;
            break;
        case 'Brand':
            defaultPageInfo.type = 'brand';
            defaultPageInfo.brandId = data.id;
            break;
        case 'Flag':
            defaultPageInfo.type = 'flag';
            break;
        case 'BlogCategory':
            defaultPageInfo.type = 'blog';
            break;
        default:
            break;
    }

    return defaultPageInfo;
};

export const getGtmPageInfoType = (
    pageType: GtmPageType,
    path: string,
    breadcrumbs: BreadcrumbItemType[] | undefined,
): GtmPageInfoType => ({
    type: pageType,
    path,
    pageId: getRandomPageId(),
    breadcrumbs: breadcrumbs ?? [],
});

const getGtmCategoryNames = (categoryHierarchy: CategoryHierarchyType) => {
    return categoryHierarchy.map((cat) => cat.name);
};

const getGtmCategoryIds = (categoryHierarchy: CategoryHierarchyType) => {
    return categoryHierarchy.map((cat) => cat.apiIdentifier);
};

const getGtmCategoryLevel = (categoryHierarchy: CategoryHierarchyType) => {
    return categoryHierarchy.length;
};

export const gtmSafePushEvent = (event: GtmPageViewEventType | GtmEcommerceEventType | GtmSearchEventType): void => {
    if (canUseDom()) {
        window.dataLayer = window.dataLayer ?? [];
        window.dataLayer.push(event);
    }
};

export const getGtmPurchaseData = (
    cart: CartType,
    transport: MappedTransportType,
    pickupPoint: PickupPointType | null,
    payment: PaymentType,
    promoCodes: PromoCodeType[],
    orderNumber: string,
    domainUrl: string,
    sendQuestionnaire: boolean,
): GtmPurchaseType => {
    const { shippingDetail } = mapGtmShippingInfo(pickupPoint);

    return {
        type: transport.isPersonalPickup ? 'reservation' : 'order',
        reviewConsents: sendQuestionnaire,
        id: orderNumber,
        coupons: promoCodes.map(({ code }) => code),
        couponsType: promoCodes.map(({ isGiftVoucher }) => (isGiftVoucher ? 'present coupon' : 'sale coupon')),
        clubPoints: cart.loyaltyClubPoints ?? 0,
        discountAmount: cart.totalDiscountPrice.priceWithoutVat,
        discountAmountWithTax: cart.totalDiscountPrice.priceWithVat,
        value: cart.totalPrice.priceWithoutVat,
        valueWithTax: cart.totalPrice.priceWithVat,
        valueTax: cart.totalPrice.vatAmount,
        currency: cart.totalPrice.currencyCode,
        products: cart.items.map((cartItem: CartItemType, index) => mapGtmCartItemType(cartItem, index, domainUrl)),
        paymentType: payment.name,
        paymentPrice: payment.price.priceWithoutVat,
        paymentPriceWithTax: payment.price.priceWithVat,
        shippingType: transport.name,
        shippingDetail: shippingDetail,
        shippingPrice: transport.price.priceWithoutVat,
        shippingPriceWithTax: transport.price.priceWithVat,
    };
};

export const getGtmPaymentData = (order: OrderDetailType): GtmPaymentType => {
    const paymentItem = order.items.filter((orderItem) => {
        return orderItem.type === OrderItemTypeEnumApi.PaymentApi;
    })[0];
    const transportItem = order.items.filter((orderItem) => {
        return orderItem.type === OrderItemTypeEnumApi.TransportApi;
    })[0];

    return {
        type: order.isReservation ? 'reservation' : 'order',
        id: order.number,
        clubPoints: order.loyaltyClubPoints ?? 0,
        value: order.totalPrice.priceWithoutVat,
        valueWithTax: order.totalPrice.priceWithVat,
        valueTax: order.totalPrice.vatAmount,
        currency: order.totalPrice.currencyCode,
        products: order.items
            .filter((orderItem) => {
                return orderItem.type === OrderItemTypeEnumApi.ProductApi && !orderItem.isRoundingItem;
            })
            .map((orderItem: OrderDetailItemType) => mapGtmOrderItemType(orderItem)),
        paymentType: order.paymentType ?? '',
        paymentPrice: paymentItem.totalPrice.priceWithoutVat,
        paymentPriceWithTax: paymentItem.totalPrice.priceWithVat,
        shippingType: order.transport?.name ?? '',
        shippingPrice: transportItem.totalPrice.priceWithoutVat,
        shippingPriceWithTax: transportItem.totalPrice.priceWithVat,
    };
};

export const getGtmConsentInfo = (): GtmConsentInfoType => {
    const userConsentCookie = getUserConsentCookie();

    return {
        marketing: userConsentCookie?.marketing ? 'granted' : 'denied',
        statistics: userConsentCookie?.statistics ? 'granted' : 'denied',
        preferences: userConsentCookie?.preferences ? 'granted' : 'denied',
    };
};

export const getGtmUserInfo = (currentCustomer: CurrentCustomerType | null | undefined): GtmUserInfoType => {
    const userInfo: GtmUserInfoType = {
        type: 'visitor',
    };

    if (currentCustomer !== undefined && currentCustomer !== null) {
        const address = getAddressObjectForGtmUser(currentCustomer);
        userInfo.type = 'customer';
        userInfo.id = currentCustomer.uuid;
        userInfo.email = currentCustomer.email;
        userInfo.emailHash = SHA256(currentCustomer.email).toString();
        userInfo.phoneNumber = currentCustomer.telephone;
        userInfo.name = currentCustomer.firstName;
        userInfo.surname = currentCustomer.lastName;
        userInfo.street = address.street;
        userInfo.city = address.city;
        userInfo.psc = address.postcode;
        userInfo.country = address.country;
        userInfo.group = currentCustomer.pricingGroup;
    }

    return userInfo;
};

export const getGtmPurchaseUserInfo = (
    order: OrderDetailFragmentApi | OrderDetailType,
    currentCustomer: CurrentCustomerType | null | undefined,
): GtmUserInfoType => {
    const address = getAddressObjectForGtmUser(currentCustomer, order);

    const userInfo: GtmUserInfoType = {
        type: 'visitor',
        email: order.email,
        emailHash: SHA256(order.email).toString(),
        phoneNumber: order.telephone!,
        name: order.firstName!,
        surname: order.lastName!,
        ...address,
    };

    if (currentCustomer !== undefined && currentCustomer !== null) {
        userInfo.type = 'customer';
        userInfo.id = currentCustomer.uuid;
        userInfo.group = currentCustomer.pricingGroup;
    }

    return userInfo;
};

export const getCategoryOrSeoCategoryGtmListName = (data: CategoryDetailType): 'seo category' | 'category' =>
    data.originalCategorySlug !== null ? 'seo category' : 'category';
