import { getFirstImage } from 'connectors/image/Image';
import {
    FooterSettingBigIconFragmentApi,
    FooterSettingFragmentApi,
    FooterSettingItemFragmentApi,
    useFooterSettingQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import {
    FooterSettingIconsType,
    FooterSettingItemType,
    FooterSettingMenuType,
    FooterSettingSocialItemType,
    FooterSettingType,
} from 'types/footerSetting';

export function useFooterSetting(): FooterSettingType | null {
    const [{ data, error }] = useFooterSettingQueryApi();
    useQueryError(error);

    if (data?.footerSetting === undefined) {
        return null;
    }

    return mapFooterSettingApiData(data.footerSetting);
}

const mapFooterSettingApiData = (apiData: FooterSettingFragmentApi): FooterSettingType => {
    return {
        logoSection: mapLogoSectionApiData(apiData),
        socialSection: mapSocialSectionApiData(apiData),
        iconsSection: mapIconSectionApiData(apiData),
        menuSection: mapMenuSectionApiData(apiData),
        newsletterImage: mapNewsletterImageApiData(apiData),
    } as FooterSettingType;
};

const mapFooterSettingItemApiData = (
    items: FooterSettingItemFragmentApi[] | FooterSettingBigIconFragmentApi[],
): FooterSettingItemType[] => {
    const mappedFooterSettingItems = [];

    for (const apiData of items) {
        mappedFooterSettingItems.push({
            ...apiData,
            image: apiData.image === null ? null : getFirstImage(apiData.image),
        });
    }

    return mappedFooterSettingItems;
};

const mapLogoSectionApiData = (apiData: FooterSettingFragmentApi): FooterSettingItemType[] => {
    return apiData.sectionLogoVisible === false ? [] : mapFooterSettingItemApiData(apiData.sectionLogoItems);
};

const mapSocialSectionApiData = (apiData: FooterSettingFragmentApi): FooterSettingSocialItemType[] => {
    const socialItems = [];
    if (apiData.facebookVisible === true && apiData.facebookUrl !== null) {
        socialItems.push({
            title: 'Facebook news overview',
            type: 'facebook',
            icon: 'Facebook' as const,
            url: apiData.facebookUrl,
        });
    }

    if (apiData.instagramVisible === true && apiData.instagramUrl !== null) {
        socialItems.push({
            title: 'Inspiration and a look behind the scenes',
            type: 'instagram',
            icon: 'Instagram' as const,
            url: apiData.instagramUrl,
        });
    }

    if (apiData.youtubeVisible === true && apiData.youtubeUrl !== null) {
        socialItems.push({
            title: 'Recipes and instructions on youtube',
            type: 'youtube',
            icon: 'Youtube' as const,
            url: apiData.youtubeUrl,
        });
    }

    return apiData.sectionSocialVisible === false ? [] : socialItems;
};

const mapIconSectionApiData = (apiData: FooterSettingFragmentApi): FooterSettingIconsType => {
    return {
        isVisible: apiData.sectionPaymentVisible === true || apiData.sectionShippingVisible === true,
        payments:
            apiData.sectionPaymentVisible === false ? [] : mapFooterSettingItemApiData(apiData.sectionPaymentItems),
        transports:
            apiData.sectionShippingVisible === false ? [] : mapFooterSettingItemApiData(apiData.sectionShippingItems),
    };
};

const mapMenuSectionApiData = (apiData: FooterSettingFragmentApi): FooterSettingMenuType => {
    const menuArticles = [];

    if (apiData.sectionArticlesVisible === true) {
        menuArticles.push({
            title: apiData.articleColumn1Title === null ? null : apiData.articleColumn1Title,
            items: apiData.articles1,
        });

        menuArticles.push({
            title: apiData.articleColumn2Title === null ? null : apiData.articleColumn2Title,
            items: apiData.articles2,
        });

        menuArticles.push({
            title: apiData.articleColumn3Title === null ? null : apiData.articleColumn3Title,
            items: apiData.articles3,
        });

        menuArticles.push({
            title: apiData.articleColumn4Title === null ? null : apiData.articleColumn4Title,
            items: apiData.articles4,
        });
    }

    let content = null;
    if (apiData.sectionTextVisible === true && apiData.sectionTextContent !== null) {
        content = apiData.sectionTextContent;
    }

    return {
        menu: menuArticles,
        text: content,
    };
};

const mapNewsletterImageApiData = (apiData: FooterSettingFragmentApi): string | null => {
    if (apiData.sectionNewsletterVisible === false) {
        return null;
    }

    return getFirstImage(apiData.newsletterImage);
};
