import { WatchDogPopupProps } from 'components/Blocks/Product/WatchDog/Popup/WatchDogPopup';
import { WatchProductPersooDetailType } from 'modules/persoo/persooTypes';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const WatchDogPopup = dynamic<WatchDogPopupProps>(() =>
    import('components/Blocks/Product/WatchDog/Popup/WatchDogPopup').then((component) => component.WatchDogPopup),
);

export const PersooWatchDog: FC = () => {
    const [productForWatchDog, setProductForWatchDog] = useState<WatchProductPersooDetailType | null>(null);

    useEffect(() => {
        const handleAddToCartFromPersoo = (event: CustomEvent<WatchProductPersooDetailType>) => {
            const { itemId, itemName } = event.detail;
            setProductForWatchDog({ itemId, itemName });
        };

        document.addEventListener('persoo:watch-product', handleAddToCartFromPersoo);
    }, []);

    return (
        <>
            {productForWatchDog && (
                <WatchDogPopup
                    onCloseCallback={() => setProductForWatchDog(null)}
                    productUuid={productForWatchDog.itemId}
                    productName={productForWatchDog.itemName}
                />
            )}
        </>
    );
};
