import NavigationItem from './NavigationItem/NavigationItem';
import Subcategories from './Subcategories/SubCategories';
import { useNavigationItems } from 'connectors/navigation/Navigation';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';
import { NavigationItemType } from 'types/navigation';

type DesktopMenuProps = {
    isOpen: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
};

const TEST_IDENTIFIER = 'layout-header-navigation';

const ESTIMATED_COUNT_OF_CATEGORIES = 15;

const DesktopMenu: FC<DesktopMenuProps> = ({ isOpen, onMouseEnter, onMouseLeave }) => {
    const { navigationItems, fetching } = useNavigationItems();
    const [hoveredCategory, setHoveredCategory] = useState<NavigationItemType | null>(null);
    const [activeCategory, setActiveCategory] = useState<NavigationItemType | null>(null);

    useEffect(() => {
        if (hoveredCategory !== activeCategory && hoveredCategory) {
            openCategoryCallback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoveredCategory]);

    useEffect(() => {
        setHoveredCategory(null);
        setActiveCategory(null);
    }, [isOpen]);

    const openCategoryCallback = () => {
        setActiveCategory(hoveredCategory);
    };

    if (navigationItems.length === 0 && !fetching) {
        return null;
    }

    return (
        <div
            className="relative hidden w-full align-middle vl:inline-block"
            data-testid={TEST_IDENTIFIER}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div
                className={twJoin(
                    'absolute left-0 -top-2.5 z-menu rounded-md bg-grayLight p-0 shadow-menu',
                    isOpen && 'opacity-1 pointer-events-auto flex',
                    !isOpen && 'pointer-events-none hidden opacity-0',
                )}
            >
                <div
                    className={twJoin(
                        'flex w-[230px] flex-col overflow-hidden rounded-l-md bg-primary',
                        activeCategory ? 'rounded-r-none' : 'rounded-r-md',
                    )}
                >
                    {!fetching
                        ? navigationItems.map((item, index) => (
                              <NavigationItem
                                  key={index}
                                  hoveredCategory={hoveredCategory}
                                  activeCategory={activeCategory}
                                  setHoveredCategory={setHoveredCategory}
                                  item={item}
                              />
                          ))
                        : Array(ESTIMATED_COUNT_OF_CATEGORIES)
                              .fill(null)
                              .map((_, index) => (
                                  <div key={index} className="flex h-10 w-full items-center gap-2 px-3">
                                      <Skeleton
                                          baseColor="#31363a"
                                          highlightColor="#6d717586"
                                          className="h-6 w-6 !rounded-full"
                                      />
                                      <Skeleton
                                          className="mt-2 h-3 w-40"
                                          baseColor="#31363a"
                                          highlightColor="#6d717581"
                                          style={{ width: (Math.random() + 5) * 25 }}
                                      />
                                  </div>
                              ))}
                </div>
                {activeCategory !== null && activeCategory.hasChildren && (
                    <Subcategories activeCategory={activeCategory.uuid} key={activeCategory.uuid} />
                )}
            </div>
        </div>
    );
};

export default DesktopMenu;
