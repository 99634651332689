import { useDomainConfig } from 'hooks/useDomainConfig';
import {
    allowedDomainsPersoo,
    PERSOO_IDS,
    PERSOO_SEARCH_QUERY_PARAMETER_NAME,
    PERSOO_SEARCH_QUERY_PARAMETER_VALUE,
    PERSOO_SEARCH_TIMEOUT_SECONDS,
} from 'modules/persoo/persooTypes';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type PersooHookProps = {
    elementId?: typeof PERSOO_IDS[keyof typeof PERSOO_IDS];
};

export const usePersoo = ({ elementId }: PersooHookProps = {}) => {
    const { domainId } = useDomainConfig();
    const router = useRouter();

    const isPersooAllowed = allowedDomainsPersoo.includes(domainId);
    const isPersooTriggered =
        !!Object.getOwnPropertyDescriptor(router.query, PERSOO_SEARCH_QUERY_PARAMETER_NAME) &&
        router.query[PERSOO_SEARCH_QUERY_PARAMETER_NAME] === PERSOO_SEARCH_QUERY_PARAMETER_VALUE;

    const [shouldDisplayTescomaResult, setShouldDisplayTescomaResult] = useState(
        !isPersooTriggered || !isPersooAllowed,
    );

    useEffect(() => {
        const element: HTMLElement | null = elementId ? document.getElementById(elementId) : null;
        if (!isPersooAllowed || !isPersooTriggered || !elementId || !element) {
            setShouldDisplayTescomaResult(true);

            return undefined;
        }

        setShouldDisplayTescomaResult(false);

        let timeout: ReturnType<typeof setTimeout> | null = null;

        // Persoo element empty
        timeout = setTimeout(() => {
            if (document.getElementById(elementId)?.innerHTML.trim() === '') {
                setShouldDisplayTescomaResult(true);
            }
        }, PERSOO_SEARCH_TIMEOUT_SECONDS * 1000);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [elementId, isPersooAllowed, isPersooTriggered]);

    return {
        isPersooAllowed,
        isPersooTriggered,
        shouldDisplayTescomaResult,
    };
};
