import { replaceAll } from 'helpers/replaceAll';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { PhoneIcon } from 'public/svg/IconsSvg';
import { twJoin } from 'tailwind-merge';

type ContactProps = {
    desktopOnly?: boolean;
    textColor?: string;
};

const Contact: FC<ContactProps> = ({ desktopOnly, textColor }) => {
    const t = useTypedTranslationFunction();
    const phone = t('Header phone number');
    const { diallingCode } = useDomainConfig();

    return (
        <div className={twJoin('pr-5 vl:pr-0', desktopOnly && 'hidden vl:block', textColor ?? 'text-primary')}>
            <div className="flex h-5 items-center">
                <div className="h-full w-6">
                    <PhoneIcon className="text-currentColor" />
                </div>
                <a
                    className="font-bold text-inherit no-underline hover:text-inherit hover:no-underline"
                    href={`tel:${diallingCode}${replaceAll(phone, ' ', '')}`}
                >
                    {phone}
                </a>
            </div>
            <div className="h-4 pl-6 text-tiny notVl:hidden">{t('Header opening hours')}</div>
        </div>
    );
};

export default Contact;
