import ProductResultInfo from './ProductResultInfo';
import Image from 'components/Basic/Image/Image';
import { AUTOCOMPLETE_PRODUCT_LIMIT } from 'components/Layout/Header/HeaderBottom/Search/Autocomplete/Autocomplete';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { twJoin } from 'tailwind-merge';
import { AutocompleteProductType } from 'types/product';

type ProductResultProps = {
    products: AutocompleteProductType[];
    autocompleteSearchQueryValue: string;
};

const TEST_IDENTIFIER = 'layout-header-search-autocomplete-products';

const ProductResults: FC<ProductResultProps> = ({ products, autocompleteSearchQueryValue }) => {
    const t = useTypedTranslationFunction();

    return (
        <div className="order-2 vl:pt-4 vl:pb-5 xl:px-5">
            <p className="mb-4 hidden text-small leading-snug tracking-wider text-grayDark vl:block">{t('Products')}</p>
            <ul
                className="list-none p-0 vl:grid vl:grid-cols-[repeat(3,_128px)] vl:gap-6 xxl:grid-cols-[repeat(4,_128px)]"
                data-testid={TEST_IDENTIFIER}
            >
                {products.map(
                    (product, index) =>
                        index < AUTOCOMPLETE_PRODUCT_LIMIT && (
                            <li
                                className={twJoin(
                                    'group relative flex items-start border-b border-grayMidDarker py-5 last:border-b-0 vl:block vl:border-b-0 vl:py-0',
                                    index === 3 && 'notVl:border-b-0',
                                    index > 3 && 'notVl:hidden',
                                    index > 5 && 'vl:notXxl:hidden',
                                )}
                                key={product.uuid}
                                data-testid={`${TEST_IDENTIFIER}-${index}`}
                            >
                                <div className="relative mr-5 h-20 w-20 before:block before:pb-[100%] before:content-[''] vl:mr-0 vl:h-[128px] vl:w-[128px]">
                                    <Image
                                        alt={product.fullName}
                                        src={product.image}
                                        className="absolute left-0 top-0"
                                        width={128}
                                        height={128}
                                    />
                                </div>
                                <ProductResultInfo
                                    autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                                    product={product}
                                    index={index}
                                />
                            </li>
                        ),
                )}
            </ul>
        </div>
    );
};

export default ProductResults;
