import { CategoryPromotedProductsSkeleton } from 'components/Blocks/Product/CategoryPromotedProducts/CategoryPromotedProductsSkeleton';
import { ProductItemSkeleton } from 'components/Blocks/Product/List/ListedItem/ProductItemSkeleton';
import { BreadcrumbsSkeleton } from 'components/Layout/Breadcrumbs/BreadcrumbsSkeleton';
import Webline from 'components/Layout/Webline/Webline';
import { createEmptyArray } from 'helpers/arrayUtils';
import Skeleton from 'react-loading-skeleton';

export const CategoryDetailPageSkeleton: FC = () => {
    return (
        <Webline>
            <BreadcrumbsSkeleton count={1} />
            <div className="mb-12 flex">
                <div className="flex w-full flex-col gap-4">
                    <Skeleton className="h-9 w-5/6" />
                    <Skeleton count={4} className="mb-3 h-4" />
                </div>
                <Skeleton className="ml-20 hidden h-44 w-[420px] rounded-none vl:block" />
            </div>
            <div className="mb-7 flex flex-wrap gap-2">
                {createEmptyArray(4).map((_, index) => (
                    <Skeleton
                        key={index}
                        className="h-full"
                        containerClassName="h-14 sm:w-[calc(50%-4px)] md:w-[calc(33.33%-8px)] lg:h-20 xl:w-[calc(25%-8px)] w-full"
                    />
                ))}
            </div>

            <div className="flex vl:gap-6">
                <Skeleton className="hidden h-[1000px] w-[300px] vl:block" />
                <div className="flex w-full flex-col">
                    <div className="mb-7">
                        <Skeleton className="mb-2 h-6 w-40" />
                        <Skeleton className="h-10 rounded-lg lg:w-20 " />
                    </div>
                    <div className="rounded-md border-y-[5px] border-grayLight py-5 vl:border-[5px] vl:p-5">
                        <div className="mb-5 flex items-center justify-between">
                            <Skeleton className="h-6 w-64" containerClassName="flex vl:justify-center" />
                            <Skeleton
                                className="hidden h-10 w-10 sm:block vl:hidden"
                                count={2}
                                circle
                                containerClassName="flex gap-1"
                            />
                        </div>
                        <CategoryPromotedProductsSkeleton />
                    </div>
                    <div className="mt-10 mb-8 flex flex-wrap justify-between gap-2 vl:hidden">
                        <Skeleton className="h-12 w-40" />
                        <Skeleton className="h-12 w-32" />
                    </div>
                    <div className="mt-10 hidden items-center justify-between vl:flex">
                        <Skeleton count={3} className="h-9 w-24" containerClassName="flex gap-3" />
                        <Skeleton className="h-4 w-20" />
                    </div>
                    <div className="mb-7 grid w-full grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-y-4 xl:grid-cols-4">
                        {createEmptyArray(10).map((_, index) => (
                            <ProductItemSkeleton key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </Webline>
    );
};
