import { BreadcrumbsSkeleton } from 'components/Layout/Breadcrumbs/BreadcrumbsSkeleton';
import Webline from 'components/Layout/Webline/Webline';
import { createEmptyArray } from 'helpers/arrayUtils';
import Skeleton from 'react-loading-skeleton';

export const BlogCategoryPageSkeleton: FC = () => {
    return (
        <Webline>
            <BreadcrumbsSkeleton count={2} />
            <div className="mb-16 flex flex-col gap-8 vl:flex-row">
                <Skeleton className="h-[450px] vl:w-[270px]" />
                <div className="flex w-full flex-col gap-20">
                    <div className="flex flex-col gap-5">
                        <Skeleton className="h-8 w-3/5" />
                        <Skeleton className="h-[300px] max-w-[700px] rounded-none" />
                        <Skeleton className="h-8 w-3/4" />
                        <div className="flex gap-5">
                            <Skeleton className="h-4 w-24 rounded-sm" />
                            <Skeleton className="h-4 w-10 rounded-sm" />
                        </div>
                        <Skeleton className="h-24 w-full" />
                    </div>
                    <div className="flex flex-wrap justify-center gap-10">
                        {createEmptyArray(4).map((_, index) => (
                            <div
                                className="flex max-w-[400px] basis-full flex-col gap-4 lg:basis-[calc(50%-40px)]"
                                key={index}
                            >
                                <Skeleton className="h-[160px] w-full rounded-none" />
                                <Skeleton className="h-8 w-full" />
                                <div className="flex gap-5">
                                    <Skeleton className="h-4 w-24 rounded-sm" />
                                    <Skeleton className="h-4 w-10 rounded-sm" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Webline>
    );
};
