import {
    LoyaltyClubPointsWithPriceApi,
    PriceFragmentApi,
    PriceInterfaceApi,
    ProductPriceFragmentApi,
} from 'graphql/generated';
import { LoyaltyClubPointsWithPriceType, PriceType, ProductPricesType, ProductPriceType } from 'types/price';

export const mapPriceData = (price: PriceInterfaceApi, currencyCode: string): PriceType => {
    return {
        priceWithVat: Number.parseFloat(price.priceWithVat),
        priceWithoutVat: Number.parseFloat(price.priceWithoutVat),
        vatAmount: Number.parseFloat(price.vatAmount),
        currencyCode,
    };
};

export const mapPriceInputData = (price: PriceType): PriceFragmentApi => {
    return {
        priceWithVat: price.priceWithVat.toString(),
        priceWithoutVat: price.priceWithoutVat.toString(),
        vatAmount: price.vatAmount.toString(),
        __typename: 'Price',
    };
};

export const mapProductPriceData = (
    price: ProductPriceFragmentApi['price'],
    currencyCode: string,
): ProductPriceType => {
    return {
        ...mapPriceData(price, currencyCode),
        isPriceFrom: price.isPriceFrom,
        validFrom: price.validFrom ? new Date(price.validFrom) : null,
        validTo: price.validTo ? new Date(price.validTo) : null,
    };
};

export const mapProductPricesData = (
    prices: ProductPriceFragmentApi['prices'],
    currencyCode: string,
): ProductPricesType => {
    return {
        basic: mapProductPriceData(prices.basic, currencyCode),
        special:
            prices.special !== null && prices.basic.priceWithoutVat !== prices.special.priceWithoutVat
                ? mapProductPriceData(prices.special, currencyCode)
                : null,
        tescoma:
            prices.tescoma !== null && prices.basic.priceWithoutVat !== prices.tescoma.priceWithoutVat
                ? mapProductPriceData(prices.tescoma, currencyCode)
                : null,
    };
};

export const mapLoyaltyPointsObtainedData = (
    loyaltyClubPointsObtained: LoyaltyClubPointsWithPriceApi,
): LoyaltyClubPointsWithPriceType => {
    return {
        loyaltyClubPoints: loyaltyClubPointsObtained.loyaltyClubPoints,
        loyaltyClubPointsPrice:
            loyaltyClubPointsObtained.loyaltyClubPointsPrice !== null
                ? Number.parseFloat(loyaltyClubPointsObtained.loyaltyClubPointsPrice)
                : null,
    };
};
