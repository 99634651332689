import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { ProductResultPrice } from 'components/Layout/Header/HeaderBottom/Search/Autocomplete/Result/ProductResult/ProductResultPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import React, { useCallback } from 'react';
import { AutocompleteProductType } from 'types/product';
import { onClickProductDetailGtmEventHandler, onClickSuggestResultGtmEventHandler } from 'utils/Gtm/EventHandlers';

type ProductResultInfoProps = {
    product: AutocompleteProductType;
    autocompleteSearchQueryValue: string;
    index: number;
};

const ProductResultInfo: FC<ProductResultInfoProps> = ({ product, autocompleteSearchQueryValue, index }) => {
    const t = useTypedTranslationFunction();
    const { url, type } = useDomainConfig();
    const onProductClickHandler = useCallback(
        (product: AutocompleteProductType, index: number) => () => {
            onClickProductDetailGtmEventHandler(product, 'suggest', index, url);
            onClickSuggestResultGtmEventHandler(autocompleteSearchQueryValue, 'product', product.fullName);
        },
        [autocompleteSearchQueryValue, url],
    );

    return (
        <div className="vl:px-1">
            <div className="mb-3 text-[15px] font-medium leading-snug tracking-wide group-hover:underline vl:mt-1.5 vl:text-small">
                {product.fullName}
            </div>
            {type !== 'web' && <ProductResultPrice product={product} />}
            <ExtendedNextLink
                type="product"
                href={product.slug}
                className="absolute inset-0"
                onClick={onProductClickHandler(product, index)}
            >
                <span className="sr-only">{t('Go to product detail')}</span>
            </ExtendedNextLink>
        </div>
    );
};

export default ProductResultInfo;
