import { yupResolver } from '@hookform/resolvers/yup';
import { useShopsysForm } from 'hooks/forms/UseShopsysForm';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { LoginFormType } from 'types/form';
import { object, string } from 'yup';

export const useLoginForm = (defaultEmail?: string): [UseFormReturn<LoginFormType>, LoginFormType] => {
    const t = useTypedTranslationFunction();
    const resolver = yupResolver(
        object().shape({
            email: string().required(t('This field is required')).email(t('This value is not a valid email')),
            password: string().required(t('This field is required')),
        }),
    );

    const defaultValues: LoginFormType = { email: defaultEmail ?? '', password: '' };

    return [useShopsysForm(resolver, defaultValues), defaultValues];
};

type LoginFormMetaType = {
    formName: string;
    fields: {
        [key in keyof LoginFormType]: {
            name: key;
            label: string;
            errorMessage: string | undefined;
        };
    };
};

export const useLoginFormMeta = (formProviderMethods: UseFormReturn<LoginFormType>): LoginFormMetaType => {
    const t = useTypedTranslationFunction();

    return useMemo(
        () => ({
            formName: 'login_form',
            fields: {
                email: {
                    name: 'email' as const,
                    label: t('Your email'),
                    errorMessage: formProviderMethods.formState.errors.email?.message,
                },
                password: {
                    name: 'password' as const,
                    label: t('Password'),
                    errorMessage: formProviderMethods.formState.errors.password?.message,
                },
            },
        }),
        [
            formProviderMethods.formState.errors.email?.message,
            formProviderMethods.formState.errors.password?.message,
            t,
        ],
    );
};
