import { useMemo } from 'react';
import { UserConsentFormType } from 'types/form';

type UserConsentFormMeta = {
    formName: string;
    fields: {
        [key in keyof UserConsentFormType]: {
            name: key;
        };
    };
};

export const useUserConsentFormMeta = (): UserConsentFormMeta => {
    return useMemo(
        () => ({
            formName: 'user-consent-form',
            fields: {
                marketing: {
                    name: 'marketing' as const,
                },
                preferences: {
                    name: 'preferences' as const,
                },
                statistics: {
                    name: 'statistics' as const,
                },
            },
        }),
        [],
    );
};
