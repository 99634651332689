import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { useFooterSetting } from 'connectors/footerSetting/footerSetting';

const FooterBigIcons: FC = () => {
    const footerSetting = useFooterSetting();

    if (footerSetting === null) {
        return null;
    }

    const logoSection = footerSetting.logoSection;

    if (logoSection.length === 0) {
        return null;
    }

    return (
        <div className="hidden vl:mt-5 vl:flex vl:justify-end vl:pb-4">
            {logoSection.map((item, index) => (
                <ExtendedNextLink
                    href={item.url}
                    key={index}
                    target="_blank"
                    className="relative ml-7 flex h-[70px] w-36 content-center items-center first:ml-0"
                    type="static"
                >
                    <>
                        <Image src={item.image} fill alt="Icon" />
                    </>
                </ExtendedNextLink>
            ))}
        </div>
    );
};

export default FooterBigIcons;
