import { BreadcrumbsSkeleton } from 'components/Layout/Breadcrumbs/BreadcrumbsSkeleton';
import Webline from 'components/Layout/Webline/Webline';
import { createEmptyArray } from 'helpers/arrayUtils';
import Skeleton from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';

export const StoreDetailPageSkeleton: FC = () => {
    return (
        <>
            <Webline>
                <BreadcrumbsSkeleton count={2} />
                <div className="flex flex-col lg:flex-row">
                    <div className="flex w-full flex-col gap-6">
                        <Skeleton className="h-10 w-5/6" />
                        <Skeleton className="h-10 w-56" />
                        <Skeleton className="h-5 w-14 rounded-none" />
                        <Skeleton className="h-6 w-32" />
                        <div className="flex flex-col gap-1">
                            {createEmptyArray(7).map((_, index) => (
                                <Skeleton className="h-5 w-48" key={index} />
                            ))}
                        </div>
                        <Skeleton className="h-6 w-16" />
                        <div className="flex flex-col gap-1">
                            {createEmptyArray(2).map((_, index) => (
                                <Skeleton className="h-4 w-80" key={index} />
                            ))}
                        </div>
                        <Skeleton className="h-4 w-48" />
                        <Skeleton className="h-4 w-48" />
                        <Skeleton className="h-4 w-52 lg:w-96" />
                        <div className="mt-4 mb-10 flex flex-col justify-between gap-4">
                            <Skeleton className="h-6 w-1/4" />
                            {createEmptyArray(3).map((_, index) => (
                                <div className="flex flex-row items-center gap-5" key={index}>
                                    <Skeleton className="h-11 w-11" />
                                    <Skeleton className="h-5 w-36" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <Skeleton
                        className={twJoin(
                            'mb-5 block h-[250px] w-full rounded-none',
                            'lg:h-[350px] lg:w-[350px]',
                            'xl:h-[600px] xl:w-[600px]',
                        )}
                        containerClassName="flex justify-end w-full"
                    />
                </div>
            </Webline>
            <Webline innerClassName="px-0 xl:px-5">
                <div className="justify-centers flex w-full flex-row flex-wrap items-center rounded-none bg-grayLight p-5">
                    {createEmptyArray(4).map((_, index) => (
                        <Skeleton
                            key={index}
                            containerClassName="h-[170px] w-full max-w-[250px] flex justify-center items-center h-full m-3"
                            className="h-full w-full rounded-none"
                        />
                    ))}
                </div>
            </Webline>
        </>
    );
};
