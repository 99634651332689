import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import Button from 'components/Forms/Button/Button';
import Webline from 'components/Layout/Webline/Webline';
import { useNotificationBars } from 'connectors/notificationBars/NotificationBars';
import { useAuth } from 'hooks/auth/useAuth';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import decode from 'jwt-decode';
import Trans from 'next-translate/Trans';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

const NotificationBars: FC = () => {
    const notificationBarItems = useNotificationBars();
    const { isUserLoggedIn, user } = useCurrentUserData();
    const [isAdminLoggedInAsUser, setIsAdminLoggedAsUser] = useState(false);
    const [, [, logout]] = useAuth();

    useEffect(() => {
        try {
            const cookies = parseCookies();
            const decodedAccessToken = decode<Record<string, any>>(cookies.accessToken);
            if ('administratorUuid' in decodedAccessToken && decodedAccessToken.administratorUuid !== null) {
                setIsAdminLoggedAsUser(true);

                return;
            }
            setIsAdminLoggedAsUser(false);
        } catch (e) {
            setIsAdminLoggedAsUser(false);
        }
    }, [isUserLoggedIn]);

    return (
        <>
            {isAdminLoggedInAsUser && (
                <div className="relative z-menu bg-red">
                    <Webline>
                        <div className="font-small flex items-center justify-center text-center font-bold leading-6">
                            <Trans
                                i18nKey="adminLoggedInAsCustomerWarning"
                                defaultTrans="Warning! You are logged in as a customer with the email {{ email }} <button>Log out</button>"
                                values={{ email: user?.email }}
                                components={{
                                    button: (
                                        <Button
                                            type="button"
                                            size="small"
                                            variant="secondary"
                                            style={{ marginLeft: '10px' }}
                                            onClick={logout}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </Webline>
                </div>
            )}

            {notificationBarItems.map((item, index) => (
                <div
                    className={twJoin(
                        'relative z-menu aspect-[40/11] w-screen items-center justify-center md:aspect-auto md:w-auto',
                        item.webImage !== null ? 'md:block' : 'md:hidden',
                        item.mobileImage !== null ? 'block' : 'hidden',
                    )}
                    style={{ backgroundColor: item.rgbColor }}
                    key={index}
                >
                    <Webline>
                        <div className="font-small flex w-full items-center justify-center text-center font-bold leading-6">
                            {item.url ? (
                                <>
                                    {item.webImage !== null && (
                                        <ExtendedNextLink href={item.url as string} passHref type="static">
                                            <div className="hidden aspect-[240/13] w-[1200px] max-w-[100vw] cursor-pointer md:block">
                                                <Image
                                                    src={item.webImage}
                                                    alt="info text"
                                                    fill
                                                    sizes="(min-width: 1440px) 1440px, 100vw"
                                                />
                                            </div>
                                        </ExtendedNextLink>
                                    )}
                                    {item.mobileImage !== null && (
                                        <ExtendedNextLink href={item.url as string} passHref type="static">
                                            <div className="my-0 -mx-5 block cursor-pointer md:hidden">
                                                <Image
                                                    src={item.mobileImage}
                                                    alt="info text"
                                                    fill
                                                    sizes="(min-width: 1440px) 1440px, 100vw"
                                                />
                                            </div>
                                        </ExtendedNextLink>
                                    )}
                                </>
                            ) : (
                                <>
                                    {item.webImage !== null && (
                                        <div className="hidden aspect-[240/13] w-[1200px] max-w-[100vw] cursor-pointer border border-black object-contain md:block">
                                            <Image
                                                src={item.webImage}
                                                alt="info text"
                                                fill
                                                sizes="(min-width: 1440px) 1440px, 100vw"
                                            />
                                        </div>
                                    )}
                                    {item.mobileImage !== null && (
                                        <div className="my-0 -mx-5 block cursor-pointer md:hidden">
                                            <Image
                                                src={item.mobileImage}
                                                alt="info text"
                                                fill
                                                sizes="(min-width: 1440px) 1440px, 100vw"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </Webline>
                </div>
            ))}
        </>
    );
};

export default NotificationBars;
