import Footer from './Footer/Footer';
import Header from './Header/Header';
import Webline from './Webline/Webline';

const ErrorLayout: FC = ({ children }) => {
    return (
        <div className="flex min-h-screen flex-col justify-between">
            <Webline type="colored" style={{ marginBottom: '32px', position: 'relative' }}>
                <Header simple />
            </Webline>
            {children}
            <Webline type="light">
                <Footer simple />
            </Webline>
        </div>
    );
};

/* @component */
export default ErrorLayout;
