import { TransportType } from 'types/transport';
import { StateCreator } from 'zustand';

export type TransportAndPaymentSlice = {
    openedTransportWithPickupPoints: TransportType | null;
    changeTransportFetching: boolean;
    selectedPaymentInvalid: boolean;

    updateTransportAndPaymentState: (value: Partial<TransportAndPaymentSlice>) => void;
};

export const createTransportAndPaymentSlice: StateCreator<TransportAndPaymentSlice> = (set) => ({
    openedTransportWithPickupPoints: null,
    changeTransportFetching: false,
    selectedPaymentInvalid: false,

    updateTransportAndPaymentState: (value) => {
        set(value);
    },
});
