import { getUrlQueriesWithoutDynamicPageQueries } from 'helpers/parsing/urlParsing';
import { INTERNAL_QUERY_PARAMETERS } from 'helpers/queryParams/queryParamNames';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { UrlQueries } from 'hooks/useQueryParams';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getUrlWithoutGetParameters } from 'utils/getUrlWithoutGetParameters';

export const Canonical: FC = () => {
    const { url } = useDomainConfig();
    const router = useRouter();
    const urlOverwrite = useMemo<string | null>(() => {
        const newQueryOverwrite: Record<string, string> = {};
        const queryWithoutAllParameter = getUrlQueriesWithoutDynamicPageQueries(router.query);

        for (const queryParam in queryWithoutAllParameter) {
            if ((INTERNAL_QUERY_PARAMETERS as string[]).includes(queryParam)) {
                const queryParamValue = queryWithoutAllParameter[queryParam as keyof UrlQueries]?.toString();

                if (queryParamValue) {
                    newQueryOverwrite[queryParam] = queryParamValue;
                }
            }
        }

        if (JSON.stringify(newQueryOverwrite) === JSON.stringify(queryWithoutAllParameter)) {
            return null;
        }

        const urlWithoutTrailingSlash = url.charAt(url.length - 1) === '/' ? url.slice(0, url.length - 1) : url;
        const queryParams = new URLSearchParams(newQueryOverwrite).toString();

        if (queryParams.length === 0) {
            return `${urlWithoutTrailingSlash}${getUrlWithoutGetParameters(router.asPath)}`;
        }

        return `${urlWithoutTrailingSlash}${getUrlWithoutGetParameters(router.asPath)}?${queryParams}`;
    }, [router, url]);

    if (urlOverwrite === null) {
        return null;
    }

    return (
        <Head>
            <link rel="canonical" href={urlOverwrite} />
        </Head>
    );
};
