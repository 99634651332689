import { SLUG_TYPE_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { useDomainConfig } from 'hooks/useDomainConfig';
// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link';
import { ComponentPropsWithoutRef, ReactElement } from 'react';
import { useSessionStore } from 'store/useSessionStore';
import { FriendlyPagesDestinations, FriendlyPagesTypes, FriendlyPagesTypesKeys } from 'types/friendlyUrl';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

type ExtendedNextLinkProps = {
    type: FriendlyPagesTypesKeys | 'static' | 'static-presumed-category';
    queryParams?: Record<string, string>;
    shouldRedirectOnClick?: boolean;
    children?: ReactElement | string | number;
} & Omit<ComponentPropsWithoutRef<'a'>, keyof LinkProps> &
    Omit<LinkProps, 'prefetch'>;

export const ExtendedNextLink: FC<ExtendedNextLinkProps> = ({
    children,
    href,
    type,
    queryParams,
    onClick,
    as,
    shouldRedirectOnClick = true,
    ...props
}) => {
    const { updateGeneralState } = useSessionStore((s) => s);
    const domainConfig = useDomainConfig();
    const [storesUrl, cartUrl, transportAndPaymentUrl] = getInternationalizedStaticUrls(
        ['/stores', '/cart', '/order/transport-and-payment'],
        domainConfig.url,
    );
    const isStatic = type !== 'static' && type !== 'static-presumed-category';

    return (
        <NextLink
            as={isStatic ? href : as}
            prefetch={false}
            onClick={(e) => {
                onClick?.(e);

                if (!shouldRedirectOnClick) {
                    e.preventDefault();

                    return;
                }
                if (type === 'static') {
                    if (href === '/') {
                        updateGeneralState({ destinationOfRedirect: 'homepage' });
                        updateGeneralState({ isPageLoading: true });
                    }
                    if (href === storesUrl) {
                        updateGeneralState({ destinationOfRedirect: 'stores' });
                        updateGeneralState({ isPageLoading: true });
                    }
                    if (href === transportAndPaymentUrl) {
                        updateGeneralState({ destinationOfRedirect: 'transport-and-payment' });
                        updateGeneralState({ isPageLoading: true });
                    }
                    if (href === cartUrl) {
                        updateGeneralState({ destinationOfRedirect: 'cart' });
                        updateGeneralState({ isPageLoading: true });
                    }
                } else if (type === 'static-presumed-category') {
                    updateGeneralState({ destinationOfRedirect: 'category' });
                    updateGeneralState({ isPageLoading: true });
                } else {
                    updateGeneralState({ destinationOfRedirect: type });
                    updateGeneralState({ isPageLoading: true });
                }
            }}
            href={
                isStatic
                    ? {
                          pathname: FriendlyPagesDestinations[type],
                          query: { [SLUG_TYPE_QUERY_PARAMETER_NAME]: FriendlyPagesTypes[type], ...queryParams },
                      }
                    : href
            }
            {...props}
        >
            {children}
        </NextLink>
    );
};
