import ArticleResults from './Result/ArticleResults';
import CategoryResults from './Result/CategoryResults';
import ProductResults from './Result/ProductResult/ProductResults';
import Image from 'components/Basic/Image/Image';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { twJoin } from 'tailwind-merge';
import { AutocompleteSearchType } from 'types/search';

export const AUTOCOMPLETE_PRODUCT_LIMIT = 8 as const;
export const AUTOCOMPLETE_CATEGORY_LIMIT = 5 as const;
export const AUTOCOMPLETE_ARTICLE_LIMIT = 5 as const;

type AutocompleteProps = {
    autocompleteSearchResults: AutocompleteSearchType | undefined;
    isAutocompleteActive: boolean;
    isPanelDockedFromLeft?: boolean;
    autocompleteSearchQueryValue: string;
    fetching?: boolean;
};

const Autocomplete: FC<AutocompleteProps> = ({
    autocompleteSearchResults,
    isAutocompleteActive,
    isPanelDockedFromLeft,
    autocompleteSearchQueryValue,
}) => {
    const testIdentifier = 'layout-header-search-autocomplete';
    const t = useTypedTranslationFunction();

    return (
        <div
            className={twJoin(
                'lg:pointer-events-none lg:relative lg:z-aboveMenu lg:w-full lg:origin-[center_top] lg:scale-y-90 lg:opacity-0 lg:transition-all',
                'vl:min-w-[320px] vl:max-w-[576px]',
                'xl:max-w-[800px]',
                'xxl:max-w-[960px]',
                isAutocompleteActive && 'lg:pointer-events-auto lg:scale-y-100 lg:opacity-100',
            )}
            data-testid={testIdentifier}
        >
            <div
                className={twJoin(
                    'absolute left-0 right-0 z-aboveMenu overflow-hidden bg-white px-6',
                    'vl:right-auto vl:top-1.5 vl:rounded-md vl:px-5',
                    'xl:flex xl:px-0',
                    'notVl:pointer-events-none notVl:origin-[center_top] notVl:scale-y-90 notVl:opacity-0 notVl:transition-all',
                    isPanelDockedFromLeft && 'xl:-translate-x-[320px]',
                    isAutocompleteActive && 'notVl:pointer-events-auto notVl:scale-y-100 notVl:opacity-100',
                )}
            >
                {(() => {
                    if (autocompleteSearchResults === undefined) {
                        return null;
                    }

                    const productsCount = autocompleteSearchResults.productsSearch.totalCount;
                    const categoriesCount = autocompleteSearchResults.categoriesSearch.totalCount;
                    const articlesCount = autocompleteSearchResults.articlesSearch.length;

                    if (productsCount === 0 && categoriesCount === 0 && articlesCount === 0) {
                        return (
                            <div className="flex min-w-[240px] items-center py-5 vl:px-6">
                                <Image width={24} height={24} src="/icons/warning.png" alt={t('warning')} />
                                <span className="flex-1 pl-4 text-small">
                                    {t('Could not find any results for the given query.')}
                                </span>
                            </div>
                        );
                    }

                    return (
                        <>
                            {productsCount > 0 && (
                                <ProductResults
                                    products={autocompleteSearchResults.productsSearch.products}
                                    autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                                />
                            )}
                            {(categoriesCount > 0 || articlesCount > 0) && (
                                <div className="xl:not-first:border-t-none py-5 not-first:border-t not-first:border-grayMidDarker vl:order-1 vl:pt-4 xl:w-[320px] xl:pr-9 xl:pl-5 xl:not-first:bg-grayLighter">
                                    {categoriesCount > 0 && (
                                        <CategoryResults
                                            categories={autocompleteSearchResults.categoriesSearch.categories}
                                            autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                                        />
                                    )}
                                    {articlesCount > 0 && (
                                        <ArticleResults
                                            articles={[
                                                ...autocompleteSearchResults.articlesSearch.filter((i) => i.image),
                                                ...autocompleteSearchResults.articlesSearch.filter((i) => !i.image),
                                            ]}
                                            autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    );
                })()}
            </div>
        </div>
    );
};

export default Autocomplete;
