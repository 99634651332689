import { LoyaltyClubPointsWithPriceType, PriceType } from './price';
import { PromoCodeType } from './promoCode';
import { OrderDetailFragmentApi, OrderDocumentDataApi } from 'graphql/generated';
import { PageInfoType } from 'types/pageInfo';

export enum OrderTypeEnum {
    order = 'e',
    reservation = 'r',
    receipt = 's',
}

export type OrderDocumentType = Pick<OrderDocumentDataApi, 'dmsId' | 'type'>;

export type ListedOrderType = {
    uuid: string;
    number: string;
    creationDate: string;
    status: string | null;
    items: {
        quantity: number;
    };
    transport: {
        name: string;
        image: string | null;
    } | null;
    isPaid: boolean | null;
    isPaymentStatusHiddenFromCustomer: boolean;
    payment: string | null;
    paymentType: string | null;
    totalPrice: PriceType;
    lastTransactionStatus: string | null;
    isReservation: boolean;
    pickupPlaceName: string | null;
    expirationDate?: string | null;
    loyaltyClubPoints: number | null;
    loyaltyClubPointsObtained: LoyaltyClubPointsWithPriceType;
    receiptNumber: string | null;
    apiTransType: OrderTypeEnum;
    transportName: string;
    paymentName: string;
    isStatusCancelled: boolean;
};

export type ListedOrderConnectionType = {
    orders: ListedOrderType[];
    totalCount: number;
    pageInfo: PageInfoType;
};

export type OrderDetailItemColorVariantType = {
    name: string;
    value: string | null;
};

export type OrderDetailItemType = {
    name: string;
    unitPrice: PriceType;
    totalPrice: PriceType;
    vatRate: string;
    quantity: number;
    unit: string;
    image: string | null;
    type: string;
    colorVariant: OrderDetailItemColorVariantType | null;
    isRoundingItem: boolean;
    productCatnum: string | null;
    productUuid: string | null;
    productId: number | null;
};

export type OrderDetailType = {
    uuid: string;
    number: string;
    creationDate: string;
    isReservation: boolean;
    pickupPlaceName: string | null;
    isPaid: boolean | null;
    isPaymentStatusHiddenFromCustomer: boolean;
    status: string | null;
    firstName: string;
    lastName: string;
    email: string;
    telephone: string | null;
    companyName: string;
    companyNumber: string;
    companyTaxNumber: string;
    companyTaxIdNumber: string;
    street: string | null;
    city: string | null;
    postcode: string | null;
    country: string | null;
    differentDeliveryAddress: boolean;
    deliveryFirstName: string;
    deliveryLastName: string;
    deliveryCompanyName: string;
    deliveryTelephone: string;
    deliveryStreet: string;
    deliveryCity: string;
    deliveryPostcode: string;
    deliveryCountry: string;
    note: string;
    urlHash: string;
    promoCode: string;
    trackingNumber: string | null;
    trackingUrl: string | null;
    items: OrderDetailItemType[];
    transport: { name: string; price: PriceType } | null;
    payment: { name: string; price: PriceType; type: string } | null;
    paymentType: string | null;
    paymentInstructions: string;
    loyaltyClubDiscount: number | null;
    lastTransactionStatus: string | null;
    totalPrice: PriceType;
    loyaltyClubPoints: number | null;
    loyaltyClubPointsObtained: LoyaltyClubPointsWithPriceType;
    promoCodes: PromoCodeType[];
    pickupPlaceTelephone: string | null;
    pickupPlaceEmail: string | null;
    expirationDate?: string | null;
    receiptNumber: string | null;
    fiscalCode1: string | null;
    fiscalCode2: string | null;
    fiscalizationDate: string | null;
    apiTransType: OrderTypeEnum;
    paymentName: string;
    transportName: string;
    orderDocuments: OrderDocumentType[];
    roundingPrice: PriceType | null;
    isStatusCancelled: boolean;
};

export const isMappedOrder = (order?: OrderDetailFragmentApi | OrderDetailType): order is OrderDetailType => {
    return !(order as OrderDetailFragmentApi | undefined)?.country?.code;
};
