import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

type OrderStepsWrapperStyledProps = HTMLAttributes<HTMLDivElement> & {
    $tightTopMargin?: boolean;
    $negativeMargin: boolean;
};

type OrderStepsListItemLinkStyledProps = HTMLAttributes<HTMLUListElement> & {
    $active?: boolean;
    $disabled?: boolean;
};

export const OrderStepsWrapperStyled = styled.div<OrderStepsWrapperStyledProps>(
    ({ theme, $tightTopMargin, $negativeMargin }) => css`
        margin: ${$negativeMargin ? '30px -20px' : '20px 0 10px'};

        overflow-x: hidden;

        @media ${theme.mediaQueries.queryVl} {
            margin: ${$tightTopMargin ? '24px' : '40px'} 0 20px;
            ${$negativeMargin &&
            css`
                margin: 20px 0 30px;
            `}
        }
    `,
);

export const OrderStepsListStyled = styled.ul`
    display: flex;
    justify-content: center;
    padding: 0;

    list-style: none;
`;

export const OrderStepsListItemStyled = styled.li(
    ({ theme }) => css`
        width: 100%;

        & + * {
            margin-left: 5px;
        }

        &:first-child a {
            border-left-style: none;
        }

        &:last-child a {
            border-right-style: none;
        }

        @media ${theme.mediaQueries.queryVl} {
            width: auto;

            & + * {
                margin-left: 10px;
            }

            &:first-child a {
                border-left-style: solid;
            }

            &:last-child a {
                border-right-style: solid;
            }
        }
    `,
);

export const OrderStepsListItemLinkStyled = styled(ExtendedNextLink)<OrderStepsListItemLinkStyledProps>(
    ({ theme, $active, $disabled }) => css`
        align-items: center;
        display: flex;
        height: 45px;
        justify-content: center;
        padding: 0 8px;
        text-align: left;

        background-color: ${$disabled && theme.color.grayLighter};
        border: 2px solid ${$active ? theme.color.green : $disabled ? 'transparent' : theme.color.grayDarker};
        color: ${theme.color.primary};
        cursor: default;
        font-size: ${theme.fontSize.small};
        font-weight: 700;
        letter-spacing: 0.035em;
        line-height: 1.2;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &[href] {
            cursor: pointer;
        }

        svg {
            margin-right: 10px;
            flex-shrink: 0;
        }

        @media (min-width: 375px) {
            padding: 0 15px;
        }

        @media ${theme.mediaQueries.queryVl} {
            padding: 0 45px;

            border-radius: 999px;
        }
    `,
);
