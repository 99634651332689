import { getFirstImage } from 'connectors/image/Image';
import { mapPayment } from 'connectors/payments/Payment';
import { mapPriceData } from 'connectors/price/Prices';
import { mapPickupPointsApiData } from 'connectors/transports/pickupPoint/PickupPoint';
import { TransportWithAvailablePaymentsAndStoresFragmentApi, useTransportsQueryApi } from 'graphql/generated';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useMemo } from 'react';
import { MappedTransportType } from 'types/transport';

export const useTransports = (cartUuid: string | null): { data: MappedTransportType[]; fetching: boolean } => {
    const { currencyCode } = useDomainConfig();
    const [result] = useTransportsQueryApi({ variables: { cartUuid } });
    const transportsApiData = result.data?.transports;

    return useMemo(() => {
        if (transportsApiData !== undefined) {
            return { data: mapTransports(transportsApiData, currencyCode), fetching: result.fetching };
        }

        return { data: [], fetching: result.fetching };
    }, [currencyCode, transportsApiData, result.fetching]);
};

export const mapTransport = (
    apiData: TransportWithAvailablePaymentsAndStoresFragmentApi,
    currencyCode: string,
): MappedTransportType => {
    return {
        ...apiData,
        description: apiData.description !== null ? apiData.description : '',
        instruction: apiData.instruction !== null ? apiData.instruction : '',
        image: getFirstImage(apiData.images),
        price: mapPriceData(apiData.price, currencyCode),
        payments: apiData.payments.map((payment) => mapPayment(payment, currencyCode)),
        stores: apiData.stores !== null ? mapPickupPointsApiData(apiData.stores) : [],
    };
};

const mapTransports = (
    apiData: TransportWithAvailablePaymentsAndStoresFragmentApi[],
    currencyCode: string,
): MappedTransportType[] => {
    const mappedTransports: MappedTransportType[] = [];
    for (const transport of apiData) {
        const mappedTransport = mapTransport(transport, currencyCode);
        if (mappedTransport.payments.length > 0) {
            mappedTransports.push(mappedTransport);
        }
    }

    return mappedTransports;
};
