import {
    ClubBenefitsItemStyled,
    ClubBenefitsListStyled,
    ClubHeadingStyled,
    ClubIconStyled,
    ClubStyled,
    LoginColumnStyled,
    LoginContentStyled,
    MembershipBenefitsLink,
} from './Login.style';
import Image from 'components/Basic/Image/Image';
import Link from 'components/Basic/Link/Link';
import LoginForm from 'components/Blocks/LoginForm/LoginForm';
import { getTescomaClubCardImage } from 'helpers/getTescomaClubImage';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { SimpleCheckmarkIcon } from 'public/svg/IconsSvg';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export type LoginPopupContentProps = {
    isMobileView: boolean | undefined;
};

export const LoginContent: FC<LoginPopupContentProps> = ({ isMobileView }) => {
    const t = useTypedTranslationFunction();
    const { url, defaultLocale } = useDomainConfig();
    const [registrationUrl, tescomaClubUrl] = getInternationalizedStaticUrls(['/registration', '/tescoma-club'], url);

    const clubBenefitsListItems = t('Login popup benefits list').split('||');

    return (
        <LoginContentStyled>
            <LoginColumnStyled>
                <ClubStyled>
                    <ClubIconStyled>
                        <Image
                            src={getTescomaClubCardImage(defaultLocale)}
                            alt={t('Tescoma club')}
                            width={100}
                            height={63}
                            className="max-h-[63px] max-w-[100px]"
                        />
                    </ClubIconStyled>
                    {isMobileView === undefined || !isMobileView ? (
                        <ClubHeadingStyled>{t('Tescom Club Member Benefits')}</ClubHeadingStyled>
                    ) : (
                        <ClubHeadingStyled $weight={400}>{t('Become a member of the TESCOMA club')}</ClubHeadingStyled>
                    )}
                    {clubBenefitsListItems.length > 0 && (
                        <ClubBenefitsListStyled>
                            {clubBenefitsListItems.map((benefit, index) => (
                                <ClubBenefitsItemStyled key={index}>
                                    <SimpleCheckmarkIcon />
                                    <span dangerouslySetInnerHTML={{ __html: benefit }}></span>
                                </ClubBenefitsItemStyled>
                            ))}
                        </ClubBenefitsListStyled>
                    )}
                    {isMobileView !== undefined && (
                        <MembershipBenefitsLink href={tescomaClubUrl}>
                            {t('What are the benefits of membership')}
                        </MembershipBenefitsLink>
                    )}
                    <Link isButton href={registrationUrl} size="small">
                        {t('Create account')}
                    </Link>
                </ClubStyled>
            </LoginColumnStyled>
            <LoginColumnStyled>
                <LoginForm />
            </LoginColumnStyled>
        </LoginContentStyled>
    );
};
