import { ListedStoreConnectionFragmentApi, ListedStoreFragmentApi } from 'graphql/generated';
import { PickupPointType } from 'types/pickupPoint';

const mapPickupPointApiData = (pickupPoint: ListedStoreFragmentApi): PickupPointType => {
    return {
        ...pickupPoint,
        identifier: pickupPoint.uuid,
        street: pickupPoint.street !== null ? pickupPoint.street : '',
        city: pickupPoint.city !== null ? pickupPoint.city : '',
        postcode: pickupPoint.postcode !== null ? pickupPoint.postcode : '',
        country: pickupPoint.country !== null ? pickupPoint.country : { code: '', name: '' },
        description: pickupPoint.description !== null ? pickupPoint.description : '',
        openingHours: pickupPoint.openingHours,
        disabled: pickupPoint.disabled,
        reservationDisabled: pickupPoint.reservationDisabled,
        locationLatitude: pickupPoint.locationLatitude,
        locationLongitude: pickupPoint.locationLongitude,
    };
};

export const mapPickupPointsApiData = (storesConnectionApi: ListedStoreConnectionFragmentApi): PickupPointType[] => {
    if (storesConnectionApi.edges === null) {
        return [];
    }

    const mappedStores = [] as PickupPointType[];
    for (const edge of storesConnectionApi.edges) {
        if (edge?.node !== undefined && edge.node !== null) {
            mappedStores.push(mapPickupPointApiData(edge.node));
        }
    }

    return mappedStores;
};
