import Script from 'next/script';
import { getDomainConfig } from 'utils/Domain/Domain';

const parseGtmUrlForDomain = (domainId: number): string => {
    let url = 'https://www.googletagmanager.com';

    switch (domainId) {
        case 1: {
            url = 'https://sgtm.tescoma.cz';
            break;
        }
        case 3: {
            url = 'https://sgtm.tescoma.sk';
            break;
        }
        case 5: {
            url = 'https://sgtm.tescoma.pl';
            break;
        }
        case 7: {
            url = 'https://sgtm.tescoma.hu';
            break;
        }
        case 9: {
            url = 'https://sgtm.tescoma.de';
            break;
        }
        case 11: {
            url = 'https://sgtm.tescoma.es';
            break;
        }
        case 12: {
            url = 'https://sgtm.tescoma.pt';
            break;
        }
        case 13: {
            url = 'https://sgtm.tescoma.uk';
            break;
        }
        case 14: {
            url = 'https://sgtm.tescoma.it';
            break;
        }
    }

    return url;
};

const getGtmHeadScript = (): JSX.Element | null => {
    if (typeof window === 'undefined') {
        return null;
    }

    const domainConfig = getDomainConfig(window.location.host);
    const GTM_ID = domainConfig.gtmId;
    if (typeof GTM_ID === 'undefined' || GTM_ID.length === 0) {
        return null;
    }

    const url = parseGtmUrlForDomain(domainConfig.domainId);

    return (
        <Script
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                '${url}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');
            `,
            }}
        />
    );
};

export default getGtmHeadScript;
