import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { getFirstImage } from 'connectors/image/Image';
import { NavigationSubCategoriesApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { memo } from 'react';
import twMerge from 'utils/twMerge';

type NavigationCategoryType = NonNullable<NavigationSubCategoriesApi['category']>;

type SubCategoryProps = {
    item: NavigationCategoryType['children'][number];
    className?: string;
};

const SubCategory = memo<SubCategoryProps>(({ item, className }) => {
    const t = useTypedTranslationFunction();

    const itemChildren = item.children;

    return (
        <li className={twMerge('relative min-h-[108px] pb-7 pl-[100px] notXl:pl-[80px]', className)}>
            <div className="absolute top-0 left-0 flex h-20 w-20 items-center justify-center notXl:h-16 notXl:w-16">
                <Image
                    className="max-h-full max-w-full mix-blend-multiply"
                    src={getFirstImage(item.image)}
                    width="80"
                    height="80"
                    alt={item.name}
                />
            </div>
            <div>
                <ExtendedNextLink
                    type={item.link ? 'static-presumed-category' : 'category'}
                    href={item.link || item.slug}
                    className="text-description font-medium leading-6 text-primary no-underline hover:underline"
                >
                    {item.name}
                </ExtendedNextLink>
            </div>
            {itemChildren.length > 0 && (
                <ul className="mt-2 text-[15px] tracking-wider">
                    {itemChildren.slice(0, 3).map((childItem, index) => (
                        <li key={index} className="py-0.5">
                            <ExtendedNextLink
                                type={childItem.link ? 'static-presumed-category' : 'category'}
                                href={childItem.link || childItem.slug}
                                className="text-primary no-underline hover:underline"
                            >
                                {childItem.name}
                            </ExtendedNextLink>
                        </li>
                    ))}
                    {itemChildren.length > 3 && (
                        <li>
                            <ExtendedNextLink type="category" href={item.slug} className="text-primary underline">
                                {t('more categories')}
                            </ExtendedNextLink>
                        </li>
                    )}
                </ul>
            )}
        </li>
    );
});

SubCategory.displayName = 'SubCategory';
export default SubCategory;
