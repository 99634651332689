import { twJoin } from 'tailwind-merge';

/**
 * Global component that serves as a wrapper
 * for rendering the text/HTML users can insert into the WYSIWYG editor
 */
type UserTextProps = {
    htmlContent: string;
    'data-testid'?: string;
    isGrapesJs?: boolean;
};

export const UserText: FC<UserTextProps> = ({ isGrapesJs, htmlContent, ...props }) => {
    const attr = {
        'data-testid': props['data-testid'],
    };

    return (
        <section
            className={twJoin('user-text', isGrapesJs && 'grapesjs-text')}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            {...attr}
        />
    );
};

export default UserText;
