import { desktopFirstSizes, mobileFirstSizes } from 'components/Theme/mediaQueries';
import { OrderDetailFragmentApi } from 'graphql/generated';
import { canUseDom } from 'helpers/canUseDom';
import { CurrentCustomerType } from 'types/customer';
import { GtmAddressObject, GtmDeviceTypes as GtmDeviceType } from 'types/gtm';
import { isMappedOrder, OrderDetailType } from 'types/orders';
import { v4 as uuidV4 } from 'uuid';

export const getGtmDeviceType = (): GtmDeviceType => {
    if (typeof navigator === 'undefined') {
        return 'unknown';
    }
    if (canUseDom()) {
        if (window.innerWidth <= desktopFirstSizes.mobile) {
            return 'mobile';
        }

        return window.innerWidth >= mobileFirstSizes.vl ? 'desktop' : 'tablet';
    }

    return 'unknown';
};

export const getRandomPageId = (): string => uuidV4();

export const getAddressObjectForGtmUser = (
    currentCustomer: CurrentCustomerType | null | undefined,
    order?: OrderDetailFragmentApi | OrderDetailType,
): GtmAddressObject => {
    let orderCountry;
    if (!currentCustomer?.country.code) {
        orderCountry = isMappedOrder(order)
            ? order.country || order.deliveryCountry
            : order?.country?.code || order?.deliveryCountry?.code;
    }

    return {
        street: currentCustomer?.street || order?.street || order?.deliveryStreet || '',
        city: currentCustomer?.city || order?.city || order?.deliveryCity || '',
        postcode: currentCustomer?.postcode || order?.postcode || order?.deliveryPostcode || '',
        country: currentCustomer?.country.code || orderCountry || '',
    };
};
