import { autoUpdate, flip, offset, Placement, shift, useFloating, useInteractions, useRole } from '@floating-ui/react';
import { cloneElement, useMemo } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { twJoin } from 'tailwind-merge';

const VARIANTS = {
    default: 'bg-black',
    error: 'bg-red',
};

type PopoverProps = {
    label?: string | null;
    variant?: keyof typeof VARIANTS;
    placement?: Placement;
    children: JSX.Element;
};

export const Popover: FC<PopoverProps> = ({ children, label, placement = 'top', variant = 'default' }) => {
    const { x, y, reference, floating, strategy, context } = useFloating({
        placement,
        middleware: [offset(5), flip(), shift({ padding: 8 })],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([useRole(context, { role: 'tooltip' })]);

    const ref = useMemo(() => mergeRefs([reference, (children as any).ref]), [reference, children]);

    return (
        <>
            {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
            {label && (
                <div
                    {...getFloatingProps({
                        ref: floating,
                        className: twJoin(VARIANTS[variant], 'tooltip p-2 block opacity-75 rounded-md text-white'),
                        style: {
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                        },
                    })}
                >
                    {label}
                </div>
            )}
        </>
    );
};
