import FooterIcons from 'components/Layout/Footer/FooterIcons/FooterIcons';
import FooterMenu from 'components/Layout/Footer/FooterMenu/FooterMenu';
import FooterSocialMedia from 'components/Layout/Footer/FooterSocialMedia/FooterSocialMedia';
import { useFooterSetting } from 'connectors/footerSetting/footerSetting';
import { memo } from 'react';

export const FooterContent = memo(() => {
    const footerSetting = useFooterSetting();

    if (footerSetting === null) {
        return null;
    }

    return (
        <>
            <FooterSocialMedia socialSection={footerSetting.socialSection} />
            <FooterIcons iconSection={footerSetting.iconsSection} />
            <FooterMenu menuSection={footerSetting.menuSection} />
        </>
    );
});

FooterContent.displayName = 'FooterContent';
