import { showErrorMessage } from 'components/Helpers/Toasts';
import { getUserFriendlyErrors } from 'connectors/lib/friendlyErrorMessageParser';
import { CartFragmentApi, useChangePaymentInCartMutationApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useLatest } from 'hooks/ui/useLatest';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCallback } from 'react';
import { usePersistStore } from 'store/usePersistStore';
import { onPaymentChangeGtmEventHandler } from 'utils/Gtm/EventHandlers';
import { useGtmCartEventInfo } from 'utils/Gtm/Gtm';

export type ChangePaymentHandler = (
    newPaymentUuid: string | null,
    newGoPayBankSwift: string | null,
) => Promise<CartFragmentApi | undefined | null>;

export const useChangePaymentMutation = (): [ChangePaymentHandler, boolean] => {
    const [{ fetching }, changePaymentInCart] = useChangePaymentInCartMutationApi();
    const { cartUuid, defaultStoreUuid } = usePersistStore((s) => s);
    const { currencyCode } = useDomainConfig();
    const t = useTypedTranslationFunction();
    const gtmCartEventInfo = useGtmCartEventInfo();

    const gtmCart = useLatest(gtmCartEventInfo.cart);

    const changePaymentHandler = useCallback<ChangePaymentHandler>(
        async (newPaymentUuid, newGoPayBankSwift) => {
            const changePaymentResult = await changePaymentInCart(
                {
                    input: { paymentUuid: newPaymentUuid, paymentGoPayBankSwift: newGoPayBankSwift, cartUuid },
                    defaultStoreUuid,
                    selectedStoreUuid: null,
                },
                { additionalTypenames: ['dedup'] },
            );

            // EXTEND PAYMENT MODIFICATIONS HERE

            if (changePaymentResult.error !== undefined) {
                const { userError } = getUserFriendlyErrors(changePaymentResult.error, t);
                if (userError?.validation?.payment !== undefined) {
                    showErrorMessage(userError.validation.payment.message, 'transport pay');
                }
                if (userError?.validation?.goPaySwift !== undefined) {
                    showErrorMessage(userError.validation.goPaySwift.message, 'transport pay');
                }

                return null;
            }

            onPaymentChangeGtmEventHandler(
                gtmCart.current,
                changePaymentResult.data?.ChangePaymentInCart.payment ?? null,
                currencyCode,
            );

            return changePaymentResult.data?.ChangePaymentInCart;
        },
        [cartUuid, changePaymentInCart, currencyCode, defaultStoreUuid, gtmCart, t],
    );

    return [changePaymentHandler, fetching];
};
