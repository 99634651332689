import { FriendlyPagesTypesKeys } from 'types/friendlyUrl';
import { StateCreator } from 'zustand';

type LoginLoadingStatus = 'not-loading' | 'loading' | 'loading-with-cart-modifications';

export type GeneralSliceType = {
    loginLoading: LoginLoadingStatus;
    lastVisitedSlug: string | null;
    lastVisitedProductsCatNumbers: string[];
    isPageLoading: boolean;
    isFetchingDataForPage: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    destinationOfRedirect: FriendlyPagesTypesKeys | (string & {});

    updateLastVisitedSlug: (value: string) => void;
    updateGeneralState: (value: Partial<GeneralSliceType>) => void;
};

export const createGeneralSlice: StateCreator<GeneralSliceType> = (set) => ({
    loginLoading: 'not-loading',
    lastVisitedSlug: null,
    isPageLoading: false,
    isFetchingDataForPage: false,
    destinationOfRedirect: '',
    lastVisitedProductsCatNumbers: [],

    updateGeneralState: (value) => {
        set(value);
    },
    updateLastVisitedSlug: (value) => {
        set({ lastVisitedSlug: value });
    },
});
