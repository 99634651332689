import Contact from './Contact/Contact';
import { HeaderBottom } from './HeaderBottom/HeaderBottom';
import HeaderLinks from './HeaderTop/HeaderLinks/HeaderLinks';
import Logo from './HeaderTop/Logo/Logo';
import { memo } from 'react';

const TEST_IDENTIFIER = 'layout-header';

type Props = {
    simple?: boolean;
};

const Header = memo<Props>(({ simple }) => {
    return (
        <div className="vl:menu-gradient relative z-menu mb-5 bg-primary" data-testid={TEST_IDENTIFIER}>
            <div className="flex w-full flex-wrap justify-between vl:px-5 xl:mx-auto xl:w-layout">
                <div className="flex h-11 flex-1 items-center justify-between vl:h-16 vl:shrink-0 vl:grow vl:basis-full">
                    <Logo />
                    {simple ? <Contact textColor="text-white" /> : <HeaderLinks />}
                </div>
                {!simple && <HeaderBottom />}
            </div>
        </div>
    );
});

Header.displayName = 'Header';

/* @component */
export default Header;
