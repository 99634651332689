import ResultTitle from './ResultTitle';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { AUTOCOMPLETE_ARTICLE_LIMIT } from 'components/Layout/Header/HeaderBottom/Search/Autocomplete/Autocomplete';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import React, { useCallback } from 'react';
import { twJoin } from 'tailwind-merge';
import { SimpleArticleInterfaceType } from 'types/articleInterface';
import { onClickSuggestResultGtmEventHandler } from 'utils/Gtm/EventHandlers';

type ProductResultProps = {
    articles: SimpleArticleInterfaceType[];
    autocompleteSearchQueryValue: string;
};

const TEST_IDENTIFIER = 'layout-header-search-autocomplete-input-articles';

const ArticleResults: FC<ProductResultProps> = ({ articles, autocompleteSearchQueryValue }) => {
    const t = useTypedTranslationFunction();

    const onArticleClickHandler = useCallback(
        (article: SimpleArticleInterfaceType) => () => {
            onClickSuggestResultGtmEventHandler(autocompleteSearchQueryValue, 'article', article.name);
        },
        [autocompleteSearchQueryValue],
    );

    return (
        <>
            <ResultTitle title={t('Blog')} />
            <ul className="list-none space-y-5 p-0 vl:space-y-7" data-testid={TEST_IDENTIFIER}>
                {articles.map(
                    (article, index) =>
                        index < AUTOCOMPLETE_ARTICLE_LIMIT && (
                            <li
                                className="group relative flex items-start"
                                key={article.slug}
                                data-testid={`${TEST_IDENTIFIER}-${index}`}
                            >
                                {article.image && (
                                    <div className="relative mr-4 h-10 w-10 flex-shrink-0 before:block before:pb-[100%] before:content-[''] vl:mr-5 vl:h-16 vl:w-16">
                                        <Image
                                            alt={article.name}
                                            src={article.image}
                                            fill
                                            sizes="(min-width: 1024px) 64px, 40px"
                                        />
                                    </div>
                                )}
                                <div className={twJoin(article.image && '-mb-2')}>
                                    <div className="w-full text-[15px] font-medium leading-relaxed tracking-wide text-primary line-clamp-3 group-hover:underline vl:text-body vl:leading-normal">
                                        {article.name}
                                    </div>
                                    <ExtendedNextLink
                                        type={article.__typename === 'Article' ? 'article' : 'blogArticle'}
                                        href={article.slug}
                                        className="absolute inset-0"
                                        onClick={onArticleClickHandler(article)}
                                    >
                                        <span className="sr-only">{t('Read more')}</span>
                                    </ExtendedNextLink>
                                </div>
                            </li>
                        ),
                )}
            </ul>
        </>
    );
};

export default ArticleResults;
