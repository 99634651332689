import { Flag } from 'components/Basic/Flag/Flag';
import { SimpleFlagType } from 'types/flag';
import { ListedProductType } from 'types/product';

type ProductFlagsProps = {
    isProductDetail: boolean;
    product: ListedProductType;
    flags: SimpleFlagType[];
};

const ProductFlags: FC<ProductFlagsProps> = (props) => {
    const testIdentifier = 'blocks-product-flags-';

    if (props.flags.length === 0) {
        return null;
    }

    return (
        <>
            {props.flags.map((flag, key) =>
                flag.visible ? (
                    <Flag
                        backgroundColor={flag.rgbColor}
                        data-testid={testIdentifier + key}
                        fixedWidth={flag.isActionFlag}
                        key={flag.uuid}
                    >
                        {flag.isActionFlag && props.product.isInAction
                            ? `-${props.product.actionDiscountPercent} %`
                            : flag.name}
                    </Flag>
                ) : null,
            )}
        </>
    );
};

export default ProductFlags;
