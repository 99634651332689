import { mobileFirstSizes } from 'components/Theme/mediaQueries';
import { RefObject, useEffect } from 'react';

export function useMenuIconicContentOffset(
    userMenuLinkRef: RefObject<HTMLDivElement>,
    targetRef: RefObject<HTMLDivElement>,
): void {
    useEffect(() => {
        const userMenuLinkNode = userMenuLinkRef.current;
        const targetNode = targetRef.current;

        if (!userMenuLinkNode || !targetNode) {
            return undefined;
        }

        const handler = () => {
            if (window.innerWidth < mobileFirstSizes.vl) {
                const { top, height } = userMenuLinkNode.getBoundingClientRect();
                targetNode.setAttribute('style', `top: ${top + height}px`);
            } else {
                targetNode.removeAttribute('style');
            }
        };

        handler();
        window.addEventListener('resize', handler, { passive: true });
        window.addEventListener('scroll', handler, { passive: true });

        return () => {
            window.removeEventListener('resize', handler);
            window.removeEventListener('scroll', handler);
        };
    }, [userMenuLinkRef, targetRef]);
}
