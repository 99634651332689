import { AvailabilityFragmentApi, StoreAvailabilityFragmentApi } from 'graphql/generated';
import {
    AvailabilityStatusEnum,
    AvailabilityType,
    StoreAvailabilityType,
    StoreListAvailabilityStatusEnum,
} from 'types/availability';

const mapAvailabilityStatus = (availabilityStatus: string): AvailabilityStatusEnum => {
    switch (availabilityStatus) {
        case AvailabilityStatusEnum.inStock:
            return AvailabilityStatusEnum.inStock;
        case AvailabilityStatusEnum.outOfStock:
            return AvailabilityStatusEnum.outOfStock;
        case AvailabilityStatusEnum.centralStock:
            return AvailabilityStatusEnum.centralStock;
        case AvailabilityStatusEnum.partiallyInStock:
            return AvailabilityStatusEnum.partiallyInStock;
        default:
            throw new Error('Invalid availability status');
    }
};

export const mapAvailabilityData = (availabilityApiData: AvailabilityFragmentApi): AvailabilityType => {
    return {
        ...availabilityApiData,
        status: mapAvailabilityStatus(availabilityApiData.status),
    };
};

export const mapStoreAvailability = (storeAvailabilityApiData: StoreAvailabilityFragmentApi): StoreAvailabilityType => {
    return {
        ...storeAvailabilityApiData,
        storeReservationDisabled: storeAvailabilityApiData.store?.reservationDisabled,
        availabilityStatus: storeAvailabilityApiData.availabilityStatus as StoreListAvailabilityStatusEnum,
        quantity: storeAvailabilityApiData.quantity,
    };
};

export const mapStoreAvailabilities = (apiData: StoreAvailabilityFragmentApi[]): StoreAvailabilityType[] => {
    const mappedStoreAvailabilities = [];

    for (const storeAvailabilityApiData of apiData) {
        mappedStoreAvailabilities.push(mapStoreAvailability(storeAvailabilityApiData));
    }

    return mappedStoreAvailabilities;
};
