import mediaQueries from './mediaQueries';
import initialStyled, { ThemedStyledInterface } from 'styled-components';
import tinycolor from 'tinycolor2';

/* Main theme */
export const theme = {
    /* Colors */
    color: {
        primary: '#222',
        white: '#fff',
        blue: '#2d66f9',
        blueVeryLight: '#f7f9fc',
        black: '#000',
        green: '#008060',
        greenLight: '#82be59',
        greenVeryLight: '#cce5df',
        greenDark: '#176652',
        red: '#bd0d2e',
        redLight: '#f93339',
        redDark: '#892a41',
        orange: '#d8560d',
        orangeLight: '#ffb053',
        gray: '#ececec',
        grayVeryLight: '#fafbfb',
        grayLight: '#f7f7f7',
        grayLighter: '#f1f2f3',
        grayMidDarker: '#e5e5e5',
        grayDarker: '#8c9196',
        grayDark: '#6d7175',
        grayVeryDark: '#31363a',
        border: '#a6acb6',
    },

    /* Fonts */
    fontSize: {
        bigger: '18px',
        default: '16px',
        small: '13px',
        extraSmall: '12px',
        mobile: {
            bigger: '17px',
            default: '14px',
            small: '13px',
            extraSmall: '10px',
        },
    },

    /* Fonts family */
    fontFamily: {
        base: 'Roboto, Arial, Helvetica, sans-serif',
    },

    /* Line heights */
    lineHeight: {
        default: 1.3,
        paragraph: '32px',
    },

    /* Layouts */
    layout: {
        width: '1240px',
        fullHDWidth: '1560px',
    },

    /* Gaps */
    gap: {
        default: '20px',
        large: '80px',
        medium: '60px',
        small: '40px',
        mobile: {
            large: '60px',
            medium: '40px',
            small: '30px',
        },
    },

    /* Default border radius value */
    radius: {
        biggest: '13px',
        big: '11px',
        medium: '7px',
        small: '2px',
    },

    zIndex: {
        hidden: -1000,
        above: 1,
        productSlider: 480,
        stickyBar: 490,
        overlay: 500,
        menu: 501,
        aboveMenu: 750,
        consentPopup: 800,
        cart: 6000,
        aboveOverlay: 10001,
        maximum: 10100,
    },

    boxShadow: {
        gray: '0 0 30px rgba(0, 0, 15, 0.1)',
        grayDown: '0 7px 10px rgba(0, 0, 15, 0.1)',
        green: '0 0 0 4px rgba(0, 236, 177, 0.12)',
        subtle: '0 1px 1px rgba(0, 0, 0, 0.05)',
    },

    /* Buttons */
    button: {
        size: {
            default: {
                height: '50px',
                lineHeight: '20px',
                paddingVertical: '14px',
                paddingHorizontal: '38px',
                fontSize: '22px',
                mobile: {
                    fontSize: () => theme.fontSize.bigger,
                },
            },
            bigger: {
                height: '50px',
                lineHeight: '20px',
                paddingVertical: '14px',
                paddingHorizontal: '38px',
                fontSize: () => theme.fontSize.bigger,
            },
            small: {
                height: '40px',
                lineHeight: '20px',
                paddingVertical: '9px',
                paddingHorizontal: '32px',
                fontSize: () => theme.fontSize.default,
            },
            smallNarrow: {
                height: '40px',
                lineHeight: '20px',
                paddingVertical: '9px',
                paddingHorizontal: '14px',
                fontSize: () => theme.fontSize.default,
            },
            extraSmall: {
                height: '40px',
                lineHeight: '20px',
                paddingVertical: '9px',
                paddingHorizontal: '14px',
                fontSize: () => theme.fontSize.mobile.default,
            },
        },
        variant: {
            default: {
                color: () => theme.color.white,
                background: () => theme.color.primary,
                colorHover: () => theme.color.white,
                backgroundHover: () => tinycolor(theme.color.primary).lighten(10).toString(),
                colorDisabled: () => theme.color.white,
                backgroundDisabled: () => theme.color.border,
            },
            primary: {
                color: () => theme.color.white,
                background: () => theme.color.green,
                colorHover: () => theme.color.white,
                backgroundHover: () => tinycolor(theme.color.green).darken(10).toString(),
                colorDisabled: () => tinycolor(theme.color.primary).lighten(40).toString(),
                backgroundDisabled: () => tinycolor(theme.color.primary).lighten(76.5).toString(),
            },
            secondary: {
                color: () => theme.color.primary,
                background: () => theme.color.white,
                colorHover: () => theme.color.primary,
                backgroundHover: () => theme.color.white,
                colorDisabled: () => tinycolor(theme.color.primary).lighten(40).toString(),
                backgroundDisabled: () => tinycolor(theme.color.primary).lighten(76.5).toString(),
            },
            link: {
                color: () => theme.color.primary,
                background: 'transparent',
                colorHover: () => theme.color.primary,
                backgroundHover: 'transparent',
                colorDisabled: () => theme.color.grayDarker,
                backgroundDisabled: 'transparent',
            },
            plain: {
                color: () => theme.color.primary,
                background: 'transparent',
                colorHover: () => theme.color.primary,
                backgroundHover: 'transparent',
                colorDisabled: () => theme.color.grayDarker,
                backgroundDisabled: 'transparent',
            },
        },
    },

    /* Transition */
    transition: '0.2s cubic-bezier(.8, .20, .48, 1.0)',
    transitionEffect: 'cubic-bezier(.8, .20, .48, 1.0)',

    mediaQueries: { ...mediaQueries },
} as const;

export type Theme = typeof theme;

export const styled = initialStyled as ThemedStyledInterface<Theme>;
