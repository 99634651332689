import { theme } from 'components/Theme/main';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ThemeProvider } from 'styled-components';

/**
 * This global provider is used primary for styleguidist as wrapper.
 */
const ShopsysGlobalProvider: FC = ({ children }) => {
    return (
        <SkeletonTheme baseColor="#eeeeee" highlightColor="#e3e4ec" duration={1}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </SkeletonTheme>
    );
};

/* @component */
export default ShopsysGlobalProvider;
