import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Heading from 'components/Basic/Heading/Heading';
import Link from 'components/Basic/Link/Link';
import Overlay from 'components/Basic/Overlay/Overlay';
import Button from 'components/Forms/Button/Button';
import { Popup } from 'components/Layout/Popup/Popup';
import Webline from 'components/Layout/Webline/Webline';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import Trans from 'next-translate/Trans';
import React, { useMemo } from 'react';

const TEST_IDENTIFIER = 'blocks-userconsent-simplified';

type UserConsentSimplifiedProps = {
    cookiePolicyUrl: string;
    onAcceptAllCallback: () => void;
    onRejectAllCallback: () => void;
    onShowUserConsentPopup: () => void;
};

export const UserConsentSimplified: FC<UserConsentSimplifiedProps> = ({
    cookiePolicyUrl,
    onShowUserConsentPopup,
    onRejectAllCallback,
    onAcceptAllCallback,
}) => {
    const { domainId } = useDomainConfig();
    const t = useTypedTranslationFunction();
    const isPlLocale = useMemo(() => domainId === 5 || domainId === 6, [domainId]);

    return (
        <>
            {isPlLocale ? (
                <>
                    <div
                        className="fixed inset-0 top-auto z-[801] w-full bg-white py-4 leading-6 vl:py-5"
                        data-testid={TEST_IDENTIFIER}
                    >
                        <Webline>
                            <div className="vl:flex vl:justify-between">
                                <div>
                                    <Heading type="h5">{t('User consent preview title')}</Heading>
                                    <p>
                                        <Trans
                                            i18nKey="User consent preview text"
                                            components={{
                                                link: (
                                                    <ExtendedNextLink
                                                        type="static"
                                                        href={cookiePolicyUrl || '#'}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-primary hover:text-grayDark"
                                                    />
                                                ),
                                            }}
                                        />
                                    </p>
                                </div>
                                <div className="flex shrink-0 flex-col gap-2 pt-2 vl:ml-20 vl:w-60 vl:gap-5">
                                    <Button
                                        type="button"
                                        size="small"
                                        onClick={onShowUserConsentPopup}
                                        data-testid={TEST_IDENTIFIER + '-preferences'}
                                        className="uppercase shadow-md"
                                    >
                                        {t('Preferences')}
                                    </Button>
                                    <Button
                                        type="button"
                                        size="small"
                                        onClick={onRejectAllCallback}
                                        data-testid={TEST_IDENTIFIER + '-reject'}
                                        className="uppercase shadow-md"
                                    >
                                        {t('Reject all')}
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        onClick={onAcceptAllCallback}
                                        data-testid={TEST_IDENTIFIER + '-accept-all'}
                                        className="uppercase shadow-md"
                                    >
                                        <strong>{t('Accept all')}</strong>
                                    </Button>
                                </div>
                            </div>
                        </Webline>
                    </div>
                    <Overlay withWrapper={false} className="z-consentPopup" />
                </>
            ) : (
                <Popup
                    className="w-[600px] rounded-md py-10 px-5 text-center vl:w-[800px] vl:px-12"
                    contentClassName="flex flex-col gap-7 items-center sm:gap-12"
                    disablePortal
                    hideCloseButton
                >
                    <div className="h-full overflow-y-auto">
                        <div className="flex flex-col gap-5">
                            <Heading type="h3" className="mb-0">
                                {t('User consent preview title')}
                            </Heading>
                            <p className="mb-0">
                                <Trans
                                    i18nKey="User consent preview text"
                                    components={{
                                        link: (
                                            <Link
                                                className="inline"
                                                href={cookiePolicyUrl || '#'}
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 text-center sm:flex-row sm:gap-20">
                        <Button
                            type="button"
                            size="small"
                            onClick={onShowUserConsentPopup}
                            data-testid={TEST_IDENTIFIER + '-preferences'}
                        >
                            {t('Preferences')}
                        </Button>
                        <Button
                            type="button"
                            variant="primary"
                            onClick={onAcceptAllCallback}
                            data-testid={TEST_IDENTIFIER + '-accept-all'}
                        >
                            <strong>{t('Accept all')}</strong>
                        </Button>
                    </div>
                    <span>
                        <Trans
                            i18nKey="Cookie consent reject"
                            components={{
                                button: (
                                    <Button
                                        type="button"
                                        isLink
                                        onClick={onRejectAllCallback}
                                        data-testid={TEST_IDENTIFIER + '-reject'}
                                    />
                                ),
                            }}
                        />
                    </span>
                </Popup>
            )}
        </>
    );
};
