import { MenuItemProps } from './UserMenu';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { NotImplementedYetHandler } from 'components/Helpers/NotImplementedYet';
import { useAuth } from 'hooks/auth/useAuth';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { twJoin } from 'tailwind-merge';

type UserMenuDesktopProps = {
    menuItems: MenuItemProps[];
    isActive: boolean;
};

const TEST_IDENTIFIER = 'header-user-menu-sub';

const UserMenuDesktop: FC<UserMenuDesktopProps> = ({ menuItems, isActive }) => {
    const t = useTypedTranslationFunction();
    const [, [, logout]] = useAuth();
    const { user } = useCurrentUserData();

    return (
        <ul
            className={twJoin(
                'absolute top-full left-0 z-menu mt-2 block min-w-[150px] origin-top-right overflow-hidden rounded-md font-medium tracking-wide shadow-mobileSubMenu transition-all will-change-transform',
                isActive
                    ? 'pointer-events-auto scale-100 opacity-100'
                    : 'pointer-events-none scale-y-50 scale-x-75 opacity-0',
            )}
        >
            {menuItems
                .filter((menuItem) => menuItem.show)
                .map((menuItem, index) => {
                    if (menuItem.link === null) {
                        return (
                            <li
                                className="block border-t border-grayMidDarker first:border-t-0"
                                key={menuItem.title}
                                data-testid={`${TEST_IDENTIFIER}-${index}`}
                                onClick={NotImplementedYetHandler}
                            >
                                <a className="block bg-white px-4 py-3.5 text-primary no-underline">{menuItem.title}</a>
                            </li>
                        );
                    }

                    return (
                        <li
                            className="block border-t border-grayMidDarker first:border-t-0"
                            key={menuItem.title}
                            data-testid={`${TEST_IDENTIFIER}-${index}`}
                        >
                            <ExtendedNextLink
                                href={menuItem.link}
                                className="block bg-white px-4 py-3.5 text-primary no-underline"
                                type="static"
                            >
                                {menuItem.title}
                            </ExtendedNextLink>
                        </li>
                    );
                })}
            <li className="block border-t border-grayMidDarker first:border-t-0" data-testid={`${TEST_IDENTIFIER}-8`}>
                <a
                    className="block bg-grayLighter px-4 py-3.5 text-primary no-underline"
                    onClick={logout}
                    data-testid={TEST_IDENTIFIER}
                >
                    <span className="whitespace-nowrap">
                        {t('Logout')} {user?.firstName} {user?.lastName}
                    </span>
                </a>
            </li>
        </ul>
    );
};

export default UserMenuDesktop;
