import { EmptyCartWidgetStyled } from './EmptyCartWidget.style';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { UnamusedFaceEmojiIcon } from 'public/svg/IconsSvg';

export const EmptyCartWidget: FC = () => {
    const t = useTypedTranslationFunction();

    return (
        <EmptyCartWidgetStyled>
            <UnamusedFaceEmojiIcon className="h-24 w-24 text-grayDarker" />
            <p>{t('Your cart is empty')}</p>
        </EmptyCartWidgetStyled>
    );
};
