import {
    GdprActionTypeEnumApi,
    GdprConsentsQueryApi,
    GdprOrderTypeEnumApi,
    useGdprConsentsQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';

export const useGdprConsents = (
    action: GdprActionTypeEnumApi,
    orderType: GdprOrderTypeEnumApi | null,
): GdprConsentsQueryApi['gdprConsents'] | undefined => {
    const [{ data, error }] = useGdprConsentsQueryApi({
        variables: { action, orderType },
        requestPolicy: 'cache-and-network',
    });
    useQueryError(error);

    return data?.gdprConsents;
};
