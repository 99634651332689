import Script from 'next/script';
import { SupportChatIcon } from 'public/svg/IconsSvg';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';

const CHAT_SCRIPT_SRC = 'https://tescoma.servicedesk.net/Chat/ChatWidgetLoader/d3c8bd46-596d-4e7b-b05c-982c58a300b9';
const CHAT_ELEMENTS_ID = {
    appendedElementFromScript: 'RQEmbeddedChatWidget',
    chatIframe: 'RQChatFrame',
    buttonForOpeningChat: 'RQIconContainer',
};

export const SupportChat: FC = () => {
    const [isSupportChatScriptExecuting, setIsSupportChatScriptExecuting] = useState<boolean>(false);

    const clickOnChatButton = () => {
        const elementForOpeningChat = document.querySelector(
            `#${CHAT_ELEMENTS_ID.buttonForOpeningChat}`,
        ) as HTMLElement | null;

        if (elementForOpeningChat) {
            elementForOpeningChat.click();
        }
    };

    const onChatAppending = (appendedElement: HTMLElement) => {
        const chatIframe = appendedElement.querySelector(`#${CHAT_ELEMENTS_ID.chatIframe}`) as HTMLIFrameElement;

        chatIframe.onload = () => {
            clickOnChatButton();
        };
    };

    const handleButtonClick = () => {
        if (!isSupportChatScriptExecuting) {
            setIsSupportChatScriptExecuting(true);
        }

        clickOnChatButton();
    };

    return (
        <>
            <button
                className={twJoin('fixed bottom-2 left-2 z-maximum md:bottom-5 md:left-5')}
                onClick={handleButtonClick}
            >
                <SupportChatIcon className="h-[70px] w-[70px]" />
            </button>
            {isSupportChatScriptExecuting && (
                <Script
                    onLoad={() => {
                        const observer = new MutationObserver((mutations) => {
                            mutations.forEach((mutation) => {
                                const appendedElements = Array.from(mutation.addedNodes) as HTMLElement[];

                                appendedElements.forEach((element) => {
                                    if (element.id === CHAT_ELEMENTS_ID.appendedElementFromScript) {
                                        onChatAppending(element);
                                    }
                                });
                            });
                        });

                        observer.observe(document.body, {
                            childList: true,
                        });
                    }}
                    src={CHAT_SCRIPT_SRC}
                />
            )}
        </>
    );
};
