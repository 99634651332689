import { useSettings } from 'connectors/Settings/Settings';
import Head from 'next/head';

type SeoMetaProps = {
    title?: string | null;
    description?: string | null;
};

const SeoMeta: FC<SeoMetaProps> = ({ title, description }) => {
    const settings = useSettings();

    const titleFromApi = settings?.seo.title;
    const descriptionFromApi = settings?.seo.metaDescription;
    const suffixFromApi = settings?.seo.titleAddOn;

    const pageTitle = `${title ?? titleFromApi} ${suffixFromApi}`;

    return (
        <Head>
            <title>{pageTitle}</title>
            <meta name="description" content={description ?? descriptionFromApi} />
        </Head>
    );
};

export default SeoMeta;
