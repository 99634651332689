import { getLastDayFromPersonalPickupString } from 'helpers/getLastDayFromPersonalPickupString';
import { Translate } from 'next-translate';
import { AvailabilityStatusEnum, StoreListAvailabilityStatusEnum } from 'types/availability';
import { CartItemType, CartType } from 'types/cart';

export const hasSelectedStore = (cartItems: CartItemType[]): boolean => {
    return cartItems.every(({ product }) => product.defaultStoreAvailability || product.selectedStoreAvailability);
};

export const isStoreReservationDisabled = (cartItems: CartItemType[]): boolean | undefined => {
    return cartItems.map(({ product }) => product.defaultStoreAvailability?.storeReservationDisabled).shift();
};

export const areAllProductsInStockAtSelectedStore = (cartItems: CartItemType[]): boolean => {
    return cartItems.every(({ product, quantity }) => {
        return (
            (product.defaultStoreAvailability?.availabilityStatus === StoreListAvailabilityStatusEnum.inStock &&
                product.defaultStoreAvailability.quantity >= quantity) ||
            (product.selectedStoreAvailability?.availabilityStatus === StoreListAvailabilityStatusEnum.inStock &&
                product.selectedStoreAvailability.quantity >= quantity)
        );
    });
};

export const areAllProductsOutOfStockAtSelectedStore = (
    cartItems: CartItemType[],
    includeCentralStockQuantity = false,
): boolean => {
    return cartItems.every(({ product, quantity }) => {
        const isOutOfStock =
            product.defaultStoreAvailability?.availabilityStatus === StoreListAvailabilityStatusEnum.outOfStock ||
            product.selectedStoreAvailability?.availabilityStatus === StoreListAvailabilityStatusEnum.outOfStock;

        if (!includeCentralStockQuantity) {
            return isOutOfStock;
        }

        return isOutOfStock && product.centralStockQuantity < quantity;
    });
};

export const areReservationsDisabledAtSelectedStore = (cartItems: CartItemType[]): boolean => {
    return cartItems.every(({ product }) => {
        return [
            undefined,
            StoreListAvailabilityStatusEnum.inStockReservationDisabled,
            StoreListAvailabilityStatusEnum.outOfStockReservationDisabled,
        ].includes(
            product.defaultStoreAvailability?.availabilityStatus ||
                product.selectedStoreAvailability?.availabilityStatus,
        );
    });
};

export const areAllProductsMissingAtStoreAvailableAtCentralStore = (cartItems: CartItemType[]): boolean => {
    const outOfStockAtSelectedStore = cartItems
        .filter(({ product, quantity }) => {
            const defaultQuantity = product.defaultStoreAvailability?.quantity;
            const selectedQuantity = product.selectedStoreAvailability?.quantity;

            return (
                product.defaultStoreAvailability?.availabilityStatus === StoreListAvailabilityStatusEnum.outOfStock ||
                product.selectedStoreAvailability?.availabilityStatus === StoreListAvailabilityStatusEnum.outOfStock ||
                (defaultQuantity !== undefined && defaultQuantity < quantity) ||
                (selectedQuantity !== undefined && selectedQuantity < quantity)
            );
        })
        .map(({ product }) => product.uuid);

    const inStockAtCentralStore = cartItems
        .filter(
            ({ product, quantity }) =>
                product.availability.status === AvailabilityStatusEnum.inStock &&
                product.centralStockQuantity >= quantity,
        )
        .map(({ product }) => product.uuid);

    return outOfStockAtSelectedStore.every((product) => inStockAtCentralStore.includes(product));
};

export const areAllProductsOutOfStockAtAllStores = (cartItems: CartItemType[]): boolean => {
    return cartItems.every(({ product }) => product.availableStoresCount === 0);
};

export const isSomeProductAvailableOnlyAtStoresWithTemporaryDisabledReservations = (
    cartItems: CartItemType[],
): boolean => {
    return cartItems.some(({ product }) => product.isAvailableOnlyAtStoresWithTemporaryDisabledReservations);
};

export const areAllProductsInStockAtSomeStoreTogether = (cart: CartType | null): boolean => {
    return cart?.areProductsAvailableAtSomeStoreTogether === true;
};

export const areAllProductsInStockAtCentralStore = (cartItems: CartItemType[]): boolean => {
    return cartItems.every(({ product, quantity }) => {
        return (
            product.availability.status === AvailabilityStatusEnum.inStock && product.centralStockQuantity >= quantity
        );
    });
};

export const isNotAvailableForPersonalPickup = (
    deliveryDaysFromCentralStore: string | null,
    cart: CartType | null,
): boolean => {
    const cartItems = cart?.items || [];

    if (deliveryDaysFromCentralStore === null) {
        if (hasSelectedStore(cartItems)) {
            if (areAllProductsInStockAtSelectedStore(cartItems)) {
                return false;
            }

            if (areAllProductsOutOfStockAtSelectedStore(cartItems)) {
                return true;
            }

            return true;
        }

        if (areAllProductsInStockAtSomeStoreTogether(cart)) {
            return false;
        }

        if (areAllProductsOutOfStockAtAllStores(cartItems)) {
            return true;
        }

        return true;
    }

    if (hasSelectedStore(cartItems)) {
        if (areAllProductsInStockAtSelectedStore(cartItems)) {
            return false;
        }

        if (areReservationsDisabledAtSelectedStore(cartItems)) {
            return true;
        }

        if (
            areAllProductsInStockAtCentralStore(cartItems) ||
            areAllProductsMissingAtStoreAvailableAtCentralStore(cartItems)
        ) {
            return false;
        }

        if (areAllProductsOutOfStockAtSelectedStore(cartItems)) {
            return true;
        }

        return true;
    }

    if (areAllProductsInStockAtSomeStoreTogether(cart)) {
        return false;
    }

    if (areAllProductsInStockAtCentralStore(cartItems)) {
        return false;
    }

    if (isSomeProductAvailableOnlyAtStoresWithTemporaryDisabledReservations(cartItems)) {
        return true;
    }

    return false;
};

export const getStoresAvailabilityMessage = (
    isInCentralStoreWithEnabledReservationsAndNowhereElse: boolean,
    storeCount: number,
    deliveryDaysFromCentralStore: string | null,
    isAnyStockStore: boolean | undefined,
    t: Translate,
): string | null => {
    const { days, daysCount } = getLastDayFromPersonalPickupString(deliveryDaysFromCentralStore);

    if (isInCentralStoreWithEnabledReservationsAndNowhereElse) {
        return t('Available in stores in {{ days }} days', { days, count: daysCount });
    }
    if (storeCount > 0) {
        return t('Available in {{ count }} stores', { count: storeCount });
    }
    if (isAnyStockStore) {
        return t('Unavailable in stores');
    }

    return null;
};

export const getStoresAvailabilityMessageQuantityDependent = (
    isInCentralStoreWithEnabledReservationsAndNowhereElse: boolean,
    storeCount: number,
    deliveryDaysFromCentralStore: string | null,
    itemQuantity: number,
    itemAvailableStoresCount: number,
    totalStoreCount: number | undefined,
    t: Translate,
): string | null => {
    const { days, daysCount } = getLastDayFromPersonalPickupString(deliveryDaysFromCentralStore);

    if (isInCentralStoreWithEnabledReservationsAndNowhereElse) {
        return (
            t('Available in {{ days }} days', { days, count: daysCount }) +
            ' ' +
            t('in {{ count }} stores', { count: totalStoreCount })
        );
    }
    if (itemAvailableStoresCount === 0 && storeCount > 0) {
        return t('Not available in stores');
    }
    if (storeCount > 0) {
        return (
            t('Available {{ quantity }} pcs', { quantity: itemQuantity, count: itemQuantity }) +
            ' ' +
            t('in {{ count }} stores', { count: storeCount })
        );
    }
    if (itemAvailableStoresCount > 0) {
        return t('The requested quantity of {{ quantity }} pcs is too high and is in stock in 0 stores', {
            quantity: itemQuantity,
            count: itemQuantity,
        });
    }

    return null;
};
