import ResultTitle from './ResultTitle';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { AUTOCOMPLETE_CATEGORY_LIMIT } from 'components/Layout/Header/HeaderBottom/Search/Autocomplete/Autocomplete';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import React, { useCallback } from 'react';
import { SimpleCategoryType } from 'types/category';
import { onClickSuggestResultGtmEventHandler } from 'utils/Gtm/EventHandlers';

type ProductResultProps = {
    categories: SimpleCategoryType[];
    autocompleteSearchQueryValue: string;
};

const CategoryResults: FC<ProductResultProps> = ({ categories, autocompleteSearchQueryValue }) => {
    const testIdentifier = 'layout-header-search-autocomplete-input-categories';
    const t = useTypedTranslationFunction();

    const onCategoryClickHandler = useCallback(
        (category: SimpleCategoryType) => () => {
            onClickSuggestResultGtmEventHandler(autocompleteSearchQueryValue, 'category', category.name);
        },
        [autocompleteSearchQueryValue],
    );

    return (
        <>
            <ResultTitle title={t('Categories')} />
            <ul className="list-none space-y-5 p-0" data-testid={testIdentifier}>
                {categories.map(
                    (category, index) =>
                        index < AUTOCOMPLETE_CATEGORY_LIMIT && (
                            <li key={category.slug} data-testid={testIdentifier + '-' + index}>
                                <ExtendedNextLink
                                    type="category"
                                    href={category.link ?? category.slug}
                                    className="flex w-full items-center text-[15px] font-bold leading-relaxed tracking-wide text-primary no-underline vl:text-body vl:leading-normal"
                                    onClick={onCategoryClickHandler(category)}
                                >
                                    {category.name}
                                </ExtendedNextLink>
                            </li>
                        ),
                )}
            </ul>
        </>
    );
};

export default CategoryResults;
