import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { TescomaClubIcon } from 'public/svg/IconsSvg';
import { twJoin } from 'tailwind-merge';
import { ProductPriceType } from 'types/price';

const ProductPrice: FC<ProductPriceType> = ({ isActionPrice, isOldPrice, isTescomaPrice, priceWithVat }) => {
    const testIdentifier = 'blocks-product-price';

    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();

    if (isOldPrice) {
        return (
            <div className="mb-1 text-small font-bold leading-4 text-red line-through">
                <div className="text-primary" data-testid={testIdentifier}>
                    {formatPrice(priceWithVat)}
                </div>
            </div>
        );
    }

    if (isTescomaPrice) {
        return (
            <div className="mb-1 text-small font-bold leading-4 text-red" data-testid={testIdentifier}>
                {t('For members') + '\u00A0' + formatPrice(priceWithVat) + '\u00A0'}
                <TescomaClubIcon className="h-4 w-4 -translate-y-[2px]" />
            </div>
        );
    }

    return (
        <div
            className={twJoin('text-h5 font-bold leading-4', isActionPrice ? 'text-red' : 'text-primary')}
            data-testid={testIdentifier}
        >
            {formatPrice(priceWithVat)}
        </div>
    );
};

export default ProductPrice;
