import Webline from 'components/Layout/Webline/Webline';
import { createEmptyArray } from 'helpers/arrayUtils';
import Skeleton from 'react-loading-skeleton';

export const HomePageSkeleton: FC = () => {
    return (
        <>
            <Webline removePaddingOnMobile>
                <div className="mb-14 flex w-full flex-col justify-center">
                    <Skeleton className="h-[530px] w-full rounded-none vl:h-[500px]" />
                    <Skeleton containerClassName="vl:hidden self-center" className="m-4 h-2 w-32 " />
                </div>
            </Webline>
            <Webline>
                <Skeleton className="mb-2 w-56 text-h3" />
                <div className="mx-2 mb-7 flex flex-wrap p-0">
                    {createEmptyArray(4).map((_, index) => (
                        <Skeleton
                            key={index}
                            containerClassName="h-36 md:h-40 lg:h-56 mb-2 w-1/2 px-1 md:mb-3 md:w-1/4 md:px-1 xl:w-1/5"
                            className="h-full w-full"
                        />
                    ))}
                    <Skeleton
                        containerClassName="h-36 md:h-40 lg:h-56 mb-2 w-full px-1 sm:w-1/2 md:mb-3 md:w-1/3 md:px-1 xl:w-1/5"
                        className="h-full w-full"
                    />
                </div>
            </Webline>
            <Webline>
                <Skeleton className="mb-12 h-[420px] w-full sm:h-[400px] md:h-[190px] lg:h-[116px] lg:rounded-tr-md lg:rounded-br-md" />
            </Webline>
            <Webline>
                <div className="grid gap-x-5 md:grid-cols-2 vl:grid-cols-4">
                    {createEmptyArray(3).map((_, index) => (
                        <Skeleton
                            key={index}
                            containerClassName="mb-5 aspect-square w-full"
                            className="h-full w-full rounded-none"
                        />
                    ))}
                </div>
            </Webline>
        </>
    );
};
