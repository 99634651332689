import Link from 'components/Basic/Link/Link';
import { isDomainLocale } from 'helpers/isDomainLocale';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import Trans from 'next-translate/Trans';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

const TEST_IDENTIFIER = 'layout-footer-footercopyright';

const footerCopyrightTextClassName =
    'text-footer tracking-wider uppercase text-grayDark mb-0 leading-loose [font-weight:inherit]';

const WEB_PRODUCER_URLS = {
    czech: 'https://www.shopsys.cz',
    other: 'https://www.shopsys.com',
};

const FooterCopyright: FC = () => {
    const { url, defaultLocale } = useDomainConfig();
    const t = useTypedTranslationFunction();
    const [GdprUrl] = getInternationalizedStaticUrls(['/gdpr'], url);
    const [cookieConsentUrl] = getInternationalizedStaticUrls(['/cookie-consent'], url);
    const isCzechLocale = isDomainLocale(defaultLocale, 'cs');

    return (
        <div data-testid={TEST_IDENTIFIER} className="flex flex-col items-center text-center">
            <p className={footerCopyrightTextClassName}>
                {t('Copyright © 1992–2021, TESCOMA s.r.o. All rights reserved.')}
            </p>
            <p className={footerCopyrightTextClassName}>
                <Trans
                    i18nKey="footer-terms"
                    defaultTrans="This website uses cookies. <lnk1>More information</lnk1> about the files."
                    components={{
                        lnk1: <Link href={GdprUrl} linkType="external" target="_blank" className="text-inherit" />,
                    }}
                />
            </p>
            <p className={footerCopyrightTextClassName}>
                <Link href={cookieConsentUrl} className="text-inherit">
                    {t('Cookie consent update')}
                </Link>
            </p>
            <div className="mt-8 flex items-center vl:mt-14">
                <p className={footerCopyrightTextClassName}>{t('Professional customized eshop')}</p>
                <a
                    href={isCzechLocale ? WEB_PRODUCER_URLS.czech : WEB_PRODUCER_URLS.other}
                    target="_blank"
                    rel="noreferrer"
                    className="ml-3 inline-flex flex-shrink-0 text-inherit vl:ml-8"
                >
                    <img alt="ShopSys" src="/images/logo-shopsys.svg" width={77} height={18} />
                </a>
            </div>
        </div>
    );
};

/* @component */
export default FooterCopyright;
