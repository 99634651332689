import { yupResolver } from '@hookform/resolvers/yup';
import { useShopsysForm } from 'hooks/forms/UseShopsysForm';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AutocompleteSearchFormType } from 'types/form';
import { object, string } from 'yup';

export const useAutocompleteSearchForm = (): [
    UseFormReturn<AutocompleteSearchFormType>,
    AutocompleteSearchFormType,
] => {
    const resolver = yupResolver(
        object().shape({
            autocompleteSearchQuery: string().required().min(3),
        }),
    );
    const defaultValues = { autocompleteSearchQuery: '' };

    return [useShopsysForm(resolver, defaultValues), defaultValues];
};

export type SearchFormMetaType = {
    formName: string;
    fields: {
        [key in keyof AutocompleteSearchFormType]: {
            name: key;
            label: string;
            errorMessage: string | undefined;
        };
    };
    getFieldId(fieldName: string): string;
};

export const useAutocompleteSearchFormMeta = (
    formProviderMethods: UseFormReturn<AutocompleteSearchFormType>,
): SearchFormMetaType => {
    const t = useTypedTranslationFunction();

    const formMeta = useMemo(
        () => ({
            formName: 'search-query-form',
            fields: {
                autocompleteSearchQuery: {
                    name: 'autocompleteSearchQuery' as const,
                    label: t("Type what you're looking for"),
                    errorMessage: formProviderMethods.formState.errors.autocompleteSearchQuery?.message,
                },
            },
            getFieldId(fieldName: string) {
                return `${formMeta.formName} - ${fieldName}`;
            },
        }),
        [formProviderMethods.formState.errors.autocompleteSearchQuery?.message, t],
    );

    return formMeta;
};
