import { SettingsApi, useSettingsQueryApi } from 'graphql/generated';

export const useSettings = (): SettingsApi | undefined => {
    const [{ data }] = useSettingsQueryApi();

    if (data === undefined || data.settings === null) {
        return undefined;
    }

    return data.settings;
};
