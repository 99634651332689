import { EmblaCarouselType } from 'embla-carousel-react';
import { useEffect, useState } from 'react';

export const useGetVisibleSliderIndexes = (embla: EmblaCarouselType | undefined, initialState: number[]): number[] => {
    const [visibleSliderIndexes, setVisibleSliderIndexes] = useState<number[]>(initialState);

    useEffect(() => {
        setVisibleSliderIndexes(initialState);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialState.length]);

    useEffect(() => {
        if (!embla) {
            return;
        }
        const onScroll = () => {
            setVisibleSliderIndexes(embla.slidesInView());
        };

        embla.on('scroll', onScroll);

        // eslint-disable-next-line consistent-return
        return () => {
            embla.off('scroll', onScroll);
        };
    }, [embla]);

    return [
        visibleSliderIndexes[0] - 1,
        ...visibleSliderIndexes,
        visibleSliderIndexes[visibleSliderIndexes.length - 1] + 1,
    ];
};
