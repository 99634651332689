import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { twJoin } from 'tailwind-merge';
import tinycolor from 'tinycolor2';

type SimpleColorVariantsProps = {
    colorVariants: {
        value: string;
        colorImage: string | null;
    }[];
};

const SimpleColorVariants: FC<SimpleColorVariantsProps> = ({ colorVariants }) => {
    const t = useTypedTranslationFunction();

    return (
        <div className="mb-3 -mr-1 flex min-h-[15px] space-x-1">
            {colorVariants.map(
                (variant, index) =>
                    index <= 4 && (
                        <div
                            style={
                                variant.colorImage !== null
                                    ? { background: `transparent url(${variant.colorImage}) no-repeat center center` }
                                    : { backgroundColor: variant.value }
                            }
                            className={twJoin(
                                'rounded flex h-4 w-4 items-center rounded-full',
                                tinycolor(variant.value).isLight() && 'border border-grayDark',
                            )}
                            key={variant.value}
                        />
                    ),
            )}
            {colorVariants.length > 5 && (
                <span className="!ml-2 text-tiny text-grayDark">
                    {t('and {{ count }} more', {
                        count: colorVariants.length - 5,
                    })}
                </span>
            )}
        </div>
    );
};

export default SimpleColorVariants;
