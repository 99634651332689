import Link from 'components/Basic/Link/Link';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FacebookIcon, InstagramIcon, YoutubeIcon } from 'public/svg/IconsSvg';
import { twJoin } from 'tailwind-merge';
import { FooterSettingSocialItemType } from 'types/footerSetting';

type SocialMediaProps = {
    socialSection: FooterSettingSocialItemType[];
};

const socialMediaIcon = { Instagram: InstagramIcon, Youtube: YoutubeIcon, Facebook: FacebookIcon };

const FooterSocialMedia: FC<SocialMediaProps> = ({ socialSection }) => {
    const t = useTypedTranslationFunction();

    if (socialSection.length === 0) {
        return null;
    }

    const titles = {
        facebook: t('Facebook news overview'),
        instagram: t('Inspiration and a look behind the scenes'),
        youtube: t('Recipes and instructions on youtube'),
    };

    type titleType = keyof typeof titles;

    return (
        <div className="flex flex-row items-center justify-center pb-10 vl:pb-12">
            {socialSection.map((item, index) => {
                const linkTwClass = twJoin(
                    'items-center flex shrink-0 mr-7 hover:transition-transform border-2 border-solid border-transparent rounded-round no-underline text-primary active:scale-100',
                    'notVl:justify-center notVl:w-14 notVl:h-14',
                    'vl:min-h-[64px] vl:mr-11 vl:py-2 vl:px-10 vl:flex-1 vl:rounded-[300px]',
                    'last:mr-0 hover:scale-105 hover:no-underline',
                    item.type === 'facebook' && 'border-[#4267B2]',
                    item.type === 'instagram' && 'instagram-gradient',
                    item.type === 'youtube' && 'border-[#FF0000]',
                );
                const Icon = socialMediaIcon[item.icon];

                return (
                    <Link className={linkTwClass} key={index} linkType="external" href={item.url} target="_blank">
                        <>
                            <Icon
                                className={twJoin(
                                    '!mr-0 h-3 w-3',
                                    item.type === 'facebook' && 'text-[#4267B2]',
                                    item.type === 'youtube' && 'text-[#FF0000]',
                                )}
                            />
                            <span className="ml-6 hidden font-bold vl:block">{titles[item.type as titleType]}</span>
                        </>
                    </Link>
                );
            })}
        </div>
    );
};

export default FooterSocialMedia;
