import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Heading from 'components/Basic/Heading/Heading';
import { ArrowIcon } from 'public/svg/IconsSvg';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { FooterArticleType } from 'types/footerSetting';

type FooterMenuItemProps = {
    title: string | null;
    items: FooterArticleType[];
};

const TEST_IDENTIFIER = 'layout-footer-footermenuitem';

const FooterMenuItem: FC<FooterMenuItemProps> = ({ items, title }) => {
    const [isContentVisible, setIsContentVisible] = useState(false);

    return (
        <div
            className="border-t border-t-borderLight px-5 last:border-b last:border-b-borderLight vl:flex-1 vl:border-t-0 vl:pl-5 last:vl:border-b-0"
            data-testid={TEST_IDENTIFIER}
        >
            <Heading
                className="text-bold mb-0 flex cursor-pointer items-center justify-between py-4 text-body leading-7 text-primary lg:text-body vl:pointer-events-none vl:mb-3 vl:items-start vl:p-0 vl:text-body vl:leading-5"
                onClick={() => setIsContentVisible((prev) => !prev)}
                type="h4"
            >
                {title}
                <ArrowIcon className={twJoin('h-3 w-3 text-[#6d7175] vl:hidden', isContentVisible && 'rotate-180')} />
            </Heading>
            <ul className={twJoin('pb-5 vl:pb-0', !isContentVisible && 'notVl:h-0 notVl:overflow-hidden notVl:p-0')}>
                {items.map((item) => (
                    <li className="mb-1 last:mb-0 vl:mb-0" key={item.slug}>
                        <ExtendedNextLink
                            href={item.link || item.slug}
                            type="static"
                            target={item.link ? '_blank' : ''}
                            className="block py-2 text-[15px] leading-5 text-primary no-underline hover:text-[#1e1e1e] hover:underline"
                        >
                            {item.name}
                        </ExtendedNextLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FooterMenuItem;
