import TransportAndPaymentSelectSkeleton from './TransportAndPaymentSelect/TransportAndPaymentSelectSkeleton';
import OrderSteps from 'components/Blocks/OrderSteps/OrderSteps';
import {
    OrderSummaryBottomStyled,
    OrderSummaryContentStyled,
    OrderSummaryContentWrapperStyled,
} from 'components/Blocks/OrderSummary/OrderSummary.style';
import Webline from 'components/Layout/Webline/Webline';
import { createEmptyArray } from 'helpers/arrayUtils';
import { generateRandomWidth } from 'helpers/skeletonHelpers';
import Skeleton from 'react-loading-skeleton';

export const TransportAndPaymentSkeleton: FC = () => {
    return (
        <>
            <OrderSteps activeStep={2} isOrderCreated={false} />
            <Webline>
                <div className="mb-24 mt-8 flex w-full flex-col flex-wrap vl:mx-0 vl:mt-10 vl:mb-16 vl:flex-row">
                    <div className="mb-16 w-full vl:mb-0 vl:min-h-[61vh] vl:flex-1 vl:pr-10">
                        <Skeleton className="mb-6 h-6 w-[250px]" />
                        <TransportAndPaymentSelectSkeleton />
                        <Skeleton className="mt-11 mb-6 h-6 w-[200px] vl:mt-20" />
                        <TransportAndPaymentSelectSkeleton />
                        <div className="text-center vl:text-right">
                            <Skeleton className="mt-6 mb-20 h-12 w-[250px] vl:mt-11" />
                        </div>
                    </div>
                    <div className="w-full vl:w-[420px]">
                        <Skeleton className="mb-6 h-4 w-[150px]" />
                        <OrderSummaryContentWrapperStyled>
                            <OrderSummaryContentStyled>
                                <div className="px-5 pt-3">
                                    {createEmptyArray(2).map((_, index) => (
                                        <div
                                            className="flex flex-wrap gap-4 border-gray py-5 first:border-b"
                                            key={index}
                                        >
                                            <Skeleton className="h-20 w-20" />
                                            <div className="mt-2 flex w-[100%-80px] flex-auto flex-col gap-2 vl:w-full vl:flex-1">
                                                <Skeleton
                                                    key={index}
                                                    className="mb-1 h-4"
                                                    style={{ width: generateRandomWidth(100, 150) }}
                                                    containerClassName="w-3/5 pr-4"
                                                />
                                                <div className="flex w-2/5 flex-col">
                                                    {createEmptyArray(2).map((_, index) => (
                                                        <Skeleton
                                                            key={index}
                                                            className="-mt-1 h-3"
                                                            style={{ width: generateRandomWidth(50, 100) }}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <OrderSummaryBottomStyled>
                                    <div className="vl:text-center">
                                        <Skeleton className="h-5 w-[150px]" />
                                        <Skeleton className="mt-3 h-10 w-24" />
                                        <Skeleton className="mt-4 mb-5 h-4 w-[250px]" />
                                    </div>
                                </OrderSummaryBottomStyled>
                            </OrderSummaryContentStyled>
                        </OrderSummaryContentWrapperStyled>
                    </div>
                </div>
            </Webline>
        </>
    );
};
