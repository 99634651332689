import { useEffect, useRef } from 'react';

const useTimeout = (callback: () => void, delay: number | null): void => {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the timeout.
    useEffect(() => {
        if (delay === null) {
            return;
        }

        const id = setTimeout(() => savedCallback.current(), delay);

        // eslint-disable-next-line consistent-return
        return () => clearTimeout(id);
    }, [delay]);
};

export default useTimeout;
