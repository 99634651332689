import OrderSteps from 'components/Blocks/OrderSteps/OrderSteps';
import Webline from 'components/Layout/Webline/Webline';
import { createEmptyArray } from 'helpers/arrayUtils';
import { generateRandomWidth } from 'helpers/skeletonHelpers';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import Skeleton from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';

export const CartPageSkeleton: FC = () => {
    const { isUserLoggedIn } = useCurrentUserData();

    return (
        <>
            <OrderSteps activeStep={1} tightTopMargin isOrderCreated={false} />
            <Webline>
                <div className="mb-20 flex flex-col gap-6 vl:mb-7">
                    <div className="flex flex-col vl:-mb-7 vl:rounded-t-md vl:border-5 vl:border-b-0 vl:border-grayLight vl:px-7">
                        {createEmptyArray(2).map((_, index) => (
                            <div
                                className="-mb-5 flex flex-wrap gap-4 border-gray pb-5 pt-14 first:border-b vl:mb-0 vl:py-5"
                                key={index}
                            >
                                <Skeleton className="h-20 w-16" />
                                <div className="flex w-[100%-80px] flex-auto flex-col gap-5 vl:w-full vl:flex-1 vl:flex-row vl:items-center">
                                    <Skeleton
                                        key={index}
                                        className="mb-1 h-4"
                                        style={{ width: generateRandomWidth(150, 200) }}
                                        containerClassName="w-3/5 pr-4"
                                    />
                                    <div className="flex w-2/5 flex-col">
                                        {createEmptyArray(2).map((_, index) => (
                                            <Skeleton
                                                key={index}
                                                className="-mt-1 h-3"
                                                style={{ width: generateRandomWidth(100, 150) }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="flex w-full justify-between vl:w-auto vl:items-center">
                                    <Skeleton className="h-12 w-24 vl:w-[120px]" containerClassName="w-[125px] mr-4" />
                                    <Skeleton
                                        className="hidden h-4 w-24 vl:block"
                                        containerClassName="w-[130px] mr-4 flex items-center justify-end"
                                    />
                                    <div className="mr-9 flex w-[125px] flex-col items-end">
                                        <Skeleton className="h-3 w-16" />
                                        <Skeleton className="h-3 w-12" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className={twJoin(
                            'flex flex-col gap-7 bg-grayLight p-5 lg:flex-row vl:rounded-b-md',
                            isUserLoggedIn ? 'lg:justify-between vl:pr-24' : 'items-center',
                        )}
                    >
                        {isUserLoggedIn ? (
                            <>
                                <div className="flex w-full items-center gap-5 lg:w-fit">
                                    <Skeleton className="h-16 w-[100px]" />
                                    <Skeleton
                                        className="h-5 w-full"
                                        containerClassName="max-w-[250px] w-full lg:w-[200px] vl:w-[250px]"
                                    />
                                </div>
                                <div className="flex flex-col gap-3">
                                    <div className="flex w-full flex-col gap-6 sm:flex-row vl:justify-end">
                                        <Skeleton className="h-12 w-32" />
                                        <Skeleton className="h-12 w-full sm:w-[200px]" />
                                    </div>
                                    <Skeleton
                                        className="h-3 w-full"
                                        containerClassName="w-full max-w-[400px] vl:w-[400px]"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex w-full gap-4 vl:gap-7">
                                    <Skeleton className="h-14 w-24" />
                                    <div className="flex flex-col">
                                        {createEmptyArray(3).map((_, index) => (
                                            <Skeleton
                                                key={index}
                                                className="-mt-1 h-3 max-w-[120px] sm:max-w-none"
                                                style={{ width: generateRandomWidth(150, 200) }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="flex w-full flex-col items-center gap-2 lg:flex-row lg:items-start">
                                    <div className="flex w-full flex-col items-center gap-2 lg:max-w-[350px]">
                                        <Skeleton className="h-12" containerClassName="w-full max-w-[350px]" />
                                        <Skeleton className="mt-2 h-3 w-40" />
                                    </div>
                                    <Skeleton className="h-12 w-36" />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="flex w-full flex-col gap-7 vl:flex-row vl:justify-between">
                        <div className="flex flex-col gap-6 vl:w-full vl:max-w-[630px]">
                            <div className="flex items-center gap-2">
                                <div className="h-4 w-4 border border-gray" />
                                <Skeleton className="h-4" containerClassName="w-full max-w-[280px]" />
                            </div>
                            <div className="rounded-md border-5 border-gray py-5 px-9">
                                <Skeleton className="h-3" containerClassName="block w-full max-w-[250px]" />
                                <Skeleton className="h-2" containerClassName="block -mt-2 w-full max-w-[250px]" />
                                <Skeleton className="-mt-2 h-3" containerClassName="w-full" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-col items-center gap-1 vl:items-end">
                                <div className="flex flex-col items-center gap-1 vl:flex-row vl:items-end">
                                    <Skeleton className="h-4 w-[170px]" />
                                    <Skeleton className="h-7 w-[150px]" />
                                </div>
                                <Skeleton className="h-3 w-[250px]" />
                            </div>
                            <Skeleton className="h-12" containerClassName="w-full max-w-[400px] mx-auto" />
                        </div>
                    </div>
                    <Skeleton className="hidden h-4 w-1/4 vl:block" />
                </div>
            </Webline>
        </>
    );
};
