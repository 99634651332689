import twMerge from 'utils/twMerge';

type NativeProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type FormLineProps = {
    bottomGap?: boolean;
} & NativeProps;

const TEST_IDENTIFIER = 'form-line';

/**
 * A form line element used for wrapping inputs
 */
const FormLine: FC<FormLineProps> = ({ className, bottomGap, children, ...props }) => {
    return (
        <div data-testid={TEST_IDENTIFIER} className={twMerge('flex-1', bottomGap && 'pb-3', className)} {...props}>
            {children}
        </div>
    );
};

/* @component */
export default FormLine;
