import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { TescomaClubIcon } from 'public/svg/IconsSvg';
import { twJoin } from 'tailwind-merge';
import { AutocompleteProductType } from 'types/product';

type ProductResultPriceProps = {
    product: AutocompleteProductType;
};

export const ProductResultPrice = ({ product }: ProductResultPriceProps) => {
    const formatPrice = useFormatPrice();
    const { isUserLoggedIn } = useCurrentUserData();

    return (
        <>
            {product.prices.tescoma && !isUserLoggedIn && (
                <div className="font-small mb-1 flex items-center font-bold leading-snug text-red vl:mb-0.5 vl:leading-4">
                    {formatPrice(product.prices.tescoma.priceWithVat)}
                    <TescomaClubIcon className="ml-2 h-4 w-4 -translate-y-px" />
                </div>
            )}
            {product.price.priceWithoutVat < product.prices.basic.priceWithoutVat && (
                <div className="font-small mb-1 font-bold leading-snug text-red line-through vl:mb-0.5 vl:text-[13px] vl:leading-4">
                    <span className="text-primary">{formatPrice(product.prices.basic.priceWithVat)}</span>
                </div>
            )}
            <div
                className={twJoin(
                    'ml-auto text-h6 font-bold leading-none vl:ml-0 vl:text-[15px] vl:leading-snug',
                    product.price.priceWithoutVat < product.prices.basic.priceWithoutVat ? 'text-red' : 'text-primary',
                )}
            >
                {formatPrice(product.price.priceWithVat)}
            </div>
        </>
    );
};
