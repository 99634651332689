export const DRAWER_DEFAULT_WIDTH_IN_CM = 67;
export const DRAWER_DEFAULT_LENGTH_IN_CM = 38;
export const DRAWER_DROP_AREA_ID = 'drawer-drop-area';
export const CONFIGURATOR_WIDTH_API_IDENTIFIER = 100;
export const CONFIGURATOR_LENGTH_API_IDENTIFIER = 99;

export const SIZES_OF_CELL_IN_CM = {
    width: 7.4,
    length: 7.4,
};
