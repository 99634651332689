import { yupResolver } from '@hookform/resolvers/yup';
import { useGdprConsents } from 'connectors/gdpr/gdprConsents';
import { GdprActionTypeEnumApi } from 'graphql/generated';
import { getDefaultGdprConsents } from 'helpers/gdprConsents/getDefaultGdprConsents';
import { useShopsysForm } from 'hooks/forms/UseShopsysForm';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { EnvelopeIcon } from 'public/svg/IconsSvg';
import { useMemo } from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { NewsletterFormType } from 'types/form';
import { array, object, string } from 'yup';

export const useNewsletterForm = (): [UseFormReturn<NewsletterFormType>, NewsletterFormType] => {
    const t = useTypedTranslationFunction();
    const gdprConsents = useGdprConsents(GdprActionTypeEnumApi.EmailApi, null);

    const resolver = yupResolver(
        object().shape({
            email: string().required(t('This field is required')).email(t('This value is not a valid email')),
            gdprConsents: array().test('all-granted', t('All required consents need to be granted.'), (it) =>
                (gdprConsents ?? [])
                    .filter((item) => item.isRequired)
                    .map((item) => item.type)
                    .every((arr2Item) => it?.includes(arr2Item)),
            ),
        }),
    );
    const defaultValues = {
        email: '',
        gdprConsents: getDefaultGdprConsents(gdprConsents),
    };

    return [useShopsysForm(resolver, defaultValues), defaultValues];
};

type NewsletterFormMetaType = {
    formName: string;
    messages: {
        error: string;
        success: string;
    };
    fields: {
        [key in keyof NewsletterFormType]: {
            name: key;
            label: string | JSX.Element;
            errorMessage: string | undefined;
        };
    };
};

export const useNewsletterFormMeta = (
    formProviderMethods: UseFormReturn<NewsletterFormType>,
): NewsletterFormMetaType => {
    const t = useTypedTranslationFunction();
    const errors = formProviderMethods.formState.errors;

    return useMemo(
        () => ({
            formName: 'newsletter-form',
            messages: {
                error: t('Could not subscribe to newsletter'),
                success: t('You have successfully subscribed to our newsletter'),
            },
            fields: {
                email: {
                    name: 'email' as const,
                    label: (
                        <span className="text-primary">
                            <EnvelopeIcon className="ml-2 mr-1 h-4 w-4 translate-y-px" /> {t('Enter your email')}
                        </span>
                    ),
                    errorMessage: errors.email?.message,
                },
                gdprConsents: {
                    name: 'gdprConsents' as const,
                    label: t('Consent to GDPR'),
                    errorMessage: (errors.gdprConsents as FieldError | undefined)?.message,
                },
            },
        }),
        [errors.email?.message, errors.gdprConsents, t],
    );
};
