import { AddToCartPopup } from 'components/Blocks/Product/AddToCartPopup/AddToCartPopup';
import { UserConsentContainer } from 'components/Blocks/UserConsent/UserConsentContainer';
import { Error500WithBoundary } from 'components/Pages/ErrorPage/500/Error500';
import { Error503 } from 'components/Pages/ErrorPage/503/Error503';
import { UserConsentContext } from 'context/UserConsentProvider/UserConsentProvider';
import { PersooWatchDog } from 'modules/persoo/PersooWatchDog';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { usePersistStore } from 'store/usePersistStore';
import { UserConsentFormType } from 'types/form';

interface PageContentProviderProps {
    userConsentCookie: UserConsentFormType | null;
    isMaintenance: boolean;
}

const PageContentProvider: FC<PageContentProviderProps> = ({ children, userConsentCookie, isMaintenance }) => {
    const { updateUserState, addToCartPopupData, addToCartFetching } = usePersistStore((s) => s);

    return (
        <>
            <ToastContainer autoClose={6000} position="top-center" theme="colored" />
            <ErrorBoundary FallbackComponent={Error500WithBoundary}>
                <UserConsentContext.Provider value={userConsentCookie}>
                    <>
                        {userConsentCookie === null && <UserConsentContainer />}
                        {isMaintenance ? <Error503 /> : children}
                    </>
                </UserConsentContext.Provider>
                <PersooWatchDog />
                {addToCartPopupData && (
                    <AddToCartPopup
                        gtmListName={addToCartPopupData.gtmListName}
                        listIndex={addToCartPopupData.listIndex}
                        onCloseCallback={() => updateUserState({ addToCartPopupData: null })}
                        product={addToCartPopupData.product}
                        fetching={addToCartFetching}
                    />
                )}
            </ErrorBoundary>
            <div id="portal" className="absolute top-0 left-0 h-px w-px" />
        </>
    );
};

export default PageContentProvider;
