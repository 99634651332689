import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import Webline from 'components/Layout/Webline/Webline';
import { useAdverts } from 'connectors/adverts/Adverts';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { Fragment, HTMLAttributes } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { AdvertType } from 'types/advert';
import { CategoryDetailType } from 'types/category';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type AdvertsProps = {
    positionName: 'productList' | 'footer' | 'header' | 'productListMiddle' | 'cartPreview';
    withGapBottom?: boolean;
    withGapTop?: boolean;
    withWebline?: boolean;
    currentCategory?: CategoryDetailType;
};

const Adverts: FC<AdvertsProps & NativeProps> = (props) => {
    const adverts = useAdverts();
    const WrapperComponent = props.withWebline ? Webline : Fragment;
    const isPositionNameSet = adverts?.some((item) => item.positionName === props.positionName);

    const isDesktop = useMediaMin('lg');

    if (!isPositionNameSet) {
        return null;
    }

    return (
        <WrapperComponent>
            <div className={twMerge(props.withGapTop && 'mt-8', props.withGapBottom && 'mb-8', props.className)}>
                {adverts?.map(
                    (item, index) =>
                        shouldBeShown(item, props) &&
                        (item.__typename === 'AdvertImage' ? (
                            <Fragment key={index}>
                                {item.link !== undefined ? (
                                    <ExtendedNextLink
                                        href={item.link}
                                        target="_blank"
                                        className="relative"
                                        type="static"
                                    >
                                        {isDesktop ? (
                                            <Image src={item.image} fill alt={item.name} />
                                        ) : (
                                            <Image src={item.imageMobile} fill alt={item.name} />
                                        )}
                                    </ExtendedNextLink>
                                ) : (
                                    <>
                                        {isDesktop ? (
                                            <Image src={item.image} alt={item.name} fill />
                                        ) : (
                                            <Image src={item.imageMobile} alt={item.name} fill />
                                        )}
                                    </>
                                )}
                            </Fragment>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: item.code }} key={index} />
                        )),
                )}
            </div>
        </WrapperComponent>
    );
};

const shouldBeShown = (advert: AdvertType, advertsProps: AdvertsProps): boolean => {
    if (advert.positionName !== advertsProps.positionName) {
        return false;
    }
    if (advert.positionName === 'productListMiddle' && advert.categories.length === 0) {
        return false;
    }
    for (const category of advert.categories) {
        if (
            category.slug === advertsProps.currentCategory?.slug ||
            category.slug === advertsProps.currentCategory?.originalCategorySlug
        ) {
            return true;
        }
    }

    return advertsProps.positionName !== 'productListMiddle' && advert.positionName === advertsProps.positionName;
};

export default Adverts;
